/* istanbul ignore file */
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, PhoneInput } from 'shared/uibuilder/form/input';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import BigFormRow from 'shared/layout/form/BigFormRow';
import {
  CandidateApplicationSource,
  CreateCandidateApplicationOptions,
  CreateCandidateApplicationOptionsLabels,
  getParentResourceUrl,
  isEmployeeSource,
} from 'erp/candidate/applications/shared/candidateApplicationService';
import CountryDropdown from 'shared/uibuilder/form/input/CountryDropdown';
import VacancyDropdown from 'erp/candidate/shared/input/VacancyDropdown';
import { FormFieldsData, useFormContext } from 'shared/uibuilder/form/FormContext';
import CandidateApplicationResumeUploaderWithParsingInput from 'erp/candidate/applications/shared/input/CandidateApplicationResumeUploaderWithParsingInput';
import CandidateApplicationSourceNameDropdown from 'erp/candidate/applications/shared/input/CandidateApplicationSourceNameDropdown';
import CandidateApplicationSourceDropdown from 'erp/candidate/applications/shared/input/CandidateApplicationSourceDropdown';
import CandidateApplicationSourceTypeDropdown from 'erp/candidate/applications/shared/input/CandidateApplicationSourceTypeDropdown';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import getDuplicatesWarning from 'erp/candidate/shared/candidateDuplicateWarningBuilder';
import Alert from 'uibuilder/Alert';
import CandidateDuplicateDropdown from 'erp/candidate/applications/shared/input/CandidateDuplicateDropdown';
import useCandidateDuplicatesHelper from 'erp/candidate/applications/shared/candidateDuplicatesHelper';
import useCandidateApplicationDuplicatesHelper from 'erp/candidate/applications/shared/candidateApplicationDuplicatesHelper';
import { Link } from 'react-router-dom';
import useCandidateApplicationFromExtensionService from 'erp/candidate/applications/shared/candidateApplicationFromExtensionService';
import AdditionalContactListInput from 'erp/shared/input/AdditionalContactListInput';
import Button from 'uibuilder/button/Button';
import LoadingDecorator from 'shared/uibuilder/LoadingDecorator';
import 'erp/candidate/applications/createupdate/CandidateApplicationForm.css';
import { EmployeeInput } from 'erp/employee/index';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import TrimTextInput from 'shared/uibuilder/form/input/TrimTextInput';
import TimelineFeedbackWysiwygInput from 'erp/employee/shared/input/TimelineFeedbackWysiwygInput';
import NoteAttachmentUploaderInput from 'artifact/shared/input/NoteAttachmentUploaderInput';
import { set } from 'lodash';

interface CandidateApplicationFromJobBoardFormProps {
  isCreateForm: boolean;
  hasAnchorLink: boolean;
  isSourceDisabled?: boolean;
  isSubmitted: boolean;
  createApplicationOption: CreateCandidateApplicationOptions;
  clearIsSubmitted: () => void;
  onDuplicatesFail: () => void;
  setCreateApplicationOption: (option: CreateCandidateApplicationOptions) => void;
}

const CandidateApplicationFromJobBoardForm = ({
  isCreateForm = false,
  hasAnchorLink = false,
  isSourceDisabled = false,
  isSubmitted,
  createApplicationOption,
  clearIsSubmitted,
  onDuplicatesFail,
  setCreateApplicationOption,
}: CandidateApplicationFromJobBoardFormProps) => {
  const [resumeParsed, setResumeParsed] = useState(true);
  const [parsingEnabled, setParsingEnabled] = useState(false);
  const { data: formData, setSubmitEnabled = () => {} } = useFormContext();
  const [isDuplicateConfirmed, setIsDuplicateConfirmed] = useState(false);
  const { duplicates, resetDuplicates, duplicateSearchFinished, removeByCandidateId } =
    useCandidateDuplicatesHelper(onDuplicatesFail);
  const { isCandidateApplicationExist } = useCandidateApplicationDuplicatesHelper(onDuplicatesFail);
  const [createNewApplication, setCreateNewApplication] = useState(false);
  const warning = useMemo(
    () => getDuplicatesWarning(duplicates, removeByCandidateId, true),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [duplicates],
  );
  const { isParsingFinished, sendMessageToExtension } = useCandidateApplicationFromExtensionService();
  const { onChangeCallback = () => {} } = useFormContext();

  const [shouldCreateApplication, setShouldCreateApplication] = useState(true);
  const [startProcessOnPrescreenStep, setStartProcessOnPrescreenStep] = useState(false);

  useEffect(() => {
    setShouldCreateApplication(createApplicationOption !== CreateCandidateApplicationOptions.DO_NOT_CREATE);
    setStartProcessOnPrescreenStep(
      createApplicationOption === CreateCandidateApplicationOptions.CREATE_IN_PRESCREEN_STEP,
    );
  }, [createApplicationOption]);

  useEffect(() => {
    if (formData) {
      set(formData, 'foundDuplicates', duplicates);
    }
    setIsDuplicateConfirmed(!duplicates.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicates]);

  // to reset warning message with duplicates after submitting the form
  useEffect(() => {
    if (isSubmitted) {
      resetDuplicates();
      if (isParsingFinished) {
        sendMessageToExtension({ created: true });
      }
      clearIsSubmitted();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearIsSubmitted, isSubmitted, resetDuplicates]);

  const candidateId = formData?.candidateId;
  useEffect(() => {
    const candidateApplicationSubmitEnabled =
      (isCandidateApplicationExist && createNewApplication) || !isCandidateApplicationExist;
    setSubmitEnabled((isDuplicateConfirmed || candidateId) && candidateApplicationSubmitEnabled);
  }, [isDuplicateConfirmed, candidateId, setSubmitEnabled, isCandidateApplicationExist, createNewApplication]);

  const hasCandidateId = !!candidateId;
  return (
    <div className="candidate-application-form">
      <FormSection title="Candidate" hasAnchorLink={hasAnchorLink} isExpandedSection>
        <>
          {!hasCandidateId && (
            <>
              <FormRow>
                <ClearAfterUnmountWrapper>
                  <TextInput source="candidate.name.firstName" label="First Name" />
                </ClearAfterUnmountWrapper>
                <ClearAfterUnmountWrapper>
                  <TextInput source="candidate.name.lastName" label="Last Name" />
                </ClearAfterUnmountWrapper>
              </FormRow>
              <FormRow>
                <ClearAfterUnmountWrapper>
                  <PhoneInput hasExtension={false} source="candidate.personalPhoneNumber" />
                </ClearAfterUnmountWrapper>
                <ClearAfterUnmountWrapper>
                  <TrimTextInput source="candidate.personalEmail" label="Personal Email Address" />
                </ClearAfterUnmountWrapper>
                <ClearAfterUnmountWrapper>
                  <CountryDropdown source="candidate.residenceCountry" label="Country of Residence" />
                </ClearAfterUnmountWrapper>
              </FormRow>
              <ClearAfterUnmountWrapper>
                <AdditionalContactListInput source="candidate.additionalContacts" />
              </ClearAfterUnmountWrapper>
            </>
          )}
          <BigFormRow>
            <CandidateApplicationResumeUploaderWithParsingInput
              parsingEnabled={parsingEnabled}
              resumeParsed={resumeParsed}
              onParsed={() => {
                setResumeParsed(true);
                setParsingEnabled(false);
              }}
              onNoResume={() => setResumeParsed(false)}
              label="Candidate resume"
              source="resume.resumeArtifactIds"
            />
            <Button onClick={() => setParsingEnabled(true)} sx={{ display: { md: 'inline-flex' }, mb: '12px' }}>
              Parse resume
            </Button>
          </BigFormRow>
        </>
        <LoadingDecorator loading={!duplicateSearchFinished} deps={[duplicateSearchFinished]}>
          {!!warning && <Alert severity="warning">{warning?.content}</Alert>}
          <FormSection title="Handle duplicates" isVisible={!!warning}>
            <BigFormRow>
              <Checkbox
                isVisible={(application: FormFieldsData) => !application.candidateId}
                source="isNotDuplicate"
                label="I confirm it is not duplicate"
                onChangeCallback={() => {
                  setIsDuplicateConfirmed(prevValue => !prevValue);
                }}
                value={isDuplicateConfirmed}
              />
              <ClearAfterUnmountWrapper isVisible={!isDuplicateConfirmed}>
                <CandidateDuplicateDropdown
                  source="candidateId"
                  label="Candidate for application from found duplicates"
                  onChangeCallback={values => {
                    onChangeCallback({
                      ...values,
                      createNewCandidate: !values.candidateId,
                    });
                  }}
                  duplicates={duplicates}
                />
              </ClearAfterUnmountWrapper>
            </BigFormRow>
          </FormSection>
        </LoadingDecorator>
      </FormSection>
      <FormSection title="Application" hasAnchorLink={hasAnchorLink}>
        <>
          {isCandidateApplicationExist && (
            <Alert severity="warning">
              Application for the selected candidate and vacancy already exists.{' '}
              <Link target="_blank" to={`${getParentResourceUrl(candidateId)}/applications`}>
                Check the candidates applications view for details.{' '}
              </Link>
              Decide if you want to create a new application or use the existing one bellow.
            </Alert>
          )}
          <FormSection title="Handle duplicates" isVisible={isCandidateApplicationExist}>
            <Checkbox
              isVisible={(application: FormFieldsData) => !application.candidateId}
              source="isNotDuplicateApplication"
              label="New application required"
              onChangeCallback={() => {
                setCreateNewApplication(prevValue => !prevValue);
              }}
              value={createNewApplication}
            />
          </FormSection>
        </>
        <FormRow>
          <Dropdown
            placeholder="Choose Create Application Option"
            source="createApplicationOption"
            options={Object.entries(CreateCandidateApplicationOptionsLabels).map(entry => {
              const [value, label] = entry;
              return {
                value,
                label,
              };
            })}
            onChangeCallback={values => {
              setCreateApplicationOption(values.createApplicationOption);
              onChangeCallback(values);
            }}
          />
        </FormRow>
        <>
          {shouldCreateApplication && (
            <>
              <FormRow>
                <TextInput
                  source="communicationChanel"
                  label="Preferable communication channels"
                  isVisible={startProcessOnPrescreenStep}
                />
                <EmployeeInput
                  source="recruiterEmployeeId"
                  label="Recruiter"
                  isVisible={startProcessOnPrescreenStep}
                  defaultFilter={{
                    'specification:isRecruiter': {
                      eq: true,
                    },
                  }}
                />
              </FormRow>
              <FormRow>
                <ClearAfterUnmountWrapper isVisible={shouldCreateApplication}>
                  <VacancyDropdown source="vacancyAlias" label="Applied to" />
                </ClearAfterUnmountWrapper>
                <ClearAfterUnmountWrapper isVisible={shouldCreateApplication}>
                  <CandidateApplicationSourceTypeDropdown source="sourceType" label="Source Type" />
                </ClearAfterUnmountWrapper>
                <ClearAfterUnmountWrapper isVisible={shouldCreateApplication}>
                  <CandidateApplicationSourceDropdown source="source" label="Source" disabled={isSourceDisabled} />
                </ClearAfterUnmountWrapper>
                <ClearAfterUnmountWrapper
                  isVisible={(application: any) =>
                    shouldCreateApplication &&
                    application.source &&
                    application.source === CandidateApplicationSource.JOB_BOARD
                  }
                >
                  <CandidateApplicationSourceNameDropdown
                    source="sourceComment"
                    helpText="Please specify the job board candidate applied from"
                    label="Job board"
                  />
                </ClearAfterUnmountWrapper>
                <ClearAfterUnmountWrapper
                  isVisible={(application: any) =>
                    shouldCreateApplication &&
                    application.source &&
                    application.source !== CandidateApplicationSource.JOB_BOARD &&
                    !isEmployeeSource(application)
                  }
                >
                  <TextInput source="sourceComment" label="Source comment" />
                </ClearAfterUnmountWrapper>
                <ClearAfterUnmountWrapper
                  isVisible={(application: any) => shouldCreateApplication && isEmployeeSource(application)}
                >
                  <EmployeeInput source="sourceEmployeeId" label="Employee" nameSource="sourceEmployeeName" />
                </ClearAfterUnmountWrapper>
              </FormRow>
            </>
          )}
          <BigFormRow>
            <ClearAfterUnmountWrapper isVisible={!isSubmitted}>
              <TimelineFeedbackWysiwygInput
                label="Timeline comment"
                labelHint="(Will be added to candidate timeline)"
                source="note.noteText"
                artifactsSource="note.attachmentsIds"
              />
            </ClearAfterUnmountWrapper>

            <ClearAfterUnmountWrapper isVisible={!isSubmitted}>
              <NoteAttachmentUploaderInput label="Attachments" source="note.attachmentsIds" />
            </ClearAfterUnmountWrapper>
          </BigFormRow>
        </>
      </FormSection>
    </div>
  );
};

export default CandidateApplicationFromJobBoardForm;
