import { HashLink } from 'react-router-hash-link';
import React from 'react';
import { useLocation } from 'react-router-dom';
import * as domUtils from 'shared/uibuilder/domUtils';

export const ERROR_IN_SECTION =
  'The form cannot be saved, please, check the entered information in the highlighted section(s):';
export const ERROR_WITHOUT_SECTION =
  'The form cannot be saved, please, check the entered information in the highlighted field(s)';

type InvalidSectionsMessageProps = {
  needToDisplay: boolean;
  invalidSections: { title: string; anchor: string }[];
  isViewInvalidSection?: boolean;
};

const InvalidSectionsMessage = ({
  needToDisplay = false,
  invalidSections,
  isViewInvalidSection = true,
}: InvalidSectionsMessageProps) => {
  const location = useLocation();

  if (!needToDisplay || !invalidSections || !invalidSections.length) {
    return null;
  }

  const isSingleSectionWithoutTitle = invalidSections.length === 1 && invalidSections[0].title.length === 0;
  const errorMessage = isSingleSectionWithoutTitle ? ERROR_WITHOUT_SECTION : ERROR_IN_SECTION;

  return (
    <div className="form-errors">
      <div>{isViewInvalidSection ? errorMessage : ERROR_WITHOUT_SECTION}</div>
      {isViewInvalidSection
        ? invalidSections.map(value => {
            return (
              <HashLink
                key={value.anchor}
                className="error-section-link"
                to={`${location.pathname}${location.search}#${value.anchor}`}
                scroll={el => domUtils.scrollToHash(el, 100)}
              >
                {value.title}
              </HashLink>
            );
          })
        : null}
    </div>
  );
};

export default InvalidSectionsMessage;
