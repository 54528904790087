/* istanbul ignore file */
import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import { TextInput } from 'shared/uibuilder/form/input';
import FormRow from 'shared/layout/form/FormRow';
import { EmployeeInput } from 'erp/employee';
import { buildEmployeeAutoSuggestLabel } from 'erp/employee/shared/input/EmployeeInput';
import { snakeCase } from 'lodash';

const ServiceCatalogForm = () => {
  return (
    <>
      <FormSection title="Service information">
        <FormRow>
          <TextInput source="name" label="Name" />
          <EmployeeInput
            source="owner"
            label="Owner"
            nameSource="employeeName"
            mapResults={(employee: any) => ({
              id: employee.alias,
              text: buildEmployeeAutoSuggestLabel(employee),
              office: employee.office ? snakeCase(employee.office).toUpperCase() : null,
            })}
          />
        </FormRow>
      </FormSection>
    </>
  );
};

export default ServiceCatalogForm;
