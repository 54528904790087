import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const SUserIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg overflow="visible" width="50" height="74" viewBox="3 3 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M25.0007 25C22.709 25 20.7472 24.184 19.1152 22.5521C17.4833 20.9201 16.6673 18.9583 16.6673 16.6666C16.6673 14.375 17.4833 12.4132 19.1152 10.7812C20.7472 9.14929 22.709 8.33331 25.0007 8.33331C27.2923 8.33331 29.2541 9.14929 30.8861 10.7812C32.518 12.4132 33.334 14.375 33.334 16.6666C33.334 18.9583 32.518 20.9201 30.8861 22.5521C29.2541 24.184 27.2923 25 25.0007 25ZM8.33398 41.6666V35.8333C8.33398 34.6528 8.63815 33.568 9.24648 32.5791C9.85482 31.5903 10.6618 30.8347 11.6673 30.3125C13.8201 29.2361 16.0076 28.4291 18.2298 27.8916C20.452 27.3541 22.709 27.0847 25.0007 27.0833C27.2923 27.0819 29.5493 27.3514 31.7715 27.8916C33.9937 28.4319 36.1812 29.2389 38.334 30.3125C39.3409 30.8333 40.1486 31.5889 40.7569 32.5791C41.3652 33.5694 41.6687 34.6541 41.6673 35.8333V41.6666H8.33398Z"
          fill="currentColor"
        />
        <g filter="url(#filter0_d_0_1)">
          <path
            d="M27.1548 54.6378C27.098 54.0649 26.8542 53.6198 26.4233 53.3026C25.9924 52.9853 25.4077 52.8267 24.669 52.8267C24.1671 52.8267 23.7434 52.8977 23.3977 53.0398C23.0521 53.1771 22.7869 53.3688 22.6023 53.6151C22.4223 53.8613 22.3324 54.1406 22.3324 54.4531C22.3229 54.7135 22.3774 54.9408 22.4957 55.1349C22.6188 55.3291 22.7869 55.4972 23 55.6392C23.2131 55.7765 23.4593 55.8973 23.7386 56.0014C24.018 56.1009 24.3163 56.1861 24.6335 56.2571L25.9403 56.5696C26.5748 56.7116 27.1572 56.901 27.6875 57.1378C28.2178 57.3745 28.6771 57.6657 29.0653 58.0114C29.4536 58.357 29.7543 58.7642 29.9673 59.233C30.1851 59.7017 30.2964 60.2391 30.3011 60.8452C30.2964 61.7353 30.0691 62.5071 29.6193 63.1605C29.1742 63.8092 28.5303 64.3134 27.6875 64.6733C26.8494 65.0284 25.8385 65.206 24.6548 65.206C23.4806 65.206 22.4579 65.026 21.5866 64.6662C20.7202 64.3063 20.0431 63.7737 19.5554 63.0682C19.0724 62.358 18.8191 61.4796 18.7955 60.4332H21.7713C21.8045 60.9209 21.9441 61.3281 22.1903 61.6548C22.4413 61.9768 22.7751 62.2206 23.1918 62.3864C23.6132 62.5473 24.089 62.6278 24.6193 62.6278C25.1402 62.6278 25.5923 62.5521 25.9759 62.4006C26.3641 62.2491 26.6648 62.0384 26.8778 61.7685C27.0909 61.4986 27.1974 61.1884 27.1974 60.8381C27.1974 60.5114 27.1004 60.2367 26.9062 60.0142C26.7169 59.7917 26.4375 59.6023 26.0682 59.446C25.7036 59.2898 25.2562 59.1477 24.7259 59.0199L23.142 58.6222C21.9157 58.3239 20.9474 57.8575 20.2372 57.223C19.527 56.5885 19.1742 55.7339 19.179 54.6591C19.1742 53.7784 19.4086 53.009 19.8821 52.3509C20.3603 51.6927 21.0161 51.179 21.8494 50.8097C22.6828 50.4403 23.6297 50.2557 24.6903 50.2557C25.7699 50.2557 26.7121 50.4403 27.517 50.8097C28.3267 51.179 28.9564 51.6927 29.4062 52.3509C29.8561 53.009 30.0881 53.7713 30.1023 54.6378H27.1548Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_0_1"
            x="14.7949"
            y="50.2557"
            width="19.5059"
            height="22.9503"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape" />
          </filter>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default SUserIcon;
