/* istanbul ignore file */
import Show from 'shared/uibuilder/show/Show';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import { EmailField, HtmlField, TextField } from 'shared/uibuilder/field';
import React from 'react';
import useCandidateService, { READ_SKILLS } from 'erp/candidate/shared/candidateService';
import { isEmployeeSource } from 'erp/candidate/applications/shared/candidateApplicationService';
import CandidateMenu from 'erp/candidate/shared/CandidateMenu';
import { useParams } from 'react-router-dom';
import CandidateEntityHeader from 'erp/candidate/shared/CandidateEntityHeader';
import DateField from 'shared/uibuilder/field/DateField';
import CandidateApplicationSourceField from 'erp/candidate/shared/field/CandidateApplicationSourceField';
import NameField from 'shared/uibuilder/field/NameField';
import AttachmentsListField from 'artifact/shared/field/AttachmentsListField';
import PhoneField from 'shared/uibuilder/field/PhoneField';
import EditCandidateButton from 'erp/candidate/shared/button/EditCandidateButton';
import DeleteCandidateButton from 'erp/candidate/shared/button/DeleteCandidateButton';
import SingleCandidateBreadcrumbs from 'erp/candidate/shared/SingleCandidateBreadcrumbs';
import AdditionalContactsField from 'erp/shared/field/AdditionalContactsField';
import { EmployeeLinkField } from 'erp/employee';
import useAuthorization from 'shared/authorization/authorizationService';
import { READ_CANDIDATE_RESUMES } from 'erp/candidate/Resume/shared/resumeService';
import CommaListField from 'shared/uibuilder/field/CommaListFIeld';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import ReplaceUrlWithLink from 'shared/uibuilder/ReplaceUrlWithLink';

const CandidateViewPage = () => {
  const { getById } = useCandidateService();
  const { id } = useParams<Dictionary<string>>();
  const { isGranted } = useAuthorization();

  const buttons = [<EditCandidateButton source="id" />, <DeleteCandidateButton />];

  return (
    <Show getDataMethod={() => getById(id!)}>
      <ShowPageLayout
        menu={<CandidateMenu />}
        entityHeader={<CandidateEntityHeader />}
        headerButtons={buttons}
        breadcrumbs={<SingleCandidateBreadcrumbs />}
        hasSeparateSections
      >
        <ShowSection title="Main Information" hasAnchorLink>
          <SectionRow>
            <NameField source="name" />
          </SectionRow>
          <SectionRow>
            <AttachmentsListField source="photosIds" label="Photos" />
            <AttachmentsListField
              source="lastUploadedResumeIds"
              label="Last Resume"
              isVisible={isGranted(READ_CANDIDATE_RESUMES)}
            />
            <EmployeeLinkField
              nameSource="convertedToEmployeeId"
              source="convertedToEmployeeId"
              label="Employee Profile"
              isVisible={(data: any) => data.getValueBySource('convertedToEmployeeId')}
            />
            <TextField source="syberryEmail" isVisible={(data: any) => data.getValueBySource('isStudent')} />
          </SectionRow>
          <SectionRow>
            <DateField source="dateOfBirth" label="Date of Birth" />
            <TextField source="age" />
            <CandidateApplicationSourceField
              source="lastCandidateApplication.source"
              label="Latest application source"
            />
            <ReplaceUrlWithLink isVisible={(candidate: any) => !isEmployeeSource(candidate.lastCandidateApplication)}>
              <HtmlField source="lastCandidateApplication.sourceComment" />
            </ReplaceUrlWithLink>
            <EmployeeLinkField
              nameSource="lastCandidateApplication.sourceEmployeeName"
              source="lastCandidateApplication.sourceEmployeeId"
              label="Employee"
              isVisible={(candidate: any) => isEmployeeSource(candidate.lastCandidateApplication)}
            />
            <TextField source="englishLevel" label="English Level" />
          </SectionRow>
        </ShowSection>

        <ShowSection title="Contact Information" hasAnchorLink>
          <SectionRow>
            <PhoneField source="personalPhoneNumber" />
            <PhoneField source="otherPhoneNumber" />
            <TextField source="residenceCountry" label="Residence Country" />
          </SectionRow>

          <SectionRow>
            <EmailField source="personalEmail" label="Personal Email Address" />
            <EmailField source="otherEmail" label="Other Email Address" />
            <AdditionalContactsField source="additionalContacts" />
          </SectionRow>
        </ShowSection>
        <ShowSection title="Skills" hasAnchorLink isVisible={isGranted(READ_SKILLS)}>
          <BigSectionRow>
            <CommaListField source="skills" />
          </BigSectionRow>
        </ShowSection>
      </ShowPageLayout>
    </Show>
  );
};

export default CandidateViewPage;
