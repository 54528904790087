import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const PLetterIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 2C3.79086 2 2 3.79086 2 6V14C2 16.2091 3.79086 18 6 18H14C16.2091 18 18 16.2091 18 14V6C18 3.79086 16.2091 2 14 2H6ZM7.08 13.92C7.13333 13.9733 7.2019 14 7.28571 14H8.58857C8.67238 14 8.74095 13.9733 8.79429 13.92C8.84762 13.8667 8.87429 13.7981 8.87429 13.7143V11.1314H10.4057C11.0229 11.1314 11.5562 11.04 12.0057 10.8571C12.4629 10.6743 12.8171 10.3962 13.0686 10.0229C13.3276 9.6419 13.4571 9.1619 13.4571 8.58286C13.4571 8.01143 13.3276 7.53524 13.0686 7.15429C12.8171 6.76571 12.4629 6.47619 12.0057 6.28571C11.5562 6.09524 11.0229 6 10.4057 6H7.28571C7.2019 6 7.13333 6.02667 7.08 6.08C7.02667 6.13333 7 6.2019 7 6.28571V13.7143C7 13.7981 7.02667 13.8667 7.08 13.92ZM10.3486 9.71429H8.84V7.42857H10.3486C10.7829 7.42857 11.0952 7.53524 11.2857 7.74857C11.4838 7.9619 11.5829 8.23619 11.5829 8.57143C11.5829 8.95238 11.4724 9.2381 11.2514 9.42857C11.0381 9.61905 10.7371 9.71429 10.3486 9.71429Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default PLetterIcon;
