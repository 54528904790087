/* istanbul ignore file */
import React from 'react';
import CreateButton from 'uibuilder/button/CreateButton';
import { useVacancyGroupId, useVacancyGroupUrl } from '../../../../routing';

const CreateVacancyPublicationButton = () => {
  const groupId = useVacancyGroupId();
  const { getCreatePublicationUrl } = useVacancyGroupUrl();

  return <CreateButton url={getCreatePublicationUrl(groupId)} label="Add platform" key="create-publication" />;
};

export default CreateVacancyPublicationButton;
