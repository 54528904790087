/* istanbul ignore file */
import React from 'react';
import AsyncSelect from 'react-select/async';
import { GroupBase, StylesConfig, OnChangeValue } from 'react-select';
import { COMMON_INPUT_LAYOUT_DEFAULT_PROPS, getBaseInputLayoutProps, getCommonInputProps } from './BaseInputLayout';
import { Option } from 'shared/uibuilder/form/input/dropdownHelper';
import { MultiSelectInputLayoutProps, MultiSelectInputLayoutType } from 'shared/uibuilder/form/input/MultiSelectInput';
import { IndicatorsContainer } from './DropdownComponents';
import getDropdownLayoutCommonProps from './dropdownLayoutHelper';
import './DropdownLayout.scss';
import useUiTheme from 'shared/uibuilder/useUiTheme';

const MultiSelectInputLayout: MultiSelectInputLayoutType = ({
  onLoadOptions,
  placeholder,
  onChangeCallback,
  noOptionsMessage,
  value: initialValue,
  menuPortalTarget = document.body,
  isMulti = true,
  ...otherProps
}: MultiSelectInputLayoutProps) => {
  const props = {
    ...COMMON_INPUT_LAYOUT_DEFAULT_PROPS,
    ...otherProps,
  };
  const { selectClassName, styles, classNamePrefix } = getDropdownLayoutCommonProps(props);
  const { BaseInputLayout } = useUiTheme();

  return (
    <BaseInputLayout {...getBaseInputLayoutProps(props)}>
      <AsyncSelect
        {...getCommonInputProps(props)}
        value={initialValue}
        className={selectClassName}
        classNamePrefix={classNamePrefix}
        isMulti={isMulti}
        loadOptions={onLoadOptions as any}
        placeholder={placeholder}
        onChange={(value: OnChangeValue<Option, boolean>) => {
          if (onChangeCallback) {
            onChangeCallback({ target: { value } });
          }
        }}
        noOptionsMessage={noOptionsMessage}
        styles={styles as StylesConfig<Option, boolean, GroupBase<Option>>}
        menuPortalTarget={menuPortalTarget}
        components={{
          IndicatorsContainer,
        }}
      />
    </BaseInputLayout>
  );
};

export default MultiSelectInputLayout;
