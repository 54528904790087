import Container from '@mui/material/Container';
import MuiTypography from '@mui/material/Typography';
import React, { Component, ReactNode } from 'react';
import getErrorDescription from 'shared/uibuilder/ErrorBoundaryHelper';

const MINUTE = 60000;

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  description: string;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = { hasError: false, description: '' };

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.setState({
      hasError: true,
      description: getErrorDescription(info),
    });

    setTimeout(() => window.location.reload(), MINUTE);
  }

  render() {
    const { hasError, description } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Container
          sx={{
            justifyContent: 'center',
            minHeight: 'calc(100vh - 90px)',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <MuiTypography variant="display0" sx={{ float: 'left', marginRight: '30px' }} className="c-title">
            Oops...
          </MuiTypography>
          <div>
            <MuiTypography variant="h4" sx={{ marginBottom: '0.5rem', paddingTop: '0.75rem' }}>
              An unexpected error has occurred.
            </MuiTypography>
            <MuiTypography color="neutral.main">
              If this continues to happen, please
              <a href={`/one-stop/requests/SUP01?description=${description}`} target="_blank" rel="noopener noreferrer">
                {' '}
                contact DaVinci support (using this link).
              </a>
            </MuiTypography>
          </div>
        </Container>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
