/* istanbul ignore file */
import React from 'react';
import { ListBreadcrumbItem, Breadcrumbs } from 'shared/uibuilder/BreadCrumbs';
import { useVacancyGroupUrl } from '../routing';

const VacancyGroupsBreadcrumbs = ({ children = null }: { children?: React.ReactNode | React.ReactNode[] | null }) => {
  const { newDashboard } = useVacancyGroupUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Dashboard" link={newDashboard} />
      {children}
    </Breadcrumbs>
  );
};

export default VacancyGroupsBreadcrumbs;
