import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const NoteIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="none">
        <path
          fill="currentColor"
          d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h400l240 240v400q0 33-23.5 56.5T760-120H200Zm80-160h400v-80H280v80Zm0-160h400v-80H280v80Zm0-160h280v-80H280v80Z"
        />
      </svg>
    </SvgIcon>
  );
};

export default NoteIcon;
