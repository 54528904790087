import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const EnglishIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_2261_2383" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
          <path
            d="M35.625 1.25H4.375C2.64911 1.25 1.25 2.64911 1.25 4.375V35.625C1.25 37.3509 2.64911 38.75 4.375 38.75H35.625C37.3509 38.75 38.75 37.3509 38.75 35.625V4.375C38.75 2.64911 37.3509 1.25 35.625 1.25Z"
            fill="white"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.8743 12.7083H8.54102V27.2916H16.3535M8.54102 20H16.3535M22.0827 14.7916V27.2916V20.5208C22.0827 19.2776 22.5765 18.0853 23.4556 17.2063C24.3347 16.3272 25.527 15.8333 26.7702 15.8333C28.0134 15.8333 29.2057 16.3272 30.0847 17.2063C30.9638 18.0853 31.4577 19.2776 31.4577 20.5208V27.2916"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </mask>
        <g mask="url(#mask0_2261_2383)">
          <path d="M-5 -5H45V45H-5V-5Z" fill="currentColor" />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default EnglishIcon;
