/* istanbul ignore file */
import React from 'react';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import VacancyGroupPublicationBreadcrumbs from 'erp/recruitment/newVacancy/show/publications/VacancyGroupPublicationBreadcrumbs';

const CreateVacancyPublicationBreadcrumbs = () => {
  return (
    <VacancyGroupPublicationBreadcrumbs>
      <CreateBreadcrumbItem entity="Vacancy Publication" />
    </VacancyGroupPublicationBreadcrumbs>
  );
};

export default CreateVacancyPublicationBreadcrumbs;
