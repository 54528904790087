/* istanbul ignore file */
import React, { useMemo, useState, MouseEvent } from 'react';
import MuiLink from '@mui/material/Link';
import Typography from 'uibuilder/Typography';
import { BreadcrumbItemProps } from 'shared/uibuilder/BreadCrumbs';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

const BreadcrumbItem = ({
  tag = 'span',
  link = '',
  active = true,
  children,
  options,
  onClickCallback,
}: BreadcrumbItemProps) => {
  const displayedValue = useMemo(() => children, [children]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleExpandButtonClick = (event: MouseEvent<HTMLButtonElement> | null) => {
    if (event) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {options?.length ? (
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {options
            ?.filter(option => option.label !== displayedValue)
            .map(({ value, label, onChangeCallback, route }) => (
              <MenuItem
                value={value}
                onClick={() => {
                  onChangeCallback(value, route);
                  handleClose();
                }}
              >
                {label}
              </MenuItem>
            ))}
        </Menu>
      ) : null}
      {active ? (
        <Typography
          component={tag as any}
          className="c-title"
          sx={{
            fontWeight: '500',
            display: 'flex',
            cursor: options?.length ? 'pointer' : 'default',
            alignItems: 'center',
          }}
          onClick={handleExpandButtonClick}
        >
          {displayedValue}
          {options?.length && <ExpandMoreIcon />}
        </Typography>
      ) : (
        <MuiLink
          component={tag as any}
          underline="hover"
          to={link}
          color="neutral.50"
          onClick={() => onClickCallback && onClickCallback()}
        >
          {displayedValue}
        </MuiLink>
      )}
    </>
  );
};

export default BreadcrumbItem;
