import BaseInputLayout, { BaseInputLayoutProps } from 'uibuilder/layout/form/input/BaseInputLayout';
import React from 'react';
import Box from 'uibuilder/Box';

const IconInputLayout = ({ label, children, ...props }: BaseInputLayoutProps) => {
  return (
    <BaseInputLayout {...props}>
      <Box display="flex" alignItems="center">
        {label as any}
        {children}
      </Box>
    </BaseInputLayout>
  );
};

export default IconInputLayout;
