import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const HLetterIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 2C3.79086 2 2 3.79086 2 6V14C2 16.2091 3.79086 18 6 18H14C16.2091 18 18 16.2091 18 14V6C18 3.79086 16.2091 2 14 2H6ZM6.71 13.92C6.76333 13.9733 6.8319 14 6.91571 14H8.16143C8.24524 14 8.31381 13.9733 8.36714 13.92C8.42048 13.8667 8.44714 13.7981 8.44714 13.7143V10.7657H11.5443V13.7143C11.5443 13.7981 11.571 13.8667 11.6243 13.92C11.6776 13.9733 11.7462 14 11.83 14H13.0757C13.1595 14 13.2281 13.9733 13.2814 13.92C13.3348 13.8667 13.3614 13.7981 13.3614 13.7143V6.28571C13.3614 6.2019 13.3348 6.13333 13.2814 6.08C13.2281 6.02667 13.1595 6 13.0757 6H11.83C11.7462 6 11.6776 6.02667 11.6243 6.08C11.571 6.13333 11.5443 6.2019 11.5443 6.28571V9.14286H8.44714V6.28571C8.44714 6.2019 8.42048 6.13333 8.36714 6.08C8.31381 6.02667 8.24524 6 8.16143 6H6.91571C6.8319 6 6.76333 6.02667 6.71 6.08C6.65667 6.13333 6.63 6.2019 6.63 6.28571V13.7143C6.63 13.7981 6.65667 13.8667 6.71 13.92Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default HLetterIcon;
