/* istanbul ignore file */
import React from 'react';
import MultiSelectInputFilter from 'shared/uibuilder/list/filter/MultiSelectInputFilter';
import { ConditionProps } from 'shared/uibuilder/list/filter/condition/Condition';
import MultiSelectField from 'erp/shared/input/MultiSelectField';
import employeeSuggestionsService from 'erp/employee/employeeSuggestionsService';
import { buildEmployeeAutoSuggestLabel } from 'erp/employee/shared/input/EmployeeInput';

const EmployeeMultiSelectFilter = ({ label, ...props }: Omit<ConditionProps, 'children'> & { label: string }) => {
  return (
    <MultiSelectInputFilter {...props}>
      <MultiSelectField
        {...props}
        label={label}
        useService={employeeSuggestionsService}
        noOptionLabel="Employee"
        buildLabelCallback={buildEmployeeAutoSuggestLabel}
      />
    </MultiSelectInputFilter>
  );
};

export default EmployeeMultiSelectFilter;
