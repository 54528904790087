export default {
  activityType: {
    type: 'enum',
    required: true,
  },

  // Only for activityType = additionalInterview
  interviewDateTime: {
    type: 'string',
    required: {
      value: true,
      when: {
        $activityType: {
          eq: 'additionalInterview',
        },
      },
    },
  },
  interviewType: {
    type: 'enum',
    required: {
      value: true,
      when: {
        $activityType: {
          eq: 'additionalInterview',
        },
      },
    },
  },
  interviewerAlias: {
    type: 'string',
    required: {
      value: true,
      when: {
        $activityType: {
          eq: 'additionalInterview',
        },
      },
    },
  },
  interviewMethod: {
    type: 'enum',
    required: {
      value: true,
      when: {
        $activityType: {
          eq: 'additionalInterview',
        },
      },
    },
  },
  resumeId: {
    type: 'array',
    required: {
      value: true,
      when: {
        $activityType: {
          eq: 'additionalInterview',
        },
        $interviewType: {
          eq: 'ROLE_RELATED',
        },
      },
    },
    maxSize: 1,
  },
  screeningMaterialsId: {
    type: 'array',
    maxSize: 1,
  },
  //

  referenceCheckDetails: {
    type: 'string',
    required: {
      value: true,
      when: {
        $activityType: {
          eq: 'referenceCheck',
        },
      },
    },
  },
  additionalMaterialDetails: {
    type: 'string',
    required: {
      value: true,
      when: {
        $activityType: {
          eq: 'additionalMaterials',
        },
      },
    },
  },
  testTaskDetails: {
    type: 'string',
    required: {
      value: true,
      when: {
        $activityType: {
          eq: 'testTask',
        },
      },
    },
  },
};
