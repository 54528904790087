import React, { useEffect } from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import PositionsInputs from './PositionsInputs';

const PositionsInputsListWrapper = ({ isLast, ...props }: any) => {
  const { getSource, getRawOnChangeCallback, getValue } = useInputHelper(props);
  const positions: any = getValue() || {};
  const rawOnChangeCallback = getRawOnChangeCallback();
  const source = getSource();

  useEffect(() => {
    if (!getValue()) {
      rawOnChangeCallback!({
        [source]: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <PositionsInputs isLast={isLast} value={positions} source={source} />;
};

export default PositionsInputsListWrapper;
