import { VacancyItem } from 'erp/recruitment/recruitingBoard/types';
import React from 'react';
import { VacancyPriority, VacancyPriorityShortLabels } from 'erp/recruitment/recruitingBoard/constants';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps } from 'shared/uibuilder/field';
import EditableBadge from 'shared/uibuilder/badge/EditableBadge';
import useRecruitingBoardService from 'erp/recruitment/recruitingBoard/useRecruitingBoardService';
import { RECRUITING_VACANCY_GROUP_UPDATE } from 'erp/recruitment/newVacancy/constants';
import { RECRUITING_CONTEXT } from 'erp/recruitment/RecruitingContext';
import useAuthorization from 'shared/authorization/authorizationService';

export const VacancyPriorityColor = {
  [VacancyPriority.HIGH]: 'error' as BasicColors,
  [VacancyPriority.MEDIUM]: 'warning' as BasicColors,
  [VacancyPriority.LOW]: 'success' as BasicColors,
};

interface PriorityBadgeProps extends FieldProps {
  prioritySource: string;
  idSource: string;
  onChange?: (vacancy: VacancyItem) => void;
}

const PriorityBadge = ({ idSource, prioritySource, onChange = () => {} }: PriorityBadgeProps) => {
  const { getValue: getPriority } = useFieldHelper({ source: prioritySource });
  const { getValue: getId } = useFieldHelper({ source: idSource });
  const { isGranted } = useAuthorization();

  const priority = getPriority() as VacancyPriority;
  const { updatePriority } = useRecruitingBoardService();

  const handleChange = async (newPriority: string) => {
    const updatedPriority = await updatePriority(getId(), newPriority);
    onChange(updatedPriority);
  };

  return (
    <EditableBadge
      color={VacancyPriorityColor[priority] as BasicColors}
      values={Object.values(VacancyPriority).map(value => ({
        value,
      }))}
      onChange={handleChange}
      canUpdate={isGranted(RECRUITING_VACANCY_GROUP_UPDATE, RECRUITING_CONTEXT)}
    >
      {VacancyPriorityShortLabels[priority]}
    </EditableBadge>
  );
};

export default PriorityBadge;
