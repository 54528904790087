/* istanbul ignore file */

import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import VacancyPublicationForm from '../VacancyPublicationForm';
import VacancyPublicationEntityHeader from 'erp/recruitment/vacancy/publication/shared/VacancyPublicationEntityHeader';
import UpdateVacancyPublicationBreadcrumbs from './Breadcrumbs';
import { useVacancyGroupId, useVacancyGroupUrl } from '../../../../routing';
import useVacancyPublicationService from '../../vacancyPublicationService';
import { useParams } from 'react-router-dom';

const UpdateVacancyPublication = () => {
  const { update, getById, getValidationSchema } = useVacancyPublicationService();
  const groupId = useVacancyGroupId();
  const { publicationId } = useParams<Dictionary<string>>();

  const { getPublicationUrl } = useVacancyGroupUrl();

  const getDataFunc = async (id: string) => {
    const response = await getById(id);

    const mapOfficeToLocation = (location: { country: string } | null) => {
      if (!location || !location.country) return null;

      const countryMapping: Record<string, number> = {
        US: 1,
        BY: 2,
        PL: 3,
        GE: 4,
      };

      return countryMapping[location.country] || 5;
    };

    const mapVacancyToDesiredFormat = (publication: any) => {
      const publicationDetail =
        publication.publicationDetails?.length > 0
          ? publication.publicationDetails[publication.publicationDetails.length - 1]
          : null;

      return {
        id: publication.id,
        vacancyGroupId: publication.vacancyGroupId,
        name: publication.name,
        type: publication.type,
        status: publication.status,
        description: publication.description,
        countryOfResidences: publication.countryOfResidences,
        englishLevels: publication.englishLevels,
        details: {
          id: publicationDetail?.id,
          contractType: publicationDetail?.contractType || [],
          location: publicationDetail?.location
            ? mapOfficeToLocation({ country: publicationDetail.location.country })
            : null,
          relocationOption: publicationDetail?.relocationOption,
        },
        formFields: publication.formFields.map((field: any) => {
          return {
            value: field.label,
            type: field.type,
            options: field.options || [],
          };
        }),
        createdAt: publication.createdAt,
        createdBy: publication.createdBy,
      };
    };

    return mapVacancyToDesiredFormat(response);
  };

  return (
    <UpdateForm
      submitFormFunc={(_, values) => update(publicationId!!, values)}
      getDataFunc={() => getDataFunc(publicationId!!)}
      getValidationSchemaFunc={getValidationSchema}
      afterSubmit={{
        redirect: getPublicationUrl(groupId, publicationId!!),
        message: 'You are awesome! The Vacancy Publication has been successfully saved.',
      }}
    >
      <UpdatePageLayout
        title="Edit: Vacancy Publication"
        breadcrumbs={<UpdateVacancyPublicationBreadcrumbs />}
        entityHeader={<VacancyPublicationEntityHeader />}
      >
        <VacancyPublicationForm />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateVacancyPublication;
