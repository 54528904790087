import React from 'react';
import Badge from 'shared/uibuilder/badge/Badge';
import { VACANCY_STATUS } from 'erp/recruitment/newVacancy/constants';

type LabelAllHiredProps = {
  status: keyof typeof VACANCY_STATUS;
  areAllHired: boolean;
};

const LabelAllHired = ({ status, areAllHired }: LabelAllHiredProps) => {
  if (![VACANCY_STATUS.OPEN, VACANCY_STATUS.REQUIRES_REVIEW, VACANCY_STATUS.CANCELLATION_REQUESTED].includes(status)) {
    return null;
  }

  if (!areAllHired) {
    return null;
  }

  return (
    <Badge
      sx={{
        marginLeft: '0',
        right: 0,
        minWidth: 80,
        paddingX: '10px',
        height: 25,
        color: 'white',
        fontSize: '12px',
      }}
      color="warning"
    >
      All Hired
    </Badge>
  );
};

export default LabelAllHired;
