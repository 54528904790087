export default {
  closeDate: {
    type: 'date',
    required: true,
  },
  startWorkingDate: {
    type: 'date',
    minDate: {
      value: '$closeDate',
      message: 'Please choose date after or at "Close" date',
    },
    required: true,
  },
};
