import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const TestTaskIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.4617 7.33715V9.17881C17.4617 9.34457 17.3958 9.50354 17.2786 9.62076C17.1614 9.73796 17.0024 9.80381 16.8367 9.80381C16.6709 9.80381 16.512 9.73796 16.3947 9.62076C16.2775 9.50354 16.2117 9.34457 16.2117 9.17881V7.33715C16.217 7.12101 16.1802 6.9059 16.1034 6.70381L10.02 10.3538V17.1788C10.1161 17.1505 10.2078 17.1116 10.295 17.0621L12.4034 15.8955C12.5465 15.82 12.7134 15.8031 12.8688 15.8481C13.0242 15.8932 13.1561 15.9968 13.2367 16.1371C13.3114 16.2813 13.3272 16.4489 13.2805 16.6045C13.2338 16.76 13.1285 16.8913 12.9867 16.9705L10.8867 18.1371C10.4255 18.394 9.90628 18.5288 9.37835 18.5288C8.85042 18.5288 8.33124 18.394 7.87002 18.1371L2.87002 15.3871C2.38751 15.1135 1.98559 14.7175 1.70472 14.2391C1.42384 13.7608 1.27391 13.2169 1.27002 12.6621V7.33715C1.27002 6.77965 1.42002 6.23298 1.70335 5.75381C1.75169 5.6677 1.80724 5.58715 1.87002 5.51215C1.89926 5.46737 1.93275 5.42551 1.97002 5.38715C2.21281 5.06899 2.51935 4.80502 2.87002 4.61215L7.87002 1.85381C8.33341 1.60364 8.85175 1.47266 9.37835 1.47266C9.90496 1.47266 10.4233 1.60364 10.8867 1.85381L15.8867 4.61215C16.2375 4.80465 16.5442 5.06881 16.7867 5.38715L16.8867 5.51215C16.95 5.58715 17.0056 5.6677 17.0534 5.75381C17.3318 6.23426 17.4731 6.78194 17.4617 7.33715Z"
          fill="currentColor"
        />
        <path
          d="M15.4366 15.2202C15.2969 15.2218 15.1583 15.1963 15.0283 15.1452C14.8978 15.0868 14.7792 15.0049 14.6783 14.9036L13.5116 13.7369C13.4523 13.6796 13.4052 13.611 13.373 13.5351C13.3408 13.4592 13.3242 13.3776 13.3242 13.2952C13.3242 13.2128 13.3408 13.1312 13.373 13.0553C13.4052 12.9794 13.4523 12.9108 13.5116 12.8536C13.6288 12.7365 13.7877 12.6708 13.9533 12.6708C14.1189 12.6708 14.2778 12.7365 14.395 12.8536L15.4366 13.8952L17.6783 11.6619C17.7968 11.5515 17.9535 11.4914 18.1154 11.4942C18.2773 11.4971 18.4318 11.5627 18.5463 11.6772C18.6608 11.7917 18.7264 11.9462 18.7293 12.1081C18.7321 12.27 18.672 12.4267 18.5616 12.5452L16.195 14.9119C16.0978 15.0096 15.9815 15.0862 15.8533 15.1369C15.722 15.1942 15.5799 15.2226 15.4366 15.2202Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default TestTaskIcon;
