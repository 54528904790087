import useBudgetingService from 'erp/serviceCatalog/budgets/budgetingService';
import React from 'react';
import UpdateForm from 'shared/uibuilder/form/UpdateForm';
import UpdateTimelineItemLayout
  from 'shared/uibuilder/timeline/layout/form/UpdateTimelineItemLayout';
import ServiceBudgetStructureForm
  from 'erp/serviceCatalog/budgets/structure/Create/ServiceBudgetStructureForm';
import { cloneDeep } from 'lodash';

const UpdateServiceBudgetStructure = ({ getDataFunc, entityId, onCancel, afterSubmit } : any) => {
  const { updateStructure, getValidationSchema } = useBudgetingService();

  const getData = () => {
    const data = cloneDeep(getDataFunc());
    data.categories = data.categories.map((name: string) => ({ name }));
    return data;
  };

  return <UpdateForm
    submitFormFunc={async (_id, values) => updateStructure(entityId, values)}
    afterSubmit={afterSubmit}
    getValidationSchemaFunc={getValidationSchema}
    getDataFunc={getData}
  >
    <UpdateTimelineItemLayout onCancel={onCancel}>
      <ServiceBudgetStructureForm />
    </UpdateTimelineItemLayout>
  </UpdateForm>
};

export default UpdateServiceBudgetStructure;
