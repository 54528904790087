import { useRecruitmentApi } from 'api';
import useBaseCrudService from 'shared/crud/baseCrudService';
import { useVacancyGroupId } from 'erp/recruitment/newVacancy/routing';
import vacancyPublicationValidation from 'erp/recruitment/newVacancy/show/publications/createupdate/vacancyPublicationValidation';
import useVacancyGroupService from 'erp/recruitment/newVacancy/useVacancyGroupService';
import { camelCase } from 'lodash';

interface VacancyGroup {
  specialization: string;
}

export const PUBLICATION_PLATFORM = {
  SYCOM: 'Syberry.com',
};

export const ENGLISH_LEVEL_OPTIONS = {
  BEGINNER: 'Beginner',
  ELEMENTARY: 'Elementary',
  INTERMEDIATE: 'Intermediate',
  'UPPER INTERMEDIATE': 'Upper Intermediate',
  ADVANCED: 'Advanced',
  PROFICIENCY: 'Proficiency',
};

export type EnglishLevel = keyof typeof ENGLISH_LEVEL_OPTIONS;

export const PUBLICATION_STATUS = {
  OPEN: 'Open',
  CLOSED: 'Closed',
  DRAFT: 'Draft',
};

const useVacancyPublicationService = () => {
  const groupId = useVacancyGroupId();
  const { getVacancyGroupById } = useVacancyGroupService();
  const { sendPatchRequest } = useRecruitmentApi();

  const {
    search: baseSearch,
    create: baseCreate,
    update: baseUpdate,
    getById: baseGetById,
  } = useBaseCrudService({
    listResourceUrl: '/vacancies/publications',
    singleResourceUrl: '/vacancies/publications/:id',
    apiService: useRecruitmentApi,
  });

  const mapVacancyPublicationToView = (data: { englishLevels: EnglishLevel[] }) => {
    return {
      ...data,
      englishLevels: data.englishLevels.map(englishLevel => ENGLISH_LEVEL_OPTIONS[englishLevel]),
    };
  };
  const mapToVacancyPublication = (formData: any) => {
    const data = { ...formData };

    if (data.formFields) {
      data.formFields = formData.formFields.map((formField: any) => ({
        ...formField,
        fieldName: camelCase(formField.value),
        label: formField.value,
      }));
    }

    return data;
  };

  const search = () => {
    return baseSearch({
      filter: {
        'vacancyGroup.id': {
          eq: groupId,
        },
      },
    });
  };
  const mapOfficeToLocation = (office: number) => {
    switch (office) {
      case 1:
        return { country: 'US', city: 'Austin' };
      case 2:
        return { country: 'BY', city: 'Minsk' };
      case 3:
        return { country: 'PL', city: 'Krakow' };
      case 4:
        return { country: 'GE', city: 'Tbilisi' };
      case 5:
      default:
        return null;
    }
  };

  const buildVacancyDetails = (payloadDetails: any) => [
    {
      id: payloadDetails.id || null,
      location: mapOfficeToLocation(Number(payloadDetails.location)),
      assignmentType: payloadDetails.assignmentType,
      workingSchedule: payloadDetails.workingSchedule,
      workplaceLocation: payloadDetails.workplaceLocation,
      contractType: payloadDetails.contractType,
      relocationOption: payloadDetails.relocationOption,
    },
  ];

  const processFormFields = (formFields: any[]) => {
    return formFields && formFields.length > 0 ? mapToVacancyPublication({ formFields }).formFields : [];
  };

  const create = async (payload: any) => {
    const vacancyDetails = buildVacancyDetails(payload.details);
    const processedFormFields = processFormFields(payload.formFields);

    return baseCreate({
      ...payload,
      details: undefined,
      publicationDetails: vacancyDetails,
      formFields: processedFormFields,
    });
  };

  const update = async (id: any, payload: any) => {
    const vacancyDetails = buildVacancyDetails(payload.details);
    const processedFormFields = processFormFields(payload.formFields);

    return baseUpdate(id, {
      ...payload,
      details: undefined,
      publicationDetails: vacancyDetails,
      formFields: processedFormFields,
    });
  };

  const getById = async (id: string) => {
    const result = await baseGetById(id);
    return mapVacancyPublicationToView(result);
  };

  const constructVacancyPublicationDescription = async (id: string) => {
    const vacancy = (await getVacancyGroupById(id)) as VacancyGroup;
    return {
      vacancyGroupId: id,
      name: vacancy.specialization,
    };
  };

  const changeStatus = async (vacancyGroupId: string, status: string) => {
    const result = await sendPatchRequest(`/vacancies/publications/${vacancyGroupId}`, { status });
    return result.json();
  };

  const updateStatus = async (vacancyGroupId: string, priority: string) => {
    const vacancy = await changeStatus(vacancyGroupId, priority);

    return vacancy;
  };

  const getValidationSchema = () => Promise.resolve(vacancyPublicationValidation);

  return {
    constructVacancyPublicationDescription,
    search,
    create,
    getValidationSchema,
    getById,
    update,
    updateStatus,
    searchWithFilters: baseSearch,
  };
};

export default useVacancyPublicationService;
