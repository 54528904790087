import React, { useEffect } from 'react';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import Button from 'uibuilder/button/Button';
import { SubmitButton } from 'uibuilder/button';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import CandidateResumeUploaderInput from 'artifact/shared/input/CandidateResumeUploaderInput';
import ZoneDateTimeInput, { ZonedApiDateFormat } from 'shared/uibuilder/form/input/ZoneDateTimeInput';
import GeneralInterviewerEmployeeInput
  from 'erp/recruitment/recruitingBoard/shared/input/GeneralInterviewerEmployeeInput';
import { ValidationSchema } from 'validation-schema-library/build/validation/utils';
import { EmployeeInput } from 'erp/employee';

interface InterviewScheduledFormProps {
  handleCancelClick: () => void;
  isGeneral?: boolean;
  validationSchema: ValidationSchema;
}

const InterviewScheduledForm = ({
                                  handleCancelClick,
                                  validationSchema,
                                  isGeneral = false,
                                }: InterviewScheduledFormProps) => {
  const { submitForm, isSubmitEnabled, isLoadingArtifact, setValidationSchemaAction } = useFormContext();

  useEffect(() => {
    if (setValidationSchemaAction) {
      setValidationSchemaAction(validationSchema);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ZoneDateTimeInput
        label="Interview Date & Time"
        source="interviewDateTime"
        isRequired
        apiDateFormat={ZonedApiDateFormat.ISO_8601_DATETIME_WITH_OFFSET}
      />
      {isGeneral
        ? <GeneralInterviewerEmployeeInput
          label="Interviewer"
          source="interviewerAlias"
          isRequired
        />
        : <EmployeeInput
          label="Interviewer"
          source="interviewerAlias"
          isRequired
        />
      }
      <Dropdown
        label="Interview Method"
        source="interviewMethod"
        options={[
          { value: 'online', label: 'Online' },
          { value: 'offline', label: 'Offline' },
        ]}
        isRequired
      />

      {!isGeneral
        ? <CandidateResumeUploaderInput label="Candidate's CV" source="resumeId" maxElements={1}
                                        isRequired />
        : <></>}

      {!isGeneral
        ? <CandidateResumeUploaderInput label="Screening materials" source="screeningMaterialsId"
                                        maxElements={1} />
        : <></>}

      <div className="btn-area">
        <SubmitButton
          key="submit-modal-btn"
          onClick={submitForm}
          isLoading={!isSubmitEnabled}
          disabled={isLoadingArtifact}
        />
        <Button
          outline
          key="cancel-modal-btn"
          onClick={handleCancelClick}
          disabled={!isSubmitEnabled || isLoadingArtifact}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default InterviewScheduledForm;
