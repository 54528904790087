export default {
  targetHiringDate: {
    type: 'date',
    required: {
      value: true,
      when: {
        $isAsap: {
          eq: false,
        },
      },
    },
    minDate: {
      value: '$TODAY',
      message: 'Must be in future.',
    },
    maxDate: {
      value: '$expirationDate',
      message: 'Please choose date earlier than Expiration.',
    },
  },

  hiringMode: {
    type: 'enum',
    required: true,
  },

  responsiblePerson: {
    type: 'string',
    required: true,
  },

  positions: {
    type: 'array',
    required: true,
    forEach: {
      type: 'object',
      properties: {
        type: {
          type: 'enum',
          required: true,
        },
        status: {
          type: 'enum',
          required: true,
        },
        forecastDate: {
          type: 'date',
          required: true,
          maxDate: {
            value: '$expirationDate',
            message: 'Please choose date before or at "Expiration" date',
          },
        },
      },
    },
  },

  workingConditions: {
    type: 'array',
    required: true,
    minSize: 1,
    forEach: {
      type: 'object',
      _divisible: false,
      properties: {
        hiringLocation: {
          type: 'enum',
          required: true,
        },
        employmentTypes: {
          type: 'array',
          required: true,
        },
        contractTypes: {
          type: 'array',
          required: true,
        },
        isRelocationAvailable: {
          type: 'enum',
          required: true,
        },
        compensation: {
          type: 'object',
          required: true,
          properties: {
            salaryFromValue: {
              type: 'number',
              required: true,
              regex: {
                value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
                message: 'Invalid number.',
              },
              maxValue: {
                value: '$salaryToValue',
                message: 'Min value cannot exceed max.',
              },
            },
            salaryToValue: {
              type: 'number',
              required: true,
              regex: {
                value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
                message: 'Invalid number.',
              },
              minValue: {
                value: '$salaryFromValue',
                message: 'Max value cannot be below min.',
              },
            },
            currency: {
              type: 'string',
              required: true,
            },
          },
        },
      },
    },
  },

  projects: {
    type: 'array',
    required: false,
    forEach: {
      type: 'object',
      _divisible: false,
      properties: {
        name: {
          type: 'string',
          required: false,
        },
        description: {
          type: 'string',
          required: false,
        },
      },
    },
  },

  responsibilities: {
    type: 'string',
    required: true,
  },

  mandatoryRequirements: {
    type: 'object',
    required: true,
    _divisible: false,
    properties: {
      description: {
        type: 'string',
        required: true,
      },
      skills: {
        type: 'array',
        required: false,
      },
      requiredExperienceInYears: {
        type: 'number',
        required: true,
      },
      englishLevel: {
        type: 'enum',
        required: true,
      },
    },
  },

  optionalRequirements: {
    type: 'object',
    required: false,
    _divisible: false,
    properties: {
      description: {
        type: 'string',
        required: false,
      },
      skills: {
        type: 'array',
        required: false,
      },
    },
  },

  notes: {
    type: 'object',
    required: false,
    _divisible: false,
    properties: {
      description: {
        type: 'string',
        required: false,
      },
      attachmentIds: {
        type: 'array',
        maxSize: 10,
      },
    },
  },
};
