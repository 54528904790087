import React from 'react';
import InterviewForm
  from 'erp/recruitment/recruitingBoard/shared/forms/InterviewForm/InterviewForm';
import roleRelatedInterviewFormValidationSchema
  from 'erp/recruitment/recruitingBoard/shared/forms/InterviewForm/roleRelatedInterviewFormValidationSchema';

interface RoleRelatedInterviewScheduledFormProps {
  handleCancelClick: () => void;
}

const RoleRelatedInterviewScheduledForm = (props: RoleRelatedInterviewScheduledFormProps) => {
  return <InterviewForm
    validationSchema={roleRelatedInterviewFormValidationSchema}
    {...props}
  />;
};

export default RoleRelatedInterviewScheduledForm;
