/* istanbul ignore file */
// libraries
import React from 'react';
// components
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import { TextField } from 'shared/uibuilder/field';
import AddEmployeeDomainButton from 'erp/employee/domains/shared/button/AddEmployeeDomainButton';
import AddDomainButton from 'erp/employee/domains/domain/button/AddDomainButton';
import EditEmployeeDomainButton from 'erp/employee/domains/shared/button/EditEmployeeDomainButton';
import DeleteEmployeeDomainButton from 'erp/employee/domains/shared/button/DeleteEmployeeDomainButton';
import useEmployeeDomainsShowHelper from 'erp/employee/domains/shared/employeeDomainsShowHelper';
import ControlledList from 'shared/uibuilder/list/ControlledList';
import NumberField from 'shared/uibuilder/field/NumberField';
import Tooltip from 'uibuilder/Tooltip';
import InfoIcon from '@mui/icons-material/InfoOutlined';

const EmployeeDomainsList = () => {
  const { currentData } = useEmployeeDomainsShowHelper();

  return (
    <ControlledList data={currentData}>
      <RelationListLayout header="Domains" actions={[<AddDomainButton />, <AddEmployeeDomainButton />]}>
        <DataGridLayout
          showTotal={false}
          buttons={[<EditEmployeeDomainButton source="id" />, <DeleteEmployeeDomainButton source="id" />]}
        >
          <TextField source="domain.name" label="Domain area" with={150} />
          <NumberField
            source="assignmentMonths"
            label={
              <Tooltip
                title="Maximum number of consecutive months working with more than 50% allocation within last 3 years"
                placement="top-start"
                arrow={false}
              >
                <div>Assignment months <InfoIcon fontSize='inherit'/></div>
              </Tooltip>
            }
          />
        </DataGridLayout>
      </RelationListLayout>
    </ControlledList>
  );
};

export default EmployeeDomainsList;
