/* istanbul ignore file */
import React from 'react';
import { CreateForm } from 'shared/uibuilder/form';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import VacancyPublicationForm from '../VacancyPublicationForm';
import CreateVacancyPublicationBreadcrumbs from './Breadcrumbs';
import useVacancyPublicationService from '../../vacancyPublicationService';
import { useVacancyGroupId, useVacancyGroupUrl } from '../../../../routing';

const CreateVacancyGroupPublication = () => {
  const { create, getValidationSchema } = useVacancyPublicationService();
  const groupId = useVacancyGroupId();
  const { getPublicationsUrl } = useVacancyGroupUrl();

  return (
    <CreateForm
      submitFormFunc={create}
      afterSubmit={{
        redirect: getPublicationsUrl(groupId),
        message: 'You are awesome! The Vacancy Publication has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout title="Create: Vacancy Publication" breadcrumbs={<CreateVacancyPublicationBreadcrumbs />}>
        <VacancyPublicationForm isCreateForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateVacancyGroupPublication;
