import React, { useState } from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import Button from 'uibuilder/button/Button';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import ModalForm from 'shared/uibuilder/form/ModalForm';
import FormSection from 'shared/layout/form/FormSection';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import { useDateService } from 'shared/uibuilder/dateService';
import useVacancyGroupService from '../../../../useVacancyGroupService';

const ClosePositionForm = ({ closeModal, modalOpen, setGeneralContextData }: any) => {
  const { closeVacancyPosition } = useVacancyGroupService();
  const { data: position } = useShowContext();
  const positionData = position.getData();

  const { getClosePositionValidationSchema } = useVacancyGroupService();

  const { getCurrentDateTime, formatDateForAPI } = useDateService();
  const now = formatDateForAPI(getCurrentDateTime());

  return (
    <ModalForm
      getValidationSchemaFunc={getClosePositionValidationSchema}
      closeModal={closeModal}
      modalOpen={modalOpen}
      submitFormFunc={data => closeVacancyPosition(positionData.vacancyId, positionData.id, data)}
      afterSubmit={{
        execute: () => {
          if (setGeneralContextData) {
            setGeneralContextData();
          }
        },
      }}
      initialData={{
        closeDate: now,
        startWorkingDate: now,
      }}
    >
      <FormSection>
        <DateInput source="closeDate" label="Close date" />

        <DateInput source="startWorkingDate" label="Start Working Date" />
      </FormSection>
    </ModalForm>
  );
};

const ClosePositionButton = ({ setGeneralContextData }: any) => {
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          setModalOpen(true);
          (e.target as HTMLElement).blur();
        }}
      >
        Close
      </Button>
      <ModalWindow backdrop="static" isOpen={modalOpen} title="Close position" onToggle={closeModal}>
        <ClosePositionForm
          setGeneralContextData={setGeneralContextData}
          closeModal={closeModal}
          modalOpen={modalOpen}
        />
      </ModalWindow>
    </>
  );
};

export default ClosePositionButton;
