/* istanbul ignore file */
import React, { useEffect } from 'react';
import Button from 'uibuilder/button/Button';
import { SubmitButton } from 'uibuilder/button';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import ZoneDateTimeInput, { ZonedApiDateFormat } from 'shared/uibuilder/form/input/ZoneDateTimeInput';
import screeningFormValidationSchema from './screeningFormValidationSchema';
import GeneralInterviewerEmployeeInput
  from 'erp/recruitment/recruitingBoard/shared/input/GeneralInterviewerEmployeeInput';

interface ScreeningFormProps {
  handleCancelClick: () => void;
}

const ScreeningForm = ({ handleCancelClick }: ScreeningFormProps) => {
  const { submitForm, isSubmitEnabled, isLoadingArtifact, setValidationSchemaAction } = useFormContext();

  useEffect(() => {
    if (setValidationSchemaAction) {
      setValidationSchemaAction(screeningFormValidationSchema);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ZoneDateTimeInput
        label="Interview date"
        source="interviewDateTime"
        apiDateFormat={ZonedApiDateFormat.ISO_8601_DATETIME_WITH_OFFSET}
        isRequired
      />
      <GeneralInterviewerEmployeeInput
        label="Interviewer"
        source="interviewerAlias"
        isRequired
      />

      <div className="btn-area">
        <SubmitButton
          key="submit-modal-btn"
          onClick={submitForm}
          isLoading={!isSubmitEnabled}
          disabled={isLoadingArtifact}
        />
        <Button
          outline
          key="cancel-modal-btn"
          onClick={handleCancelClick}
          disabled={!isSubmitEnabled || isLoadingArtifact}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default ScreeningForm;
