import PriorityBadge from 'erp/recruitment/recruitingBoard/shared/badge/PriorityBadge';
import React from 'react';
import { BadgeField } from 'shared/uibuilder/field';
import TextField from 'shared/uibuilder/field/TextField';
import { TextInput } from 'shared/uibuilder/form/input';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import Box from 'uibuilder/Box';
import Typography from '@mui/material/Typography';
import Grid from 'uibuilder/Grid';
import Tooltip from 'uibuilder/Tooltip';
import OfficeAndAccommodationIcons from './OfficeAndAccommodationIcons';
import VacancyGroupStatusBadge from '../vacancyGroup/shared/badge/VacancyGroupStatusBadge';

interface ShowSectionTitleProps {
  data: any;
  actions?: React.ReactNode;
  isEdit?: boolean;
}

const VacancyGroupSectionTitle: React.FC<ShowSectionTitleProps> = ({ data = {}, actions, isEdit = false }) => {
  const { specialization, details: { accommodations = [], competencyLevel } = {} } = data;
  const { setData = () => {} } = useShowContext();

  return (
    <Box sx={{ width: '100%' }}>
      <Box display="flex" alignItems="center" gap={1} sx={{ width: '100%' }}>
        <Typography variant="h4" sx={{ marginBottom: '5px' }}>
          {competencyLevel} {specialization}
        </Typography>

        <BadgeField
          style={{ marginRight: '20px' }}
          badges={[
            <PriorityBadge
              idSource="id"
              prioritySource="priority"
              onChange={response => {
                setData({
                  ...data,
                  data: { ...data, priority: response.priority },
                  ...response,
                });
              }}
            />,
          ]}
        />

        <OfficeAndAccommodationIcons accommodations={accommodations} registrations={[]} />

        <Box display="flex" justifyContent="flex-end" alignItems="center" flexGrow={1}>
          <BadgeField
            width={100}
            badges={[<VacancyGroupStatusBadge idSource="id" statusSource="details.status" isEdit={isEdit} />]}
          />
          {actions}
        </Box>
      </Box>
      <Grid xs={12} sm={4}>
        {isEdit ? (
          <Tooltip title="Specific Note" placement="left">
            <TextInput source="specificNote" label="" className="small-input" tooltip="Specific Note" />
          </Tooltip>
        ) : (
          <Typography variant="subtitle1" sx={{ fontSize: 9, color: '#757575' }}>
            <TextField source="specificNote" label="" />
          </Typography>
        )}
      </Grid>
    </Box>
  );
};

export default VacancyGroupSectionTitle;
