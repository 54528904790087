import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import useEmployeeService, {
  READ_EMPLOYEE_SPECIALIZATION_INFO,
  UPDATE_EMPLOYEE_SPECIALIZATION_INFO,
} from 'erp/employee/employeeService';
import React, { useCallback, useState } from 'react';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import Button from 'uibuilder/button/Button';
import { EditOutlined } from '@mui/icons-material';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import TextField from 'shared/uibuilder/field/TextField';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import ModalForm from 'shared/uibuilder/form/ModalForm';
import FormSection from 'shared/layout/form/FormSection';
import SpecializationDropdown
  from 'erp/recruitment/newVacancy/shared/inputs/SpecializationDropdown';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { COMPETENCY_LEVEL_WITHOUT_NA } from 'erp/recruitment/newVacancy/constants';
import useAuthorization from 'shared/authorization/authorizationService';

const SpecializationInfoSection = () => {
  const {
    data, setData = () => {
    },
  } = useShowContext();
  const { isGranted } = useAuthorization();
  const { update } = useEmployeeService();
  const [modalsState, setModalsState] = useState<{ [key: string]: boolean }>({
    updateSpecializationInfo: false,
  });
  const toggleModal = useCallback((modalKey: string) => {
    setModalsState(prev => ({ ...prev, [modalKey]: !prev[modalKey] }));
  }, []);

  return <ShowSection
    title="Specialization"
    titleVariant="h2"
    actions={isGranted(UPDATE_EMPLOYEE_SPECIALIZATION_INFO) ? <Button
      size="small"
      outline
      onClick={() => {
        toggleModal('updateSpecializationInfo');
      }}
    >
      <EditOutlined />
    </Button> : <></>}
    isVisible={isGranted(READ_EMPLOYEE_SPECIALIZATION_INFO)}
  >

    <SectionRow>
      <TextField label="Primary Specialization" source="specializationInfo.specialization" />
      <TextField label="Secondary Specialization"
                 source="specializationInfo.secondarySpecialization" />
      <TextField label="Competency Level" source="specializationInfo.competencyLevel" />
    </SectionRow>

    <ModalWindow isOpen={modalsState.updateSpecializationInfo}
                 onToggle={() => toggleModal('updateSpecializationInfo')}>
      <ModalForm
        closeModal={() => toggleModal('updateSpecializationInfo')}
        modalOpen={modalsState.updateSpecializationInfo}
        submitFormFunc={(fields) => update(data.getData().alias, fields)}
        initialData={data.getData()}
        afterSubmit={{
          execute: (response: any) => setData(response),
        }}
      >
        <FormSection>
          <SpecializationDropdown source="specializationInfo.specialization"
                                  tooltip="Select Specialization or create new." />
          <SpecializationDropdown source="specializationInfo.secondarySpecialization"
                                  tooltip="Select Specialization or create new." />
          <EnumDropdown
            source="specializationInfo.competencyLevel"
            options={COMPETENCY_LEVEL_WITHOUT_NA}
            label="Competency Level"
          />
        </FormSection>
      </ModalForm>
    </ModalWindow>
  </ShowSection>;
};

export default SpecializationInfoSection;
