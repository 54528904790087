import React, { useEffect, useMemo, useState } from 'react';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import VacancyGroupsBreadcrumbs from './VacancyGroupsBreadcrumbs';
import { useVacancyGroupId, useVacancyGroupUrl } from 'erp/recruitment/newVacancy/routing';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useFormContext } from 'shared/uibuilder/form/FormContext';

const SingleVacancyBreadcrumbs = ({
  children = null,
  onClickCallback,
}: {
  children?: React.ReactNode | React.ReactNode[] | null;
  onClickCallback: () => void;
}) => {
  const { data } = useShowContext();
  const { data: formData } = useFormContext();
  const { getSingleEntityUrl } = useVacancyGroupUrl();
  const id = useVacancyGroupId();

  const [initialEntityName, setInitialEntityName] = useState<string | null>(null);

  useEffect(() => {
    if (!initialEntityName && formData?.name) {
      setInitialEntityName(formData.name);
    }
  }, [formData?.name, initialEntityName]);

  const entity = useMemo(() => {
    return initialEntityName || data.getValueBySource('specialization');
  }, [data, initialEntityName]);

  return (
    <VacancyGroupsBreadcrumbs>
      <SingleBreadcrumbItem entity={entity} link={getSingleEntityUrl(id)} onClickCallback={onClickCallback} />
      {children}
    </VacancyGroupsBreadcrumbs>
  );
};

export default SingleVacancyBreadcrumbs;
