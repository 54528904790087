import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import useCandidateService from 'erp/candidate/shared/candidateService';
import useCrudService from 'shared/crud';
import { useRecruitmentApi } from 'api';
import { Card, CardContent, CardHeader, StepIconProps, Stepper, SvgIcon } from '@mui/material';
import Typography from 'uibuilder/Typography';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Tooltip from 'uibuilder/Tooltip';
import { columnNames } from 'erp/recruitment/recruitingBoard/constants';
import useVacancyGroupService from 'erp/recruitment/newVacancy/useVacancyGroupService';
import LoadingDecorator from 'shared/uibuilder/LoadingDecorator';
import { EMPTY_VALUE_INDICATOR } from 'shared/uibuilder/helper';
import { CROP_SIZES } from 'artifact/const';
import Avatar from '@mui/material/Avatar';
import useArtifactThumbnailService from 'artifact/artifactThumbnailService';
import DraggableItem from 'erp/recruitment/recruitingBoard/shared/board/DraggableItem';
import { Application, MappedApplication } from 'erp/recruitment/recruitingBoard/types';
import useDateTimeService from 'shared/uibuilder/dateService/useDateTimeService';
import { useVacancyGroupUrl } from 'erp/recruitment/newVacancy/routing';

const getPossibleLinkedInLinks = (linkedInId: string) => [
  linkedInId,
  `https://linkedin.com/in/${linkedInId}`,
  `https://linkedin.com/in/${linkedInId}/`,
  `https://www.linkedin.com/in/${linkedInId}`,
  `https://www.linkedin.com/in/${linkedInId}/`,
  `linkedin.com/in/${linkedInId}`,
  `linkedin.com/in/${linkedInId}/`,
  `www.linkedin.com/in/${linkedInId}`,
  `www.linkedin.com/in/${linkedInId}/`,
];

const CustomStepIcon = (props: StepIconProps) => {
  const { active, completed } = props;

  return (
    <SvgIcon
      sx={{
        width: 15,
        height: 15,
        color: active || completed ? 'primary.main' : 'grey.400',
      }}
    >
      {completed ? (
        <circle cx="14" cy="10" r="9" fill="currentColor" />
      ) : (
        <circle cx="14" cy="10" r="9" stroke="currentColor" strokeWidth={2} fill="transparent" />
      )}
    </SvgIcon>
  );
};
const ApplicationStatusStepper = ({ activeStep, steps }: { activeStep: string; steps: string[] }) => {
  return (
    <Stepper activeStep={steps.indexOf(activeStep)}>
      {steps.map(step => (
        <Step key={step}>
          <Tooltip title={step}>
            <StepLabel
              slots={{
                stepIcon: CustomStepIcon,
              }}
            />
          </Tooltip>
        </Step>
      ))}
    </Stepper>
  );
};

const CandidateContextPage = () => {
  const linkedInId = useParams<Dictionary<string>>().linkedInId!;
  const { search: searchCandidates } = useCandidateService();
  const { search: searchVacancyGroup } = useVacancyGroupService();
  const { search: searchApplications } = useCrudService({
    singleResourceUrl: '/candidates/applications/:id',
    listResourceUrl: '/candidates/applications',
    apiService: useRecruitmentApi,
  });
  const { getThumbnails } = useArtifactThumbnailService();
  const { getDateInCurrentTimezone } = useDateTimeService();
  const { getSingleEntityUrl } = useVacancyGroupUrl();

  const [candidate, setCandidate] = useState<any>(null);
  const [candidatePhoto, setCandidatePhoto] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const applicationMap = useCallback(
    (entityId: string, application: Application): MappedApplication => ({
      id: application.id,
      version: application.version,
      entityId,
      candidateId: application.candidateId,
      name: application.candidateName,
      vacancyId: application.vacancyId,
      eventTimestamp: getDateInCurrentTimezone(application.applicationPipelineState.stageTransitionTimestamp),
      stage: application.applicationPipelineState.stage,
      tooltipData: application.applicationPipelineState.info,
      undoStates: application.applicationPipelineState.undoStates,
      activeStates: application.applicationPipelineState.jobsStates,
      canDrag: true,
    }),
    [getDateInCurrentTimezone],
  );

  useEffect(() => {
    const findCandidate = async () => {
      const response = await searchCandidates({
        filter: {
          linkedInLink: { in: getPossibleLinkedInLinks(linkedInId) },
        },
        sort: {
          updatedAt: 'DESC',
        },
      });

      const [candidateDto] = response.result;

      let vacancy: any;
      try {
        if (candidateDto?.lastCandidateApplication?.vacancyAlias) {
          [vacancy] = (
            await searchVacancyGroup({
              filter: {
                alias: { eq: candidateDto?.lastCandidateApplication?.vacancyAlias },
              },
              sort: { createdAt: 'DESC' },
              pageSize: 1,
            })
          ).result;
        }
      } catch {
        vacancy = null;
      }

      let application: any;
      try {
        [application] = (
          await searchApplications({
            filter: {
              candidateId: { eq: candidateDto.id },
            },
            sort: {
              updatedAt: 'DESC',
            },
            pageSize: 1,
          })
        ).result;
      } catch {
        application = null;
      }

      if (candidateDto?.mainPhotoId) {
        const thumbnailsResponse = await getThumbnails(candidateDto?.mainPhotoId);
        setCandidatePhoto(thumbnailsResponse[CROP_SIZES.SIZE_100]);
      }

      setCandidate({
        ...candidateDto,
        vacancy,
        application,
      });
      setLoading(false);
    };
    findCandidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const steps = columnNames.map(column => column.name);

  const renderValue = (value: any) => value ?? EMPTY_VALUE_INDICATOR;
  return (
    <LoadingDecorator loading={loading} deps={[loading]}>
      <Card>
        <CardHeader
          avatar={
            <Avatar
              src={candidatePhoto}
              alt="Candidate"
              sx={{
                width: 75,
                height: 75,
              }}
            />
          }
          title={renderValue(candidate?.name?.fullName)}
          subheader={<a href={getSingleEntityUrl(candidate?.vacancy?.id)} target="_blank" rel="noreferrer">
            {renderValue(candidate?.vacancy?.searchString)}
          </a>}
          action={
            <>
              <Typography variant="body2" color="text.secondary">
                {renderValue(candidate?.personalEmail)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {renderValue(candidate?.personalPhoneNumber)}
              </Typography>
            </>
          }
        />
        <CardContent>
          {candidate?.application ? (
            <>
              <ApplicationStatusStepper
                activeStep={candidate?.application?.applicationPipelineState?.stage}
                steps={steps}
              />
              <br />
              <DraggableItem
                key="key"
                item={applicationMap(candidate?.vacancy?.id, candidate?.application)}
                columnSettings={
                  columnNames.filter(
                    settings => settings.name === candidate?.application?.applicationPipelineState?.stage,
                  )[0]
                }
              />
            </>
          ) : (
            <></>
          )}
        </CardContent>
      </Card>
    </LoadingDecorator>
  );
};

export default CandidateContextPage;
