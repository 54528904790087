import React from 'react';
import SecurityContextTemplate, { SecurityContextCommonProps } from 'shared/authorization/SecurityContextTemplate';
import { useRecruitmentApi } from 'api';

export const RECRUITING_CONTEXT = 'Recruiting';

const RecruitingContext = ({ permissionToCheck, children }: SecurityContextCommonProps) => {
  const { sendGetRequest } = useRecruitmentApi();

  const getRecruitingPermissions = async (): Promise<string[]> => {
    const response = await sendGetRequest(`/permissions`);
    return response.json();
  };

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getRecruitingPermissions}
      permissionToCheck={permissionToCheck}
      contextName={RECRUITING_CONTEXT}
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default RecruitingContext;
