import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const ALetterIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 2C3.79086 2 2 3.79086 2 6V14C2 16.2091 3.79086 18 6 18H14C16.2091 18 18 16.2091 18 14V6C18 3.79086 16.2091 2 14 2H6ZM6.16818 13.9314C6.2139 13.9771 6.27104 14 6.33961 14H7.52818C7.64247 14 7.72628 13.9733 7.77961 13.92C7.83294 13.8667 7.86723 13.8171 7.88247 13.7714L8.37389 12.4914H11.5968L12.0882 13.7714C12.1034 13.8171 12.1377 13.8667 12.191 13.92C12.2444 13.9733 12.3282 14 12.4425 14H13.631C13.6996 14 13.7568 13.9771 13.8025 13.9314C13.8482 13.8781 13.871 13.821 13.871 13.76C13.871 13.7219 13.8672 13.6876 13.8596 13.6571L11.1396 6.30857C11.1168 6.22476 11.071 6.15238 11.0025 6.09143C10.9339 6.03048 10.8387 6 10.7168 6H9.25389C9.13199 6 9.03675 6.03048 8.96818 6.09143C8.89961 6.15238 8.85389 6.22476 8.83104 6.30857L6.11104 13.6571C6.10342 13.6876 6.09961 13.7219 6.09961 13.76C6.09961 13.821 6.12247 13.8781 6.16818 13.9314ZM11.1625 11.0171H8.79675L9.98532 7.72571L11.1625 11.0171Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default ALetterIcon;
