import useVacancyGroupService from "erp/recruitment/newVacancy/useVacancyGroupService";
import { SearchInput, ValidationProps } from "shared/uibuilder/form/input";
import { FormFieldsData } from "validation-schema-library/build/validation/types";
import React from 'react';

export interface VacancyGroupInputProps extends ValidationProps {
    source: string;
    label?: string;
    nameSource?: string;
    defaultFilter?: Dictionary<object>;
    isVisible?: boolean | ((data: FormFieldsData) => boolean);
    disabled?: boolean | ((data: FormFieldsData) => boolean);
    props?: any;
    initialLabel?: string;
    onChangeCallback?: (values: FormFieldsData) => void;
    onInputChangeCallback?: (query: any) => void;
    valueDecorator?: (searchValue: string) => string;
  }

const VacancyGroupInput = (props: VacancyGroupInputProps) => {
    const { search } = useVacancyGroupService();

    const mapGroup = (group: any) => ({
        id: group.id,
        text: group.searchString,
    });

    return <SearchInput 
        searchRequest={search}
        noResultMessage="No Vacancy Groups are found."
        mapResults={mapGroup}
        {...props}
    />;
};

export default VacancyGroupInput;
