/* istanbul ignore file */
import React from 'react';
import Button from 'uibuilder/button/Button';
import useSubmit from 'shared/useSubmit';
import useVacancyGroupService from 'erp/recruitment/newVacancy/useVacancyGroupService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const DeclineVacancyCancellationRequestButton = ({
  setGeneralContextData,
  setModalOpen,
}: {
  setGeneralContextData: () => {};
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { declineVacancyCancellation } = useVacancyGroupService();

  const { data } = useShowContext();
  const { id } = data.getData();

  const { onSubmit } = useSubmit({
    submitMethod: () => declineVacancyCancellation(id!),
    afterSubmit: {
      successMessage: 'Vacancy cancellation request has been successfully declined.',
      errorMessage: "Can't decline cancellation request.",
      execute: () => {
        if (setGeneralContextData) {
          setGeneralContextData();
        }
      },
    },
  });

  return (
    <Button
      onClick={() => {
        onSubmit();
        setModalOpen(false);
      }}
      variant="outlined"
    >
      Decline
    </Button>
  );
};

export default DeclineVacancyCancellationRequestButton;
