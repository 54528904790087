/* istanbul ignore file */
import React from 'react';
import LinkButton, { LinkButtonProps } from 'uibuilder/button/LinkButton';
import { useVacancyGroupId, useVacancyGroupUrl } from '../../../../routing';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

const ViewVacancyPublicationButton = (props: Omit<LinkButtonProps, 'children' | 'url'>) => {
  const groupId = useVacancyGroupId();
  const { getValue: getPublicationId } = useFieldHelper({
    source: 'id',
  });
  const { getPublicationUrl } = useVacancyGroupUrl();

  return (
    <LinkButton {...props} outline url={getPublicationUrl(groupId, getPublicationId())}>
      View
    </LinkButton>
  );
};

export default ViewVacancyPublicationButton;
