import SectionRow, { SectionRowProps } from 'shared/uibuilder/layout/SectionRow';
import UiThemeOverrider from 'shared/uibuilder/UiThemeOverrider';
import IconFieldLayout from 'uibuilder/layout/field/IconFieldLayout';
import IconInputLayout from 'uibuilder/layout/form/input/IconInputLayout';
import React from 'react';

const IconSectionRow = ({
  children,
  spacing = { xs: 1, md: 1 },
  colXl = 3,
  colMd = 3,
  containerProps = { alignItems: 'center' },
  ...props
}: SectionRowProps) => {
  return (
    <UiThemeOverrider
      overriddenLayouts={{
        BaseFieldLayout: IconFieldLayout,
        BaseInputLayout: IconInputLayout,
      }}
    >
      <SectionRow spacing={spacing} colXl={colXl} colMd={colMd} containerProps={containerProps} {...props}>
        {children}
      </SectionRow>
    </UiThemeOverrider>
  );
};

export default IconSectionRow;
