/* istanbul ignore file */
import React from 'react';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import useCustomHtmlTagsHelper from 'uibuilder/customHtmlTagsHelper';
import HtmlWithArtifactFieldDecorator from 'artifact/shared/field/HtmlWithArtifactFieldDecorator';
import { FieldLayoutProps } from 'shared/uibuilder/field';
import useAsyncValue from 'shared/asyncHelper';

const HtmlFieldLayoutWithTags = ({ value, label }: FieldLayoutProps) => {
  const { BaseFieldLayout } = useUiTheme();
  const { mapTags, mapAsyncTags } = useCustomHtmlTagsHelper();

  const syncData = mapTags(value);
  const mappedTags = useAsyncValue(async () => mapAsyncTags(syncData));

  return (
    <HtmlWithArtifactFieldDecorator>
      <BaseFieldLayout label={label} value={mappedTags || syncData} />
    </HtmlWithArtifactFieldDecorator>
  );
};

export default HtmlFieldLayoutWithTags;
