import { useEffect, useState, useMemo } from 'react';
import getLoadingParams from 'shared/uibuilder/list/builder/listBuilderHelper';
import { LoadingParams, SearchParams } from '../ListContext';
import { useDateService } from '../../dateService';

export interface ListUpdatingHelperParams {
  loadData: (params: LoadingParams, isNeedToUpdateLoading: boolean) => Promise<any>;
  params: SearchParams;
  pageSize?: number;
  autoUpdateTime?: number;
}

const useListDataUpdating = ({ loadData, params, pageSize, autoUpdateTime }: ListUpdatingHelperParams) => {
  const { getCurrentDateTime } = useDateService();
  const [data, setData] = useState({
    items: [],
    totalPages: 0,
    totalElements: 0,
    lastUpdate: '',
  });
  const loadingParams = useMemo(() => getLoadingParams(params, pageSize), [pageSize, params]);

  const handleLoad = async (isNeedToUpdateLoading = true) => {
    const response = await loadData(loadingParams, isNeedToUpdateLoading);
    setData({
      items: response.result || [],
      totalPages: response.totalPages || 0,
      totalElements: response.totalElements || 0,
      lastUpdate: getCurrentDateTime().toISOString(),
    });
  };

  useEffect(() => {
    handleLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingParams]);

  // Auto update
  useEffect(() => {
    if (autoUpdateTime) {
      const intervalId = setInterval(() => {
        handleLoad(false);
      }, autoUpdateTime);

      return () => clearInterval(intervalId);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingParams, autoUpdateTime]);

  return {
    data,
    reloadData: handleLoad,
  };
};

export default useListDataUpdating;
