/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import VacancyGroupStatusDropdown from '../../shared/input/VacancyGroupStatusDropdown';

const VacancyGroupStatusFilter = (props: any) => {
  return (
    <DropDownFilter {...props}>
      <VacancyGroupStatusDropdown />
    </DropDownFilter>
  );
};

export default VacancyGroupStatusFilter;
