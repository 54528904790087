/* istanbul ignore file */
import React, { useEffect } from 'react';
import { CurrencyInput } from 'shared/uibuilder/form/input';
import Button from 'uibuilder/button/Button';
import { SubmitButton } from 'uibuilder/button';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import CurrencyDropdown from 'erp/employee/finance/shared/inputs/CurrencyDropdown';
import { CURRENCY_TYPES } from 'erp/employee/finance/shared/financeService';
import FormRow from 'shared/layout/form/FormRow';
import offerFormValidationSchema from './offerFormValidationSchema';

interface OfferFormProps {
  handleCancelClick: () => void;
}

const OfferForm = ({ handleCancelClick }: OfferFormProps) => {
  const { submitForm, isSubmitEnabled, isLoadingArtifact, setValidationSchemaAction } = useFormContext();

  useEffect(() => {
    if (setValidationSchemaAction) {
      setValidationSchemaAction(offerFormValidationSchema);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormRow>
        <CurrencyInput source="offerSum" label="Offer Sum" isRequired />
        <CurrencyDropdown source="compensationCurrency" options={CURRENCY_TYPES} label="Currency" />
      </FormRow>

      <div className="btn-area">
        <SubmitButton
          key="submit-modal-btn"
          onClick={submitForm}
          isLoading={!isSubmitEnabled}
          disabled={isLoadingArtifact}
        />
        <Button
          outline
          key="cancel-modal-btn"
          onClick={handleCancelClick}
          disabled={!isSubmitEnabled || isLoadingArtifact}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default OfferForm;
