import React from 'react';
import InterviewForm
  from 'erp/recruitment/recruitingBoard/shared/forms/InterviewForm/InterviewForm';
import generalInterviewFormValidationSchema
  from 'erp/recruitment/recruitingBoard/shared/forms/InterviewForm/generalInterviewFormValidationSchema';

interface GeneralInterviewScheduledFormProps {
  handleCancelClick: () => void;
}

const GeneralInterviewScheduledForm = (props: GeneralInterviewScheduledFormProps) => {
  return <InterviewForm
    validationSchema={generalInterviewFormValidationSchema}
    isGeneral
    {...props}
  />;
};

export default GeneralInterviewScheduledForm;
