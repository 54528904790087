/* istanbul ignore file */
import React from 'react';
import { Dropdown } from 'shared/uibuilder/form/input';
import useDropdownHelper, { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';
import { ENGLISH_LEVEL_OPTIONS } from 'erp/recruitment/newVacancy/show/publications/vacancyPublicationService';

export interface EnumDropdownProps extends Omit<DefaultDropdownProps, 'options'> {
  options: Dictionary<string>;
}

const MinimalAndBetterEnglishLevelEnumDropdown = ({ options, ...props }: EnumDropdownProps) => {
  const { getSource, getRawOnChangeCallback } = useDropdownHelper(props);
  const rawOnChangeCallback = getRawOnChangeCallback() || (() => {});

  const onChangeCallback = (values: any) => {
    const source = getSource();
    const selectedValues = values[source];
    const englishLevelValues = Object.keys(ENGLISH_LEVEL_OPTIONS);
    const lowestSelected = englishLevelValues.find((option: string) => selectedValues?.includes(option));

    if (lowestSelected) {
      const greaterThanSelected: string[] = englishLevelValues.slice(englishLevelValues.indexOf(lowestSelected));

      rawOnChangeCallback({
        [source]: greaterThanSelected,
      });
    }
  };

  return (
    <Dropdown
      {...props}
      options={Object.keys(options).map(key => ({
        value: key,
        label: options[key],
      }))}
      onChangeCallback={onChangeCallback}
    />
  );
};

export default MinimalAndBetterEnglishLevelEnumDropdown;
