export default {
  firstWorkingDate: {
    type: 'string',
    required: true,
  },
  vacancyId: {
    type: 'string',
    required: true,
  },
};
