/* istanbul ignore file */

import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useVacancyGroupUrl } from 'erp/recruitment/newVacancy/routing';
import { FieldProps, LinkField } from 'shared/uibuilder/field';
import React from 'react';

const PublicationLinkField = ({ label }: FieldProps) => {
  const { getValue: getName } = useFieldHelper({ source: 'name' });
  const { getValue: getId } = useFieldHelper({ source: 'id' });
  const { getSingleEntityUrl } = useVacancyGroupUrl();
  return (
    <LinkField
      label={label}
      linkText={getName()}
      value={`${getSingleEntityUrl()}/publications/${getId()}`}
      isInternal
    />
  );
};

export default PublicationLinkField;
