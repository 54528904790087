import React, { useEffect } from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import ApproveVacancyCompensationsInputs from 'erp/recruitment/newVacancy/shared/inputs/ApproveVacancyCompensationsInputs';
import { WorkingCondition } from 'erp/recruitment/newVacancy/types';

const ApproveVacancyCompensationsInputsListWrapper = ({ isLast, ...props }: any) => {
  const { getSource, getRawOnChangeCallback, getValue } = useInputHelper(props);
  const workingCondition: WorkingCondition & { id: string } = getValue() || {};
  const rawOnChangeCallback = getRawOnChangeCallback();
  const source = getSource();

  const registration = workingCondition.hiringLocation.split('_', 1)[0];

  useEffect(() => {
    if (!getValue()) {
      rawOnChangeCallback!({
        [source]: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ApproveVacancyCompensationsInputs
      isLast={isLast}
      registration={registration}
      value={workingCondition}
      source={source}
    />
  );
};

export default ApproveVacancyCompensationsInputsListWrapper;
