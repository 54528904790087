import { EmployeeInput } from 'erp/employee';
import React from 'react';
import { cloneDeep } from 'lodash';
import useEmployeeSuggestionsService from 'erp/employee/employeeSuggestionsService';
import { ValidationProps } from 'shared/uibuilder/form/input';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';

export interface InterviewerEmployeeInputProps extends ValidationProps {
  source: string;
  label?: string;
  nameSource?: string;
  mapResults?: (employee: any) => void;
  defaultFilter?: Dictionary<object>;
  isVisible?: boolean | ((data: FormFieldsData) => boolean);
  disabled?: boolean | ((data: FormFieldsData) => boolean);
  props?: any;
  withContractors?: boolean;
  initialLabel?: string;
  onChangeCallback?: (values: FormFieldsData) => void;
  onInputChangeCallback?: (query: any) => void;
  valueDecorator?: (searchValue: string) => string;
}

const GeneralInterviewerEmployeeInput = (props: InterviewerEmployeeInputProps) => {
  const { advancedSearch } = useEmployeeSuggestionsService();

  const searchInterviewers = (filter: any) => {
    const searchFilter = cloneDeep(filter) as any;

    const searchStringFilter = searchFilter.filter.searchString ? {
      filterType: 'FILTER',
      property: 'searchString',
      operator: 'CT',
      value: searchFilter.filter.searchString.ct,
    } : null;

    return advancedSearch({
      filter: {
        filterType: 'FILTER_GROUP',
        condition: 'AND',
        filters: [
          {
            filterType: 'FILTER_GROUP',
            condition: 'OR',
            filters: [
              {
                filterType: 'FILTER',
                property: 'specification:isRecruiter',
                operator: 'EQ',
                value: true,
              },
              {
                filterType: 'FILTER',
                property: 'specification:isHrManager',
                operator: 'EQ',
                value: true,
              },
            ],
          },
          searchStringFilter
        ].filter(Boolean),
      }} as any)
  };

  return <EmployeeInput
    searchRequest={searchInterviewers}
    {...props}
  />
};

export default GeneralInterviewerEmployeeInput;
