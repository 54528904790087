import { SvgIconProps } from '@mui/material';
import React from 'react';
import { AppIconType, AppIconTypeToSvgIcon } from 'uibuilder/AppIcon/const';

export interface AppIconProps extends SvgIconProps {
  icon: AppIconType;
}

const AppIcon = ({ icon, viewBox = '0 0 20 20', sx = { fontSize: '20px' }, ...props }: AppIconProps) => {
  const Icon = AppIconTypeToSvgIcon[icon];

  return <Icon sx={sx} viewBox={viewBox} {...props} />;
};

export default AppIcon;
