/* istanbul ignore file */
import React from 'react';
import { Link } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';
import { useCandidateUrl } from 'erp/candidate/CandidateRouter';

const CandidateLink = ({
  style,
  color,
  name,
  id,
  onClick,
}: {
  name?: string;
  id: number | string;
  style?: Dictionary<string>;
  color?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}) => {
  const { getSingleEntityUrl } = useCandidateUrl();

  return (
    <>
      {onClick ? (
        <MuiLink
          style={style}
          onClick={onClick}
          role="button"
          color={color || 'primary'}
          sx={{ cursor: 'pointer', textDecoration: 'none', color: 'var(--mui-palette-primary-main)' }}
        >
          {name || id}
        </MuiLink>
      ) : (
        <Link style={style} color={color} to={getSingleEntityUrl(id)} target="_blank" onClick={onClick}>
          {name || id}
        </Link>
      )}
    </>
  );
};

export default CandidateLink;
