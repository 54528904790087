import React from 'react';
import { Routes } from 'react-router-dom';
import ProtectedRouter from 'routing/ProtectedRouter';
import { Route } from 'shared/routing';
import RecruitingContext from '../RecruitingContext';
import { RECRUITING_REPORT_PATH, useRecruitingReportPath } from './routing';
import { RECRUITING_REPORT_GENERATION_READ } from './useReportService';
import ReportsListPage from './List';

const ReportRouter = () => {
  const { listRoute } = useRecruitingReportPath();

  return (
    <ProtectedRouter basePath={RECRUITING_REPORT_PATH}>
      <Routes>
        <Route
          path={listRoute}
          element={
            <RecruitingContext permissionToCheck={RECRUITING_REPORT_GENERATION_READ}>
              <ReportsListPage />
            </RecruitingContext>
          }
        />
      </Routes>
    </ProtectedRouter>
  );
};

ReportRouter.getRouterPath = () => `${RECRUITING_REPORT_PATH}/*`;

export default ReportRouter;
