import React, { useState, useCallback, useEffect } from 'react';
import Show from 'shared/uibuilder/show/Show';
import TabsWrapper from 'shared/uibuilder/TabsWrapper/TabsWrapper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import ViewVacancyContent from './ViewVacancyContent';
import UpdateVacancy from '../../createupdate/update/UpdateVacancy';
import { MapVacancyType } from '../../types';
import { RECRUITING_VACANCY_MOVE_TO_GROUP, RECRUITING_VACANCY_UPDATE_VACANCY, VACANCY_STATUS } from '../../constants';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import ModalForm from 'shared/uibuilder/form/ModalForm';
import VacancyGroupInput from './shared/input/VacancyGroupInput';
import useVacancyGroupService from '../../useVacancyGroupService';
import Loading from 'shared/uibuilder/Loading';
import { Button } from 'uibuilder/button';
import Add from '@mui/icons-material/Add';
import VacancyInput from './shared/input/VacancyInput';
import useAuthorization from 'shared/authorization/authorizationService';
import { RECRUITING_CONTEXT } from 'erp/recruitment/RecruitingContext';
import useVacancyPublicationService
  from 'erp/recruitment/newVacancy/show/publications/vacancyPublicationService';
import { useRecruitmentApi } from 'api';

export const VACANCIES_MODES = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
};

interface VacancyViewWrapperProps {
  vacancy: MapVacancyType;
  refreshData: () => Promise<void>;
  moveToGroup: (vacancyId: string, groupId: string | null) => Promise<void>;
  modalsState: Dictionary<boolean>;
  toggleModal: (modalKey: string) => void;
  vacancyGroupId: string;
}

const VacancyViewWrapper: React.FC<VacancyViewWrapperProps> = ({
  vacancy,
  refreshData,
  moveToGroup,
  modalsState,
  toggleModal,
  vacancyGroupId,
}) => (
  <>
    <ViewVacancyContent setGeneralContextData={refreshData} />

    <ModalWindow
      title="Move vacancy to another group"
      isOpen={modalsState.moveToGroup}
      onToggle={() => toggleModal('moveToGroup')}
    >
      <ModalForm
        closeModal={() => toggleModal('moveToGroup')}
        modalOpen={modalsState.moveToGroup}
        submitFormFunc={values => moveToGroup(vacancy.id, values.vacancyGroupId)}
        afterSubmit={{ execute: refreshData }}
      >
        <VacancyGroupInput label="Target Vacancy Group" source="vacancyGroupId" />
      </ModalForm>
    </ModalWindow>

    <ModalWindow isOpen={modalsState.removeFromGroup} onToggle={() => toggleModal('removeFromGroup')}>
      <ModalForm
        closeModal={() => toggleModal('removeFromGroup')}
        modalOpen={modalsState.removeFromGroup}
        submitFormFunc={() => moveToGroup(vacancy.id, null)}
        afterSubmit={{ execute: refreshData }}
      >
        <p>Are you sure that you want to remove this vacancy from group?</p>
      </ModalForm>
    </ModalWindow>

    <ModalWindow isOpen={modalsState.addVacancy} onToggle={() => toggleModal('addVacancy')}>
      <ModalForm
        closeModal={() => toggleModal('addVacancy')}
        modalOpen={modalsState.addVacancy}
        submitFormFunc={values => moveToGroup(values.vacancyId, vacancyGroupId)}
        afterSubmit={{ execute: refreshData }}
      >
        <VacancyInput source="vacancyId" />
      </ModalForm>
    </ModalWindow>
  </>
);

const VacanciesWrapper = () => {
  const [rerenderCount, setRerenderCount] = useState(0);

  const [isLoading, setLoading] = useState(false);
  const { isGranted } = useAuthorization();
  const { getVacancyGroupById, moveToGroup } = useVacancyGroupService();
  const { searchWithFilters } = useVacancyPublicationService();
  const { data, setData = () => {} } = useShowContext();

  // TODO: Use appropriate service instead of API drirectly, when implemented
  const { sendPostRequest } = useRecruitmentApi();

  const [hasActiveApplications, setHasActiveApplications] = useState(true);
  const [hasActivePublications, setHasActivePublications] = useState(true);

  useEffect(() => {
    (async () => {
      const applications = await sendPostRequest('/candidates/applications/search', {
        filter: {
          'vacancyGroupAlias': {
            in: [data.getValueBySource('alias')],
          },
          currentPipelineStage: {
            not_in: ['Rejected', 'Closed'],
          },
        },
        pageSize: 1,
      });
      const result = await applications.json();
      setHasActiveApplications(result.totalElements !== 0);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      const publications = await searchWithFilters({
        filter: {
          'vacancyGroup.id': {
            eq: data.getValueBySource('id'),
          },
          status: {
            eq: 'OPEN'
          }
        },
        pageSize: 1,
      } as any);
      setHasActivePublications(publications.totalElements !== 0)
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const vacancies = data?.getValueBySource('vacancies') || [];
  const [tabsState, setTabsState] = useState({ currentActiveIndex: 0, mode: VACANCIES_MODES.VIEW });
  const [modalsState, setModalsState] = useState<Dictionary<boolean>>({
    moveToGroup: false,
    removeFromGroup: false,
    addVacancy: false,
  });

  const toggleModal = useCallback((modalKey: string) => {
    setModalsState(prev => ({ ...prev, [modalKey]: !prev[modalKey] }));
  }, []);

  const refreshData = async () => {
    setLoading(true);
    const newData = await getVacancyGroupById(data.id);
    setData(newData);
    setRerenderCount(prev => prev + 1);
    setLoading(false);
  };

  const renderTabs = () =>
    vacancies.map((vacancy: MapVacancyType, index: number) => ({
      index,
      label: vacancy.title,
      content: (
        <Show key={`${vacancy.id}-${rerenderCount}`} getDataMethod={async () => vacancy}>
          {tabsState.mode === VACANCIES_MODES.EDIT && tabsState.currentActiveIndex === index ? (
            <UpdateVacancy vacancy={vacancy} setGeneralContextData={refreshData} setTabsState={setTabsState} />
          ) : (
            <VacancyViewWrapper
              vacancy={vacancy}
              vacancyGroupId={data.id}
              refreshData={refreshData}
              moveToGroup={moveToGroup}
              modalsState={modalsState}
              toggleModal={toggleModal}
            />
          )}
        </Show>
      ),
      menuItems: createMenuItems(vacancy, index),
    }));

  const createMenuItems = (vacancy: MapVacancyType, index: number) => {
    const canMove = vacancy.status !== VACANCY_STATUS.REQUIRES_REVIEW &&
      (data.getValueBySource('vacancies') > 1 || (!hasActiveApplications && !hasActivePublications));
    const moveDisabledReason = <ul>
      {vacancy.status === VACANCY_STATUS.REQUIRES_REVIEW ? <li>Can not move this vacancy, as it requires review.</li> : <></>}
      {hasActiveApplications ? <li>Can not move this vacancy, as it&apos;s the last one in the group with active applications.</li> : <></>}
      {hasActivePublications ? <li>Can not move this vacancy, as it&apos;s the last one in the group with active publications.</li> : <></>}
    </ul>

    return [
      {
        label: 'Edit',
        disabled: tabsState.mode === VACANCIES_MODES.EDIT || vacancy.status !== VACANCY_STATUS.OPEN,
        isVisible: isGranted(RECRUITING_VACANCY_UPDATE_VACANCY, RECRUITING_CONTEXT),
        menuAction: () => setTabsState({ currentActiveIndex: index, mode: VACANCIES_MODES.EDIT }),
      },
      {
        label: 'Move to group',
        disabled: tabsState.mode === VACANCIES_MODES.EDIT || !canMove,
        disabledReason: moveDisabledReason,
        isVisible: isGranted(RECRUITING_VACANCY_MOVE_TO_GROUP, RECRUITING_CONTEXT),
        menuAction: () => toggleModal('moveToGroup'),
      },
      {
        label: 'Remove from group',
        disabled: tabsState.mode === VACANCIES_MODES.EDIT || !canMove,
        disabledReason: moveDisabledReason,
        isVisible: isGranted(RECRUITING_VACANCY_MOVE_TO_GROUP, RECRUITING_CONTEXT),
        menuAction: () => toggleModal('removeFromGroup'),
      },
    ];
  };

  return (
    <>
      <TabsWrapper
        sxContentProps={{ p: 0 }}
        sxTab={{ p: '0 0 0 5px' }}
        changeTabCallback={index => setTabsState({ currentActiveIndex: index, mode: VACANCIES_MODES.VIEW })}
        buttons={[
          tabsState.mode === VACANCIES_MODES.VIEW ? (
            <Button onClick={() => toggleModal('addVacancy')} sx={{ marginLeft: 2 }} link title="Add vacancy">
              <Add />
              <p>Add vacancy</p>
            </Button>
          ) : null,
        ]}
        tabs={renderTabs()}
      />
      {isLoading && <Loading hasBackground hasPositionFixed />}
    </>
  );
};

export default VacanciesWrapper;
