/* istanbul ignore file */
import React from 'react';
import ControlledShow from 'shared/uibuilder/show/ControlledShow';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import QualificationsListBreadcrumbs
  from 'erp/employee/qualification/List/shared/QualificationsBreadcrumbs';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import useEmployeeNewQualificationShowHelper
  from 'erp/employee/newQualification/Show/employeeNewQualificationShowHelper';
import CareerDevelopmentReviewSection
  from 'erp/employee/qualification/Show/CareerDevelopmentReviewSection';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import useAuthorization from 'shared/authorization/authorizationService';
import { VIEW_EMPLOYEE_BOOKS } from 'erp/employee/books/shared/readBooksService';
import RelatedReadBooksList from 'erp/employee/books/list/RelatedReadBooksList';
import { NewCompetencyTypeLabels } from 'erp/qualification/newCompetency/newCompetencyService';
import NewCompetencyQualificationModel from './NewCompetencyQualificationModel';
import NewQualificationMenu from 'erp/qualification/newCompetency/shared/NewQualificationMenu';
import EmployeeDomainsList from 'erp/employee/domains/list/EmployeeDomainsList';
import { READ_EMPLOYEE_DOMAIN_LIST } from 'erp/employee/domains/shared/employeeDomainsService';
import EmployeeCommonSkillsList from 'erp/employee/commonSkills/list/EmployeeCommonSkillsList';
import {
  READ_EMPLOYEE_COMMON_SKILL_LIST,
} from 'erp/employee/commonSkills/shared/employeeCommonSkillsService';
import SpecializationInfoSection
  from 'erp/employee/newQualification/Show/SpecializationInfoSection';

const EmployeeQualificationListPage = () => {
  const { currentType, setCurrentType, currentData } = useEmployeeNewQualificationShowHelper();
  const { isGranted } = useAuthorization();

  return (
    <ShowPageLayout
      entityHeader={<EmployeeEntityHeader />}
      menu={<EmployeeMenu />}
      breadcrumbs={<QualificationsListBreadcrumbs />}
    >
      <CareerDevelopmentReviewSection />

      <SpecializationInfoSection />

      <ShowSection title="Qualifications" titleVariant="h2">
        <ControlledShow data={currentData}>
          <div>
            <NewQualificationMenu setCurrentType={setCurrentType} currentType={currentType} />
            <NewCompetencyQualificationModel data={currentData} title={NewCompetencyTypeLabels[currentType]} />
          </div>
        </ControlledShow>
      </ShowSection>

      {/* section with domains */}
      <ShowSection isVisible={isGranted(READ_EMPLOYEE_DOMAIN_LIST)}>
        <EmployeeDomainsList />
      </ShowSection>

      {/* section with common skills */}
      <ShowSection isVisible={isGranted(READ_EMPLOYEE_COMMON_SKILL_LIST)}>
        <EmployeeCommonSkillsList />
      </ShowSection>

      <ShowSection isVisible={isGranted(VIEW_EMPLOYEE_BOOKS)}>
        <RelatedReadBooksList />
      </ShowSection>
    </ShowPageLayout>
  );
};

export default EmployeeQualificationListPage;
