import React from 'react';
import { TimelineType } from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/timelineLayoutHelper';
import BaseTimelineRecordLayout from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import Collapsible from 'shared/uibuilder/field/Collapsible';
import DateField from 'shared/uibuilder/field/DateField';
import { CommaListField } from 'shared/uibuilder/field';
import { BUDGET_OBJECTIVE_UNIT_OPTIONS } from '../budgetVersionService';
import EditTimelineRecordButton from 'uibuilder/layout/timeline/buttons/EditTimelineRecordButton';
import { BUDGETING_CREATE_STRUCTURE } from 'erp/serviceCatalog/budgets/budgetingService';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const ServiceBudgetStructureRecordLayout = ({ onEdit, ...props }: any) => {
  const formatObjective = (objective: any) => {
    return `${objective.name} (${BUDGET_OBJECTIVE_UNIT_OPTIONS[objective.unit]})`;
  };
  const buttons = [
    <EditTimelineRecordButton onEdit={onEdit} permissionToCheck={BUDGETING_CREATE_STRUCTURE} />,
  ];

  const { data } = useShowContext();

  return (
    <BaseTimelineRecordLayout
      timelineType={TimelineType.NOTE}
      buttons={buttons}
      entity={data}
      {...props}
      fields={
        <Collapsible>
          <ShowSection>
            <DateField label="Effective Date" source="effectiveDate" dateFormat={DATE_FORMAT.API_ONLY_MONTH} />
            <CommaListField label="Categories" source="categories" />
            <CommaListField label="Objectives" source="objectives" mapValue={formatObjective} />
          </ShowSection>
        </Collapsible>
      }
    />
  );
};

export default ServiceBudgetStructureRecordLayout;
