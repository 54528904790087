/* istanbul ignore file */
import React from 'react';
import { ApplicationSettings } from 'shared/uibuilder/applicationSettingsContext';
import DefaultInputLayout from 'uibuilder/layout/form/input/DefaultInputLayout';
import FormRow from 'shared/layout/form/FormRow';
import DefaultDropdownLayout from 'uibuilder/layout/form/input/DefaultDropdownLayout';
import DefaultTextAreaLayout from 'uibuilder/layout/form/input/TextAreaLayout';
import DavinciFormPageLayout from 'uibuilder/layout/DavinciFormPageLayout';
import HtmlFieldLayoutWithTags from 'uibuilder/layout/field/HtmlFieldLayoutWithTags';
import PhoneInputLayout from 'uibuilder/layout/form/input/PhoneInputLayout';
import LabeledFieldLayout from 'uibuilder/layout/field/LabeledFieldLayout';
import ButtonsSelectInputLayout from 'uibuilder/layout/form/input/ButtonsSelectInputLayout';
import ExternalLinkFieldLayout from 'uibuilder/layout/field/ExternalLinkFieldLayout';
import InternalLinkFieldLayout from 'uibuilder/layout/field/InternalLinkFieldLayout';
import EmailFieldLayout from 'uibuilder/layout/field/EmailFieldLayout';
import PhoneFieldLayout from 'uibuilder/layout/field/PhoneFieldLayout';
import WysiwygInputLayout from 'uibuilder/layout/form/input/WysiwygInputLayout';
import DateInputLayout from 'uibuilder/layout/form/input/DateInputLayout';
import AutosuggestionDropdownLayout from 'uibuilder/layout/form/input/AutosuggestionDropdownLayout';
import InputListLayout from 'uibuilder/layout/form/input/InputListLayout';
import RadioButtonGroupLayout from 'uibuilder/layout/form/input/RadioButtonGroupLayout';
import CheckboxGroupLayout from 'uibuilder/layout/form/input/CheckboxGroupLayout';
import CheckboxLayout from 'uibuilder/layout/form/input/CheckboxLayout';
import TogglerInputLayout from 'uibuilder/layout/form/input/TogglerInputLayout';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import SingleLineNameFieldLayout from 'uibuilder/layout/field/SingleLineNameFieldLayout';
import NameInputLayout from 'uibuilder/layout/form/input/NameInputLayout';
import NumberInputLayout from 'uibuilder/layout/form/input/NumberInputLayout';
import ArtifactsListLayout from 'artifact/shared/field/layout/ArtifactsListLayout';
import MultiSelectInputLayout from 'uibuilder/layout/form/input/MultiSelectInputLayout';
import SelectInputLayout from 'uibuilder/layout/form/input/SelectInputLayout';
import ButtonDropdownLayout from 'uibuilder/layout/form/input/ButtonDropdownLayout';
import DropDownFilterLayout from 'shared/uibuilder/list/filter/layout/PopupFiltersLayout/filter/DropDownFilterLayout';
import DateRangeFilterLayout from 'shared/uibuilder/list/filter/layout/PopupFiltersLayout/filter/DateRangeFilterLayout';
import CheckboxGroupFilterLayout from 'shared/uibuilder/list/filter/layout/PopupFiltersLayout/filter/CheckboxGroupFilterLayout';
import LoadMultiSelectInputLayout from 'uibuilder/layout/form/input/LoadMultiSelectInputLayout';
import ImageLayout from 'artifact/shared/field/layout/AttachmentFieldLayout/ImageLayout';
import DocumentLayout from 'artifact/shared/field/layout/AttachmentFieldLayout/DocumentLayout';
import IconFieldLayout from './layout/field/IconFieldLayout';
import BaseInputLayout from './layout/form/input/BaseInputLayout';

const DavinciSettings = ({ children }: { children: React.ReactNode }) => {
  const { getTimezone } = useAuthenticatedUserService();

  const theme = {
    // pages
    FormPageLayout: DavinciFormPageLayout,
    // inputs
    BaseInputLayout,
    InputLayout: DefaultInputLayout,
    DropdownLayout: DefaultDropdownLayout,
    TextAreaLayout: DefaultTextAreaLayout,
    AddressInputLayout: FormRow,
    NameInputLayout,
    NumberInputLayout,
    PhoneInputLayout,
    ButtonsSelectInputLayout,
    WysiwygInputLayout,
    DateInputLayout,
    MultiSelectInputLayout,
    LoadMultiSelectInputLayout,
    SelectInputLayout,
    ButtonDropdownLayout,
    // fields
    BaseFieldLayout: LabeledFieldLayout,
    AddressFieldLayout: SectionRow,
    NameFieldLayout: SingleLineNameFieldLayout,
    ExternalLinkLayout: ExternalLinkFieldLayout,
    InternalLinkLayout: InternalLinkFieldLayout,
    AttachmentsListLayout: ArtifactsListLayout,
    PhoneFieldLayout,
    EmailFieldLayout,
    HtmlFieldLayout: HtmlFieldLayoutWithTags,
    AutosuggestionDropdownLayout,
    InputListLayout,
    RadioButtonGroupLayout,
    CheckboxLayout,
    CheckboxGroupLayout,
    TogglerInputLayout,
    IconFieldLayout,

    DropDownFilterLayout,
    DateRangeFilterLayout,
    CheckboxGroupFilterLayout,

    ArtifactImageLayout: ImageLayout,
    ArtifactDocumentLayout: DocumentLayout,
  };

  return (
    <ApplicationSettings theme={theme} getTimezone={getTimezone}>
      {children}
    </ApplicationSettings>
  );
};

export default DavinciSettings;
