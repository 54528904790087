import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const WorkIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="39" height="37" viewBox="0 0 39 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.5 36.375C3.46875 36.375 2.58625 36.0081 1.8525 35.2744C1.11875 34.5406 0.75125 33.6575 0.75 32.625V12C0.75 10.9688 1.1175 10.0862 1.8525 9.3525C2.5875 8.61875 3.47 8.25125 4.5 8.25H12V4.5C12 3.46875 12.3675 2.58625 13.1025 1.8525C13.8375 1.11875 14.72 0.75125 15.75 0.75H23.25C24.2812 0.75 25.1644 1.1175 25.8994 1.8525C26.6344 2.5875 27.0012 3.47 27 4.5V8.25H34.5C35.5312 8.25 36.4144 8.6175 37.1494 9.3525C37.8844 10.0875 38.2513 10.97 38.25 12V32.625C38.25 33.6562 37.8831 34.5394 37.1494 35.2744C36.4156 36.0094 35.5325 36.3763 34.5 36.375H4.5ZM15.75 8.25H23.25V4.5H15.75V8.25Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default WorkIcon;
