/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import { EditButtonProps } from 'uibuilder/button/EditButton';
import Button from 'uibuilder/button/Button';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import useAuthorization from 'shared/authorization/authorizationService';
import ModalForm from 'shared/uibuilder/form/ModalForm';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import useVacancyService from 'erp/recruitment/newVacancy/useVacancyGroupService';
import TextField from 'shared/uibuilder/field/TextField';
import DateField from 'shared/uibuilder/field/DateField';
import EmployeeLinkFieldWithNameLoading from 'erp/employee/shared/field/EmployeeLinkFieldWithNameLoading';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import Show from 'shared/uibuilder/show/Show';
import { Stack } from '@mui/material';
import ControlledList from 'shared/uibuilder/list/ControlledList';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import BadgeField from 'shared/uibuilder/field/BadgeField';
import Badge from 'shared/uibuilder/badge/Badge';
import Alert from 'uibuilder/Alert';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import VacancyGroupInput
  from 'erp/recruitment/newVacancy/show/vacancy/shared/input/VacancyGroupInput';

const MigrateVacancyButton = ({ permissionToCheck, ...props }: EditButtonProps) => {
  const { data: vacancy } = useShowContext();
  const { mapLegacyVacancy, migrateVacancy } = useVacancyService();
  const [modalOpen, setModalOpen] = useState(false);
  const [mappedVacancy, setMappedVacancy] = useState<any>({});
  const [migrateHiringRequests, setMigrateHiringRequests] = useState(true);
  const [vacancyToMerge, setVacancyToMerge] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      setMappedVacancy(await mapLegacyVacancy(vacancy));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = () => {
    setModalOpen(false);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const { isGranted } = useAuthorization();

  if (permissionToCheck && !isGranted(permissionToCheck)) {
    return null;
  }

  const submit = async () => {
    await migrateVacancy(
      mappedVacancy.group,
      mappedVacancy.vacancies,
      mappedVacancy.publications,
      migrateHiringRequests,
      vacancyToMerge,
    );
  };

  return (
    <>
      <Button {...props} onClick={openModal}>
        Migrate
      </Button>

      <ModalWindow
        backdrop="static"
        isOpen={modalOpen}
        onToggle={closeModal}
        title="Migration plan"
        modalSize="md"
        hasCancelButton
      >
        {!mappedVacancy.error ? (
          <ModalForm closeModal={closeModal} modalOpen={modalOpen} submitFormFunc={submit}>
            <Show getDataMethod={() => mappedVacancy}>
              <ShowSection title="Vacancy Group" titleVariant="h5">
                <Stack direction="row" spacing={4}>
                  <TextField label="Alias" source="group.alias" />
                  <TextField label="Specialization" source="group.specialization" />
                  <DateField label="Created At" source="group.createdAt" />
                  <EmployeeLinkFieldWithNameLoading label="Created By" source="group.createdBy" />
                </Stack>
              </ShowSection>
            </Show>
            <ShowSection title="Vacancies" titleVariant="h5">
              <FormControlLabel
                control={<Checkbox
                  checked={migrateHiringRequests}
                  key="migrate-hiring-requests-checkbox"
                  onChange={(event: any) => setMigrateHiringRequests(event.target.checked)}
                />}
                label="Migrate hiring requests related to this vacancy?"
              />
              {migrateHiringRequests ?
              <ControlledList data={{ items: mappedVacancy.vacancies ?? [], totalPages: 1 }}>
                <DataGridLayout>
                  <BadgeField
                    label="Specialization"
                    width={200}
                    badges={[
                      <Badge
                        isVisible={(data: any) => data.status === 'OPEN'}
                        className="ml-2"
                        color="primary"
                        {...props}
                      >
                        Open
                      </Badge>,
                      <Badge
                        isVisible={(data: any) => data.status === 'CLOSED'}
                        className="ml-2"
                        color="neutral"
                        {...props}
                      >
                        Closed
                      </Badge>,
                      <Badge
                        isVisible={(data: any) => data.status === 'CANCELLED'}
                        className="ml-2"
                        color="neutral"
                        {...props}
                      >
                        Cancelled
                      </Badge>,
                      <Badge
                        isVisible={(data: any) => data.status === 'REQUIRES_REVIEW'}
                        className="ml-2"
                        color="warning"
                        {...props}
                      >
                        Requires Review
                      </Badge>,
                    ]}
                  >
                    <TextField source="specialization" />
                  </BadgeField>
                  <TextField label="Competency Level" source="competencyLevelMin" />
                  <DateField source="targetHiringDate" />
                  <DateField source="expirationDate" />
                  <TextField source="hiringMode" />
                  <DateField source="createdAt" />
                  <EmployeeLinkFieldWithNameLoading source="createdBy" />
                </DataGridLayout>
              </ControlledList> : <VacancyGroupInput
                  label="Vacancy group to move vacancies from:"
                  source="vacancyGroup"
                  defaultFilter={{
                    specialization: {
                      eq: mappedVacancy.group.specialization
                    }
                  }}
                  onChangeCallback={(data: any) => setVacancyToMerge(data.vacancyGroup)}
                />}
            </ShowSection>
            <ShowSection title="Vacancy Publications" titleVariant="h5">
              <ControlledList data={{ items: mappedVacancy.publications ?? [], totalPages: 1 }}>
                <DataGridLayout>
                  <BadgeField
                    label="Name"
                    width={200}
                    badges={[
                      <Badge
                        isVisible={(data: any) => data.status === 'OPEN'}
                        className="ml-2"
                        color="primary"
                        {...props}
                      >
                        Open
                      </Badge>,
                      <Badge
                        isVisible={(data: any) => data.status === 'DRAFT'}
                        className="ml-2"
                        color="neutral"
                        {...props}
                      >
                        Draft
                      </Badge>,
                    ]}
                  >
                    <TextField source="name" />
                  </BadgeField>
                  <TextField label="Type" source="type" />
                  <TextField label="Country Of Residences" source="countryOfResidences" />
                  <TextField label="English Levels" source="englishLevels" />
                  <DateField label="Craeted At" source="createdAt" />
                  <EmployeeLinkFieldWithNameLoading source="createdBy" />
                </DataGridLayout>
              </ControlledList>
            </ShowSection>
          </ModalForm>
        ) : (
          <>
            <Alert color="warning">{mappedVacancy.error}</Alert>
          </>
        )}
      </ModalWindow>
    </>
  );
};

export default MigrateVacancyButton;
