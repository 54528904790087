/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import AccountLinkField from 'crm/account/shared/field/AccountLinkField';
import ArtifactStorageFileField from 'artifactStorage/shared/field/ArtifactStorageFileField';
import ProjectLink from 'erp/project/shared/field/ProjectLink';
import List from 'shared/uibuilder/list/List';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import { TextField } from 'shared/uibuilder/field';
import 'artifactStorage/Show/shared/field/ArtifactStorageShowPage.scss';
import ProjectWeeklyReportLink from 'erp/project/weeklyreports/shared/field/ProjectWeeklyReportLink';
import LeadLinkField from 'crm/lead/shared/field/LeadLinkField';

interface ArtifactRelationFieldProps {
  source: string;
}

const MESSAGE_CONTEXT = 'ArtifactStorageFile_RelationsTable';

export const mapArtifactRelations = (info: any) => {
  const mappedRelation = {
    entityClass: info.entityClass,
    linkField: <></>,
  };

  switch (info.entityClass) {
    case 'Account':
      mappedRelation.linkField = (
        <AccountLinkField
          idSource=""
          value={info.entity.accountName}
          idValue={info.entity.accountId}
          isInternal={false}
        />
      );
      break;
    case 'ArtifactStorageFile':
      mappedRelation.entityClass = 'Artifact Storage File';
      mappedRelation.linkField = <ArtifactStorageFileField source="metaInformation" value={info.entity.artifact} />;
      break;
    case 'Project':
      mappedRelation.linkField = <ProjectLink isClickable name={info.entity.name} id={info.entity.id} />;
      break;
    case 'ProjectWeeklyReport':
      mappedRelation.entityClass = 'Project Weekly Report';
      mappedRelation.linkField = (
        <ProjectWeeklyReportLink
          isClickable
          fromDate={info.entity?.from}
          toDate={info.entity?.to}
          id={info.entityId}
          projectId={info.entity?.project.id}
        />
      );
      break;
    case 'Lead':
      mappedRelation.linkField = (
        <LeadLinkField idSource="" nameValue={info.entity.id} idValue={info.entity.id} isInternal={false} />
      );
      break;
    default:
      mappedRelation.linkField = <></>;
      break;
  }

  return mappedRelation;
};

const ArtifactRelationField = ({ source }: ArtifactRelationFieldProps) => {
  const { getValue } = useFieldHelper<any>({ source });
  const relations = getValue();

  return (
    <div className="storage-file-info-table">
      <List
        getDataMethod={async () => {
          return Promise.resolve({ result: relations.map(mapArtifactRelations) });
        }}
      >
        <ListPageLayout contextName={MESSAGE_CONTEXT}>
          <DataGridLayout showTotal={false} isSmallTable tableWrapperClassName="meta-info-table">
            <TextField source="entityClass" label="Entity related to" width={250} />
            <TextField source="linkField" label="Link" />
          </DataGridLayout>
        </ListPageLayout>
      </List>
    </div>
  );
};

export default ArtifactRelationField;
