import React from 'react';
import { DateField, EnumField } from 'shared/uibuilder/field';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import List from 'shared/uibuilder/list/List';
import useRecruitingReportService, { REPORT_STATUSES } from '../useReportService';
import RequestReportGenerationButton from './button/RequestReportGenerationButton';
import { DESC } from 'shared/uibuilder/list/baseListHelper';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import ReportLinkField from './field/ReportLinkField';

const ReportsListPage = () => {
  const { search } = useRecruitingReportService();
  return (
    <List getDataMethod={search} defaultSortField="generatedAt" defaultSortOrder={DESC}>
      <ListPageLayout
        header={<ListHeader label="Recruiting Reports" icon="fa-user" />}
        actions={[<RequestReportGenerationButton />]}
      >
        <DataGridLayout rowKeySource="alias">
          <ReportLinkField label="Report type" width={150} />
          <EnumField options={REPORT_STATUSES} source="status" />
          <DateField source="periodFrom" />
          <DateField source="periodTo" />
          <DateField source="generatedAt" label="Date of report generation" dateFormat={DATE_FORMAT.FULL} />
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default ReportsListPage;
