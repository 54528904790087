import { TaskDeadlineStyle } from '../../constants';
import { ColumnState, TaskStyles } from '../../types';

export const getTaskStatusByDuration = (
  durationTime: number,
  deadlines?: { normal: number; warning: number },
): TaskStyles => {
  if (!deadlines) return TaskDeadlineStyle.Normal;

  const { normal, warning } = deadlines;

  if (durationTime >= warning) return TaskDeadlineStyle.Expired;
  if (durationTime >= normal) return TaskDeadlineStyle.Warning;

  return TaskDeadlineStyle.Normal;
};

export const formatTime = (milliseconds: number) => {
  const minutes = Math.floor(milliseconds / 60000);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  const remainingMinutes = minutes % 60;
  const remainingHours = hours % 24;

  return [
    days > 0 ? `${days}d` : '',
    remainingHours > 0 || days > 0 ? `${remainingHours}h` : '',
    `${remainingMinutes}m`,
  ]
    .filter(Boolean)
    .join(' ');
};

export const countItemsWithStatus = (data: any[], stage: string): number => {
  return data.reduce((total, item) => {
    const countInItem = item?.items?.filter((position: any) => position.stage === stage).length;
    return total + countInItem;
  }, 0);
};

export const getBorderColor = (columnState: ColumnState): string => {
  const borderStyle = '2px dashed';
  if (columnState === ColumnState.VALID_DROP) {
    return `${borderStyle} var(--mui-palette-primary-main)`;
  } else if (columnState === ColumnState.INVALID_DROP) {
    return `${borderStyle} #f44336`;
  }

  return '2px solid transparent';
};

export const getShortName = (fullName: string) => {
  if (!fullName) {
    return '';
  }

  const parts = fullName.trim().split(' ');

  if (parts.length === 2) {
    const [firstName, lastName] = parts;
    return `${firstName.charAt(0)}.${lastName}`;
  }

  return fullName;
};
