import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import SwiperContainer from 'shared/Swiper/SwiperContainer';
import { WorkingCondition } from '../../../types';
import Box from 'uibuilder/Box';
import { EnumField, ListField } from 'shared/uibuilder/field';
import { EMPLOYEE_CONTRACT_ASSIGMENT_TYPES } from 'erp/employee/contracts/shared/input/EmployeeContractAssignmentTypeRadios';
import { EMPLOYEE_CONTRACT_TYPES, RELOCATION_OPTION } from '../../../constants';
import OfficeAndAccommodationIcons from '../../shared/OfficeAndAccommodationIcons';
import TextField from 'shared/uibuilder/field/TextField';

const WorkingConditionsSwiper = () => {
  const { data } = useShowContext();
  const { workingConditions } = data.getData();

  return (
    <SwiperContainer>
      {workingConditions.map((workingCondition: WorkingCondition & { id: string }) => {
        const [office, accommodation] = workingCondition.hiringLocation.split('_', 2);

        return (
          <Box sx={{ height: '195px' }}>
            <Box
              sx={{
                mb: '20px',
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  flexBasis: '33%',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                <OfficeAndAccommodationIcons registrations={[office]} accommodations={[accommodation]} />
              </Box>

              <Box
                sx={{
                  flexBasis: '33%',
                }}
              >
                <TextField label="Compensation" value={workingCondition.compensationValue} />
              </Box>

              <EnumField
                options={RELOCATION_OPTION}
                value={workingCondition.isRelocationAvailable as any}
                label="Relocation"
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  flexBasis: '33%',
                }}
              >
                <ListField value={workingCondition.employmentTypes as any} label="Employment Type">
                  <EnumField options={EMPLOYEE_CONTRACT_ASSIGMENT_TYPES} />
                </ListField>
              </Box>

              <ListField value={workingCondition.contractTypes as any} label="Contract Type">
                <EnumField options={EMPLOYEE_CONTRACT_TYPES} />
              </ListField>
            </Box>
          </Box>
        );
      })}
    </SwiperContainer>
  );
};

export default WorkingConditionsSwiper;
