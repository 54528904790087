import React from 'react';
import Box from 'uibuilder/Box';

interface VacancyGroupTabContentProps {
  children?: React.ReactNode;
  index: number;
  currentTab: number;
}

const VacancyGroupTabContent: React.FC<VacancyGroupTabContentProps> = ({ children, index, currentTab }) => (
  <Box
    role="tabpanel"
    hidden={currentTab !== index}
    id={`tabpanel-${index}`}
    sx={{ position: 'relative', width: '100%', minHeight: '200px' }}
  >
    {currentTab === index && <>{children}</>}
  </Box>
);

export default VacancyGroupTabContent;
