/* istanbul ignore file */
import React from 'react';
import List from 'shared/uibuilder/list/List';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import useContractsService from 'erp/contracts/shared/contractsService';
import { DESC } from 'shared/uibuilder/list/baseListHelper';
import Filters from 'shared/uibuilder/list/filter/Filters';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import DateRangeFilter from 'shared/uibuilder/list/filter/DateRangeFilter';
import { BadgeField, DateField, TextField } from 'shared/uibuilder/field';
import EmployeeContractWillExpireSoonBadge from 'erp/contracts/shared/RelatedContractsList/badge/EmployeeContractWillExpireSoonBadge';
import ActiveBadge from 'shared/uibuilder/badge/ActiveBadge';
import ExpiredBadge from 'shared/uibuilder/badge/ExpiredBadge';
import ViewContractButton from 'erp/contracts/List/button/ViewContractButton';
import { EmployeeFilter, EmployeeLinkField } from 'erp/employee';
import OfficeDropdownFilter from 'erp/contracts/List/filter/OfficeDropdownFilter';
import ContractTypeFilter from 'erp/contracts/List/filter/ContractTypeFilter';
import EmployeeOfficeField from 'erp/employee/shared/field/EmployeeOfficeField';
import ContractCategoryField from 'erp/contracts/shared/field/ContractCategoryField';
import useFeatureToggle, { Features } from 'featuretoggle';
import ContractDurationField from 'erp/contracts/shared/field/ContractDurationField';
import ContractEndDateBadgeField from 'erp/contracts/shared/field/ContractEndDateBadgeField';

const ContractsList = () => {
  const { search, isContractActive, isContractExpired, isContractWillExpireSoon, getFiltersValidationSchema } =
    useContractsService();
  const twoContractEnabled = useFeatureToggle().isFeatureEnabled(Features.TWO_CONTRACTS);

  const filters = (
    <Filters getValidationSchema={getFiltersValidationSchema}>
      <FiltersLayout>
        <EmployeeFilter source="employee.alias" label="Employee"  />
        <DateRangeFilter canBeInFuture source="dateOfSignature" label="Date of Signature" />
        <DateRangeFilter canBeInFuture source="startDate" label="Contract Start Date" />
        <DateRangeFilter canBeInFuture source="endDate" label="Contract End Date" />
        <OfficeDropdownFilter source="office.id" label="Office" />
        <ContractTypeFilter source="type" label="Contract Type" />
      </FiltersLayout>
    </Filters>
  );

  return (
    <List getDataMethod={search} defaultSortOrder={DESC} defaultSortField="dateOfSignature">
      <ListPageLayout filter={filters} header={<ListHeader label="Contracts" icon="icon-docs" />}>
        <DataGridLayout buttons={[<ViewContractButton source="id" outline permissionToCheck={null} />]}>
          <EmployeeLinkField source="employeeId" nameSource="employeeId" label="Employee ID" isSortable width={105} />
          <TextField
            source="employeeName"
            label="Employee Name"
            isSortable
            sortSource="employee.nameEn.lastName"
            width={130}
          />
          <TextField source="employeePositionName" label="Employee Position" isSortable width={140} />
          <TextField source="number" label="Contract Number" isSortable width={135} />
          <DateField source="dateOfSignature" label="Date of Signature" isSortable width={135} />
          <DateField source="startDate" label="Contract Start Date" isSortable width={145} />
          <ContractDurationField dateFromSource="startDate" dateToSource="endDate" label="Duration" width={135} />
          <BadgeField
            badges={[
              <EmployeeContractWillExpireSoonBadge
                id="expireSoon"
                isVisible={({ endDate }) => isContractWillExpireSoon(endDate)}
              />,

              <ActiveBadge
                id="activeContract"
                isVisible={({ startDate, endDate }) => isContractActive(startDate, endDate)}
              />,
              <ExpiredBadge id="expiredContract" isVisible={({ endDate }) => isContractExpired(endDate)} />,
            ]}
            isSortable
            source="endDate"
            label="Contract End Date"
            width={175}
          >
            <ContractEndDateBadgeField />
          </BadgeField>
          <EmployeeOfficeField source="officeId" label="Office" width={90} />
          {twoContractEnabled ? <ContractCategoryField source="secondary" label="Category" width={105} /> : []}
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default ContractsList;
