import React from 'react';
import DateField from 'shared/uibuilder/field/DateField';
import { POSITION_STATUS, RECRUITING_VACANCY_APPROVE_VACANCY, VACANCY_STATUS } from '../../../constants';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import ClosePositionButton from './closePosition/ClosePositionButton';
import useAuthorization from 'shared/authorization/authorizationService';
import { RECRUITING_CONTEXT } from 'erp/recruitment/RecruitingContext';

const VacancyCloseDateListField = ({ vacancyStatus, setGeneralContextData }: any) => {
  const { data } = useShowContext();
  const rowData = data.getData();
  const { isGranted } = useAuthorization();

  return (
    <>
      {rowData.status === POSITION_STATUS.OPEN &&
      vacancyStatus === VACANCY_STATUS.OPEN &&
      isGranted(RECRUITING_VACANCY_APPROVE_VACANCY, RECRUITING_CONTEXT) ? (
        <ClosePositionButton setGeneralContextData={setGeneralContextData} />
      ) : (
        <DateField source="closeDate" label="Close Date" />
      )}
    </>
  );
};

export default VacancyCloseDateListField;
