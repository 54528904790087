import React from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import FormRow from 'shared/layout/form/FormRow';
import { POSITION_STATUS_OPTIONS, POSITION_TYPE_OPTIONS } from 'erp/recruitment/newVacancy/constants';
import { EnumField } from 'shared/uibuilder/field';
import { DateInput } from 'shared/uibuilder/form/input';

interface ApproveVacancyPositionsInputsProps {
  isLast: boolean;
  source: string;
  value: any;
}

const ApproveVacancyPositionsInputs = ({ isLast, ...props }: ApproveVacancyPositionsInputsProps) => {
  const { getSource } = useInputHelper(props);
  const source = getSource();

  return (
    <FormRow
      sx={{
        borderBottom: !isLast ? '1px solid #dcdbdb' : '',
      }}
      colXl={4}
    >
      <EnumField source={`${source}.type`} label="Type" options={POSITION_TYPE_OPTIONS} width="30%" />
      <EnumField source={`${source}.status`} label="Status" options={POSITION_STATUS_OPTIONS} width="30%" />
      <DateInput source={`${source}.forecastDate`} label="Forecast Date" isRequired />
    </FormRow>
  );
};

export default ApproveVacancyPositionsInputs;
