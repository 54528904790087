import React from 'react';
import Box from 'uibuilder/Box';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import ControlledList from 'shared/uibuilder/list/ControlledList';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { EnumField, HtmlField } from 'shared/uibuilder/field';
import { ENGLISH_LEVEL_SHORT, POSITION_STATUS_OPTIONS, POSITION_TYPE_OPTIONS } from '../../constants';
import DateField from 'shared/uibuilder/field/DateField';
import { HIRING_MODE } from '../../../hiring/useHiringService';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import EmployeeLinkFieldWithNameLoading from 'erp/employee/shared/field/EmployeeLinkFieldWithNameLoading';
import TextField from 'shared/uibuilder/field/TextField';
import VacancyProjectsSwiper from './shared/VacancyProjectsSwiper';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import AttachmentsListField from 'artifact/shared/field/AttachmentsListField';
import CommaListField from 'shared/uibuilder/field/CommaListFIeld';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import WorkingConditionsSwiper from './shared/WorkingConditionsSwiper';
import Typography from 'uibuilder/Typography';
import Data from 'shared/uibuilder/Data';
import LabelStatus from '../shared/LabelStatus';
import LabelAllHired from '../shared/LabelAllHired';
import LabelCandidateRejected from '../shared/LabelCandidateRejected';
import VacancyCloseDateListField from './shared/VacancyCloseDateListField';
import ApproveVacancyButton from 'erp/recruitment/newVacancy/shared/button/ApproveVacancyButton';
import CloseVacancyButton from 'erp/recruitment/newVacancy/shared/button/CloseVacancyButton';
import RequestVacancyCancellationButton from 'erp/recruitment/newVacancy/shared/button/RequestVacancyCancellationButton';
import ResolveVacancyCancellationRequestButton from 'erp/recruitment/newVacancy/shared/button/ResolveVacancyCancellationRequestButton';
import ShowDate from 'shared/uibuilder/ShowDate';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import { EntityHeaderMetaFieldLayout } from 'shared/layout/EntityHeader';
import UiThemeOverrider from 'shared/uibuilder/UiThemeOverrider';

import './ViewVacancyContent.scss';

const ViewVacancyContent = ({ setGeneralContextData }: any) => {
  const { data } = useShowContext();
  const { positions, status } = data.getData();

  const createdAt = data.getValueBySource('createdAt');

  return (
    <>
      <Box
        sx={{
          display: 'flex',

          gap: '20px',
          alignItems: 'center',

          '& > section': {
            flex: '0 0 49%',
            maxWidth: '49%',
            height: '280px',
            position: 'relative',
          },

          '@media (max-width: 1440px)': {
            flexWrap: 'wrap',
            gap: '0',

            '& > section': {
              flex: '0 0 100%',
              maxWidth: '100%',
              height: 'auto',
            },
          },
        }}
      >
        <ShowSection title="Positions">
          <ControlledList data={{ items: positions, totalElements: positions.length, totalPages: 1 }}>
            <DataGridLayout
              sxPaginationWrapper={{ paddingTop: '15px' }}
              isSmallTable
              tableWrapperClassName="vacancy-positions-table-wrapper"
            >
              <EnumField source="type" label="Type" options={POSITION_TYPE_OPTIONS} width="15%" />
              <EnumField source="status" label="Status" options={POSITION_STATUS_OPTIONS} width="15%" />
              <DateField source="forecastDate" label="Forecast Date" />

              <VacancyCloseDateListField
                setGeneralContextData={setGeneralContextData}
                vacancyStatus={status}
                label="Close Date"
              />

              <DateField source="startWorkingDate" label="Start Working Date" />
              <LabelCandidateRejected />
            </DataGridLayout>
          </ControlledList>
        </ShowSection>

        <ShowSection
          actions={[
            <ApproveVacancyButton statusSource="status" setGeneralContextData={setGeneralContextData} />,
            <CloseVacancyButton setGeneralContextData={setGeneralContextData} />,
            <RequestVacancyCancellationButton statusSource="status" setGeneralContextData={setGeneralContextData} />,
            <ResolveVacancyCancellationRequestButton
              statusSource="status"
              setGeneralContextData={setGeneralContextData}
            />,
          ]}
          justifyContent="space-between"
          title={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Typography variant="h4">
                {data.competencyRange} {data.specializationName}
              </Typography>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                flexWrap: 'wrap',
                marginRight: '60px'
              }}>
                <LabelStatus status={data.status} />
                <LabelAllHired status={data.status} areAllHired={data.areAllHired} />
              </Box>

            </Box>
          }
        >
          <SectionRow spacing={{}}>
            <EnumField source="hiringMode" label="Hiring Mode" options={HIRING_MODE} />
            <DateField
              isVisible={(showData: Data) => !showData.getValueBySource('isAsap')}
              source="targetHiringDate"
              label="Target Hiring"
            />
            <TextField
              isVisible={(showData: Data) => showData.getValueBySource('isAsap')}
              value="Asap"
              label="Target Hiring"
            />
            <DateField source="expirationDate" label="Expiration" />
          </SectionRow>

          <SectionRow spacing={{}}>
            <EnumField source="mandatoryRequirements.englishLevel" label="English" options={ENGLISH_LEVEL_SHORT} />
            <EmployeeLinkFieldWithNameLoading label="DRI" source="responsiblePerson" />
            <EmployeeLinkFieldWithNameLoading label="Coordinator" source="coordinator" />
          </SectionRow>

          <UiThemeOverrider overriddenLayouts={{ BaseFieldLayout: EntityHeaderMetaFieldLayout }}>
            <Box
              sx={{
                position: 'absolute',
                textAlign: 'right',
                color: '#817379',
                right: '20px',
                bottom: '10px',
                padding: '0',
                fontSize: '10px',
              }}
            >
              <TextField
                label="Created"
                value={
                  createdAt && (
                    <>
                      <ShowDate dateUTC={createdAt} format={DATE_FORMAT.FULL} />
                    </>
                  )
                }
              />
            </Box>
          </UiThemeOverrider>
        </ShowSection>
      </Box>

      <Box
        sx={{
          display: 'flex',

          gap: '20px',
          alignItems: 'center',

          '& > section': {
            flex: '0 0 49%',
            maxWidth: '49%',
            height: '270px',
          },

          '@media (max-width: 1440px)': {
            flexWrap: 'wrap',
            gap: '0',

            '& > section': {
              flex: '0 0 100%',
              maxWidth: '100%',
              height: 'auto',
            },
          },
        }}
      >
        <ShowSection title="Mandatory Requirements">
          <Box
            sx={{
              maxHeight: '120px',
              overflow: 'auto',
              mb: '10px',
            }}
          >
            <HtmlField source="mandatoryRequirements.description" label="" />
          </Box>

          <MediumFormRow
            sx={{
              '> .MuiGrid-item': {
                pl: 0,
              },
            }}
          >
            <CommaListField label="Skills" source="mandatoryRequirements.skills" />
            <TextField label="Min Experience (years)" source="mandatoryRequirements.requiredExperienceInYears" />
          </MediumFormRow>
        </ShowSection>

        <ShowSection title="Working Conditions">
          <WorkingConditionsSwiper />
        </ShowSection>
      </Box>

      <Box
        sx={{
          display: 'flex',

          flexWrap: 'wrap',
          gap: '0 20px',
          alignItems: 'center',

          '& > section': {
            flex: '0 0 49%',
            maxWidth: '49%',
            height: '365px',
          },

          '@media (max-width: 1440px)': {
            flexWrap: 'wrap',
            gap: '0',

            '& > section': {
              flex: '0 0 100%',
              maxWidth: '100%',
              height: 'auto',
            },
          },
        }}
      >
        <ShowSection title="Responsibilities">
          <Box
            sx={{
              maxHeight: '265px',
              overflow: 'auto',
            }}
          >
            <HtmlField source="responsibilities" label="" />
          </Box>
        </ShowSection>

        <ShowSection isVisible={showData => showData.getValueBySource('projects')?.length > 0}>
          <VacancyProjectsSwiper />
        </ShowSection>

        <ShowSection
          title="Optional Requirements"
          isVisible={showData => showData.getValueBySource('optionalRequirements')}
        >
          <Box
            sx={{
              maxHeight: '210px',
              overflow: 'auto',
              mb: '10px',
            }}
          >
            <HtmlField source="optionalRequirements.description" label="" />
          </Box>

          <BigSectionRow spacing={{}}>
            <CommaListField label="Skills" source="optionalRequirements.skills" />
          </BigSectionRow>
        </ShowSection>

        <ShowSection title="Notes" isVisible={showData => showData.getValueBySource('notes')}>
          <Box
            sx={{
              maxHeight: '210px',
              overflow: 'auto',
              mb: '10px',
            }}
          >
            <HtmlField source="notes.description" label="" />
          </Box>

          <AttachmentsListField source="notes.attachmentIds" label="Attachments" />
        </ShowSection>
      </Box>
    </>
  );
};

export default ViewVacancyContent;
