/* istanbul ignore file */
import React from 'react';
import useProjectService, { PROJECT_COMPLEXITY, PROJECT_IMPORTANCE } from 'erp/project/shared/projectService';
import ProjectForm from 'erp/project/createupdate/ProjectForm';
import { CreateForm } from 'shared/uibuilder/form';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import CreateProjectBreadcrumbs from 'erp/project/createupdate/Create/Breadcrumbs';
import { useProjectUrl } from 'erp/project/ProjectRouter';

const CreateProject = () => {
  const { createProject, getValidationSchema } = useProjectService();
  const { getListUrl } = useProjectUrl();

  return (
    <CreateForm
      submitFormFunc={createProject}
      initialData={{
        projectComplexity: PROJECT_COMPLEXITY.STANDARD,
        projectImportance: PROJECT_IMPORTANCE.STANDARD,
      }}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Project has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout title="Create: Project" breadcrumbs={<CreateProjectBreadcrumbs />}>
        <ProjectForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateProject;
