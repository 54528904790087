import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const People = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9056 7.12205C14.599 7.12205 14.3315 6.89621 14.2873 6.58371C14.2398 6.24121 14.4773 5.92538 14.8198 5.87788C15.6865 5.75621 16.3406 5.00455 16.3423 4.12788C16.3423 3.25871 15.719 2.52371 14.8598 2.38288C14.5198 2.32705 14.289 2.00538 14.3448 1.66455C14.4015 1.32371 14.7206 1.09538 15.0631 1.14955C16.5281 1.39038 17.5923 2.64371 17.5923 4.12955C17.589 5.62538 16.4715 6.90871 14.9923 7.11621C14.9631 7.12038 14.934 7.12205 14.9056 7.12205Z"
          fill="currentColor"
        />
        <mask id="mask0_2305_305" maskUnits="userSpaceOnUse" x="15" y="8" width="4" height="5">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.8154 8.66797H18.9771V12.4426H15.8154V8.66797Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_2305_305)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.3848 12.4426C17.1332 12.4426 16.8957 12.2893 16.8007 12.0401C16.6773 11.7176 16.8398 11.3559 17.1623 11.2334C17.7273 11.0184 17.7273 10.7809 17.7273 10.6668C17.7273 10.3018 17.2632 10.0468 16.3482 9.91011C16.0073 9.85844 15.7715 9.54094 15.8223 9.19844C15.8732 8.85678 16.1898 8.62928 16.534 8.67344C18.5532 8.97594 18.9773 9.92428 18.9773 10.6668C18.9773 11.1201 18.799 11.9476 17.6073 12.4018C17.534 12.4293 17.459 12.4426 17.3848 12.4426Z"
            fill="currentColor"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.90578 10.2617C7.92577 10.2617 5.56494 10.5051 5.56494 11.6634C5.56494 12.8309 7.92577 13.0759 9.90578 13.0759C11.8858 13.0759 14.2458 12.8334 14.2458 11.6776C14.2458 10.5076 11.8858 10.2617 9.90578 10.2617ZM9.90578 14.3259C8.52327 14.3259 4.31494 14.3259 4.31494 11.6634C4.31494 9.01172 8.52327 9.01172 9.90578 9.01172C11.2883 9.01172 15.4958 9.01172 15.4958 11.6776C15.4958 14.3259 11.4349 14.3259 9.90578 14.3259Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.90582 1.58301C8.49582 1.58301 7.34915 2.73051 7.34915 4.14051C7.34665 4.82384 7.60832 5.46301 8.08749 5.94551C8.56665 6.42801 9.20499 6.69551 9.88499 6.69801L9.90582 7.32301V6.69801C11.3158 6.69801 12.4633 5.55134 12.4633 4.14051C12.4633 2.73051 11.3158 1.58301 9.90582 1.58301ZM9.90582 7.94801H9.88332C8.86665 7.94467 7.91415 7.54634 7.20082 6.82634C6.48582 6.10717 6.09499 5.15217 6.09915 4.13801C6.09915 2.04134 7.80665 0.333008 9.90582 0.333008C12.0058 0.333008 13.7133 2.04134 13.7133 4.14051C13.7133 6.23967 12.0058 7.94801 9.90582 7.94801Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.90507 7.1222C4.87674 7.1222 4.84757 7.12054 4.81841 7.11637C3.33924 6.90887 2.22257 5.62554 2.21924 4.13137C2.21924 2.64387 3.28341 1.39054 4.74841 1.1497C5.09924 1.0947 5.41007 1.32554 5.46674 1.6647C5.52257 2.00554 5.29174 2.3272 4.95174 2.38304C4.09257 2.52387 3.46924 3.25887 3.46924 4.1297C3.47091 5.0047 4.12507 5.7572 4.99091 5.87804C5.33341 5.92554 5.5709 6.24137 5.52341 6.58387C5.47924 6.89637 5.21174 7.1222 4.90507 7.1222Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.42683 12.4424C2.35266 12.4424 2.27766 12.4291 2.20433 12.4016C1.01183 11.9466 0.833496 11.1191 0.833496 10.6666C0.833496 9.92493 1.25766 8.97577 3.27766 8.67327C3.62183 8.62993 3.93683 8.8566 3.9885 9.19827C4.03933 9.54077 3.8035 9.85827 3.46266 9.90993C2.54766 10.0466 2.0835 10.3016 2.0835 10.6666C2.0835 10.7808 2.0835 11.0174 2.64933 11.2333C2.97183 11.3558 3.13433 11.7174 3.011 12.0399C2.916 12.2891 2.6785 12.4424 2.42683 12.4424Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default People;
