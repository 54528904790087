import React, { useEffect } from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import ApproveVacancyPositionsInputs from 'erp/recruitment/newVacancy/shared/inputs/ApproveVacancyPositionsInputs';

const ApproveVacancyPositionsInputsListWrapper = ({ isLast, ...props }: any) => {
  const { getSource, getRawOnChangeCallback, getValue } = useInputHelper(props);
  const positions: any = getValue() || {};
  const rawOnChangeCallback = getRawOnChangeCallback();
  const source = getSource();

  useEffect(() => {
    if (!getValue()) {
      rawOnChangeCallback!({
        [source]: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ApproveVacancyPositionsInputs isLast={isLast} value={positions} source={source} />;
};

export default ApproveVacancyPositionsInputsListWrapper;
