/* istanbul ignore file */
import React, { useContext } from 'react';
import { Filter } from './filter/FilterContext';
import { ResourceData } from 'shared/crud/baseCrudService';

export type SortOrder = 'ASC' | 'DESC' | string;

export type SortParam = {
  field?: string;
  order?: SortOrder;
};

export type LoadingParams = {
  pageSize: number;
  pageNumber: number;
  filter?: Filter;
  sort?: SortParam;
};

export interface ListParams {
  sort?: SortParam;
  filter?: Filter;
  page?: number;
}

export interface SearchParams extends ListParams {
  search?: string;
}

export interface DefaultListParams {
  initialParams?: SearchParams;
  defaultSortOrder?: SortOrder;
  defaultSortField?: string;
  defaultFilter?: Filter;
  page?: number;
}

export type ListItem = any;

interface ListData {
  items?: ListItem[];
  totalPages?: number;
  totalElements?: number;
  lastUpdate?: string | null;
}

export interface ListContextData {
  loading?: boolean;
  data?: ListData;
  setData?: (data: any) => void;
  setLoading?: (isLoading: boolean) => void;
  deleteFromList?: (id: StringOrNumber) => void;
  hasMoreData?: () => boolean;
  hasLessData?: () => boolean;
  loadNextPage?: () => void;
  loadPreviousPage?: () => void;
  loadMoreData?: (page: number) => void;
  sort?: SortParam;
  sortBy?: (key?: string, order?: SortOrder) => void;
  searchBy?: (search: string) => void;
  search?: string;
  filterBy?: (filters: Filter) => void;
  filter?: Filter;
  page?: number;
  goToPage?: (page: number) => void;
  createTimelineItemMethod?: (type: string, request: ResourceData) => void;
  setAddForm?: (form: Nullable<React.ReactElement>) => void;
  addToList?: (entity: ListItem) => void;
  updateInList?: ((entity: ListItem) => void) | ((id: Nullable<StringOrNumber>, entity?: ListItem) => void);
  reloadData?: () => void;
  uncontrolledFilters?: string[];
}

const defaultContext = {};

const ListContext = React.createContext<ListContextData>(defaultContext);

export const ListProvider = ListContext.Provider;

export const useListContext = () => useContext(ListContext);

export default ListContext;
