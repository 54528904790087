import React from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import FormRow from 'shared/layout/form/FormRow';
import { POSITION_STATUS, POSITION_STATUS_OPTIONS, POSITION_TYPE, POSITION_TYPE_OPTIONS } from '../../../constants';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import Box from 'uibuilder/Box';
import IconButton from 'uibuilder/button/IconButton';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import HistoryIcon from '@mui/icons-material/History';

type PositionsInputsProps = {
  source: string;
  value: any;
  isLast?: boolean;
};

const PositionsInputs = ({ isLast, ...props }: PositionsInputsProps) => {
  const { data } = useShowContext();
  const { getSource, getValue, getRawOnChangeCallback } = useInputHelper(props);
  const source = getSource();
  const values = getValue();
  const onChangeCallback = getRawOnChangeCallback() || (() => {});
  const prevData = data.getData();
  const prevPosition = prevData.positions.find((item: any) => values.id === item.id);

  const cancelButtonAction = () => {
    onChangeCallback({
      [source]: {
        ...prevPosition,
        status: POSITION_STATUS.CANCELLED,
        isCancelByButton: true,
      },
    });
  };

  const resetButtonAction = () => {
    onChangeCallback({
      [source]: {
        ...prevPosition,
      },
    });
  };

  const reopenButtonAction = () => {
    onChangeCallback({
      [source]: {
        ...prevPosition,
        status: POSITION_STATUS.OPEN,
        isReopenByButton: true,
        startWorkingDate: null,
        closeDate: null,
      },
    });
  };

  return (
    <Box
      sx={{
        borderBottom: !isLast ? '1px solid #dcdbdb' : '',
        display: 'flex',
        width: '70%',
        alignItems: 'center',
      }}
    >
      <FormRow colXl={4}>
        <EnumDropdown
          disabled={
            (values.id && prevPosition.type === POSITION_TYPE.REQUIRED) || values.status !== POSITION_STATUS.OPEN
          }
          options={POSITION_TYPE_OPTIONS}
          source={`${source}.type`}
          label="Type"
        />

        <EnumDropdown disabled options={POSITION_STATUS_OPTIONS} source={`${source}.status`} label="Status" />

        <DateInput
          // isVisible={POSITION_STATUS.OPEN === positionStatus}
          disabled={values.status !== POSITION_STATUS.OPEN}
          source={`${source}.forecastDate`}
          label="Forecast date"
        />
      </FormRow>

      <Box
        sx={{
          minWidth: '40px',
        }}
      >
        {values.id && values.status === POSITION_STATUS.OPEN && !values.isReopenByButton ? (
          <IconButton onClick={cancelButtonAction}>
            <DoDisturbAltIcon color="error" />
          </IconButton>
        ) : null}

        {values.id && (values.isCancelByButton || values.isReopenByButton) ? (
          <IconButton onClick={resetButtonAction}>
            <RotateLeftIcon color="secondary" />
          </IconButton>
        ) : null}

        {values.id && values.status === POSITION_STATUS.CLOSED ? (
          <IconButton onClick={reopenButtonAction}>
            <HistoryIcon color="primary" />
          </IconButton>
        ) : null}
      </Box>
    </Box>
  );
};

export default PositionsInputs;
