/* istanbul ignore file */
import React, { useEffect } from 'react';
import { CurrencyInput } from 'shared/uibuilder/form/input';
import Button from 'uibuilder/button/Button';
import { SubmitButton } from 'uibuilder/button';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import CurrencyDropdown from 'erp/employee/finance/shared/inputs/CurrencyDropdown';
import FormRow from 'shared/layout/form/FormRow';
import { CURRENCY_TYPES } from 'erp/employee/finance/shared/financeService';
import FormSection from 'shared/layout/form/FormSection';
import compensationFormValidationSchema from './compensationFormValidationSchema';

interface CompensationFormProps {
  handleCancelClick: () => void;
}

const CompensationForm = ({ handleCancelClick }: CompensationFormProps) => {
  const { submitForm, isSubmitEnabled, isLoadingArtifact, setValidationSchemaAction } = useFormContext();

  useEffect(() => {
    if (setValidationSchemaAction) {
      setValidationSchemaAction(compensationFormValidationSchema);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormSection title="Expected Compensation">
        <FormRow>
          <CurrencyInput source="compensationFromValue" label="From" isRequired />
          <CurrencyInput source="compensationToValue" label="To" isRequired />
          <CurrencyDropdown source="compensationCurrency" options={CURRENCY_TYPES} label="Currency" />
        </FormRow>
      </FormSection>

      <div className="btn-area">
        <SubmitButton
          key="submit-modal-btn"
          onClick={submitForm}
          isLoading={!isSubmitEnabled}
          disabled={isLoadingArtifact}
        />
        <Button
          outline
          key="cancel-modal-btn"
          onClick={handleCancelClick}
          disabled={!isSubmitEnabled || isLoadingArtifact}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default CompensationForm;
