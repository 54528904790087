export const RECRUITING_DASHBOARD_READ = 'RECRUITING_DASHBOARD_READ';

export const RECRUITING_VACANCY_GROUP_READ = 'RECRUITING_VACANCY_GROUP_READ';
export const RECRUITING_VACANCY_GROUP_UPDATE = 'RECRUITING_VACANCY_GROUP_UPDATE';
export const RECRUITING_VACANCY_GROUP_CLOSE_VACANCY_GROUP = 'RECRUITING_VACANCY_GROUP_CLOSE_VACANCY_GROUP';

export const RECRUITING_VACANCY_APPROVE_VACANCY = 'RECRUITING_VACANCY_APPROVE_VACANCY';
export const RECRUITING_VACANCY_CLOSE_VACANCY = 'RECRUITING_VACANCY_CLOSE_VACANCY';
export const RECRUITING_VACANCY_UPDATE_VACANCY = 'RECRUITING_VACANCY_UPDATE_VACANCY';
export const RECRUITING_VACANCY_MOVE_TO_GROUP = 'RECRUITING_VACANCY_MOVE_TO_GROUP';
export const RECRUITING_VACANCY_REQUEST_CANCELLATION = 'RECRUITING_VACANCY_REQUEST_VACANCY_CANCELLATION';
export const RECRUITING_VACANCY_APPROVE_DECLINE_CANCELLATION =
  'RECRUITING_VACANCY_APPROVE_DECLINE_VACANCY_CANCELLATION';

export const RECRUITING_VACANCY_PUBLICATION_CREATE = 'RECRUITING_VACANCY_PUBLICATION_CREATE';
export const RECRUITING_VACANCY_PUBLICATION_UPDATE = 'RECRUITING_VACANCY_PUBLICATION_UPDATE';
export const RECRUITING_VACANCY_PUBLICATION_READ = 'RECRUITING_VACANCY_PUBLICATION_READ';

export const RELOCATION = {
  AVAILABLE: 'AVAILABLE',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
};

export const RELOCATION_OPTION = {
  [RELOCATION.AVAILABLE]: 'Available',
  [RELOCATION.NOT_AVAILABLE]: 'Not available',
};

export const LOCATION_OFFICES = {
  BY: 'BY',
  PL: 'PL',
  US: 'US',
};

export const LOCATION_ACCOMMODATIONS = {
  BY: 'BY',
  PL: 'PL',
  EU: 'PL',
  ANYWHERE: 'ANYWHERE',
};

export const LOCATION_FOR_HIRING = {
  BY_BY: 'BY_BY',
  PL_PL: 'PL_PL',
  PL_EU: 'PL_EU',
  PL_ANYWHERE: 'PL_ANYWHERE',
  US_ANYWHERE: 'US_ANYWHERE',
};

export const LOCATION_FOR_HIRING_OPTIONS = {
  [LOCATION_FOR_HIRING.BY_BY]: 'BY - BY',
  [LOCATION_FOR_HIRING.PL_PL]: 'PL - PL',
  [LOCATION_FOR_HIRING.PL_EU]: 'PL - EU',
  [LOCATION_FOR_HIRING.PL_ANYWHERE]: 'PL - ANYWHERE',
  [LOCATION_FOR_HIRING.US_ANYWHERE]: 'US - ANYWHERE',
};

export const EXPIRATION_PERIOD_OPTIONS = {
  '3': '3',
  '4': '4',
  '5': '5',
  '6': '6',
  '7': '7',
  '8': '8',
  '9': '9',
};

export const ENGLISH_LEVEL_SHORT = {
  A1: 'A1',
  A2: 'A2',
  B1: 'B1',
  B2: 'B2',
  C1: 'C1',
  C2: 'C2',
};

export const ENGLISH_LEVEL_SHORT_OPTIONS = {
  [ENGLISH_LEVEL_SHORT.A1]: 'A1',
  [ENGLISH_LEVEL_SHORT.A2]: 'A2',
  [ENGLISH_LEVEL_SHORT.B1]: 'B1',
  [ENGLISH_LEVEL_SHORT.B2]: 'B2',
  [ENGLISH_LEVEL_SHORT.C1]: 'C1',
  [ENGLISH_LEVEL_SHORT.C2]: 'C2',
};

export const EMPLOYEE_CONTRACT_TYPES = {
  EMPLOYMENT_CONTRACT: 'Employment contract',
  B2B_CONTRACT: 'B2B contract',
  CONTRACT_FOR_SERVICES: 'Contract for services',
};

export const COMPETENCY_LEVEL = {
  L1: 'L1',
  L2: 'L2',
  L3: 'L3',
  L4: 'L4',
  L5: 'L5',
  L6: 'L6',
  N_A: 'N/A',
};

export const COMPETENCY_LEVEL_WITHOUT_NA = {
  L1: 'L1',
  L2: 'L2',
  L3: 'L3',
  L4: 'L4',
  L5: 'L5',
  L6: 'L6',
};

export const POSITION_STATUS = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  CANCELLED: 'CANCELLED',
  EXPIRED: 'EXPIRED',
};

export const POSITION_STATUS_OPTIONS = {
  [POSITION_STATUS.OPEN]: 'Open',
  [POSITION_STATUS.CLOSED]: 'Closed',
  [POSITION_STATUS.CANCELLED]: 'Cancelled',
  [POSITION_STATUS.EXPIRED]: 'Expired',
};

export const POSITION_TYPE = {
  REQUIRED: 'REQUIRED',
  ADDITIONAL: 'ADDITIONAL',
};

export const POSITION_TYPE_OPTIONS = {
  [POSITION_TYPE.REQUIRED]: 'Required',
  [POSITION_TYPE.ADDITIONAL]: 'Additional',
};

export const VACANCY_GROUP_STATUS = {
  REQUIRES_REVIEW: 'REQUIRES_REVIEW',
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
};

export const VACANCY_STATUS = {
  REQUIRES_REVIEW: 'REQUIRES_REVIEW',
  OPEN: 'OPEN',
  CANCELLATION_REQUESTED: 'CANCELLATION_REQUESTED',
  CANCELLED: 'CANCELLED',
  CLOSED: 'CLOSED',
  EXPIRED: 'EXPIRED',
};

export const VACANCY_STATUS_OPTIONS = {
  [VACANCY_STATUS.REQUIRES_REVIEW]: 'Request review',
  [VACANCY_STATUS.OPEN]: 'Open',
  [VACANCY_STATUS.CANCELLATION_REQUESTED]: 'Cancellation Request',
  [VACANCY_STATUS.CANCELLED]: 'Cancelled',
  [VACANCY_STATUS.CLOSED]: 'Closed',
  [VACANCY_STATUS.EXPIRED]: 'Expired',
};

export const VACANCY_GROUP_PRIORITY = {
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
};

export const VACANCY_GROUP_PRIORITY_OPTIONS = {
  [VACANCY_GROUP_PRIORITY.HIGH]: 'High',
  [VACANCY_GROUP_PRIORITY.MEDIUM]: 'Medium',
  [VACANCY_GROUP_PRIORITY.LOW]: 'Low',
};

export const WORKING_SCHEDULE = {
  STANDARD: 'Standard',
  NON_STANDARD: 'Non-standard',
};

export enum VacancyStatus {
  OPEN = 'OPEN',
  DRAFT = 'DRAFT',
  CLOSED = 'CLOSED',
}

export const COMPENSATION_CURRENCY_TYPES = {
  USD: 'USD',
  BYN: 'BYN',
  PLN: 'PLN',
};
