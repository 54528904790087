// libraries
import React from 'react';
import { useDateTimeService, useDateService } from 'shared/uibuilder/dateService';

interface ShowDateProps {
  format: string;
  dateUTC?: string;
  timezone?: string;
  isDateTime?: boolean;
  className?: string;
  withCommaAtEnd?: boolean;
}

const ShowDate = ({
  dateUTC,
  timezone,
  format,
  isDateTime = true,
  className,
  withCommaAtEnd = false,
}: ShowDateProps) => {
  const { formatDateWithTimezone } = useDateTimeService(timezone);
  const { formatDate } = useDateService();

  if (!dateUTC) return null;

  return (
    <span className={className} style={{ ...(withCommaAtEnd && { marginRight: '3px' }) }}>
      {isDateTime ? formatDateWithTimezone(dateUTC, format) : formatDate(dateUTC, format)}
      {withCommaAtEnd ? ',' : ''}
    </span>
  );
};

export default ShowDate;
