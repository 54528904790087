/* istanbul ignore file */
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import SingleVacancyBreadcrumbs from 'erp/recruitment/newVacancy/shared/SingleVacancyBreadcrumbs';
import { useVacancyGroupUrl } from 'erp/recruitment/newVacancy/routing';
import { TabProps } from '../vacancyGroup/ViewVacancyGroup';

interface VacancyGroupPublicationBreadcrumbsProps {
  children?: any;
  entity?: string;
  tabs?: TabProps[];
  setCurrentTab?: Dispatch<SetStateAction<number>>;
}

const VacancyGroupPublicationBreadcrumbs = ({
  children,
  entity = 'Publications',
  tabs,
  setCurrentTab = () => {},
}: VacancyGroupPublicationBreadcrumbsProps) => {
  const { getSingleEntityUrl } = useVacancyGroupUrl();

  const options = useMemo(
    () =>
      tabs?.map(({ label, index, onChangeTabCallback, route }) => ({
        label,
        value: index,
        onChangeCallback: onChangeTabCallback,
        route,
      })),
    [tabs],
  );

  return (
    <SingleVacancyBreadcrumbs onClickCallback={() => setCurrentTab(0)}>
      <ListBreadcrumbItem entity={entity} link={`${getSingleEntityUrl()}/publications`} options={options} />
      {children}
    </SingleVacancyBreadcrumbs>
  );
};

export default VacancyGroupPublicationBreadcrumbs;
