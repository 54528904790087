/* istanbul ignore file */
import React, { useState } from 'react';
import useAuthorization from 'shared/authorization/authorizationService';
import DeleteButton from 'uibuilder/button/DeleteButton';
import { RECRUITING_VACANCY_REQUEST_CANCELLATION, VACANCY_STATUS } from 'erp/recruitment/newVacancy/constants';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { TextArea } from 'shared/uibuilder/form/input';
import useVacancyGroupService from 'erp/recruitment/newVacancy/useVacancyGroupService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { RECRUITING_CONTEXT } from '../../../RecruitingContext';

const RequestVacancyCancellationButton = ({
  statusSource,
  setGeneralContextData,
}: {
  statusSource: string;
  setGeneralContextData: () => {};
}) => {
  const { requestVacancyCancellation } = useVacancyGroupService();
  const { isGranted } = useAuthorization();

  const { data } = useShowContext();
  const { id } = data.getData();

  const [cancellationReason, setCancellationReason] = useState<string>();
  const [cancellationReasonErrors, setCancellationReasonErrors] = useState<string[]>();

  const { getValue } = useFieldHelper({ source: statusSource });
  const status = getValue();

  if (status !== VACANCY_STATUS.OPEN) {
    return null;
  }

  const onCancellationReasonChange = (values: FormFieldsData) => {
    setCancellationReason(values.cancellationReason);

    if (!values.cancellationReason) {
      setCancellationReasonErrors(['Reason should be provided to request cancellation']);
    } else {
      setCancellationReasonErrors([]);
    }
  };

  return isGranted(RECRUITING_VACANCY_REQUEST_CANCELLATION, RECRUITING_CONTEXT) ? (
    <DeleteButton
      displayMessage="Do you really want to request cancellation for this vacancy?"
      modalChildren={
        <TextArea
          source="cancellationReason"
          label="Cancellation Reason"
          value={cancellationReason}
          onChangeCallback={onCancellationReasonChange}
          errorMessages={cancellationReasonErrors}
          isRequired
        />
      }
      deleteMethod={() => requestVacancyCancellation(id!, cancellationReason)}
      afterDelete={{
        successMessage: 'Cancellation for vacancy has been successfully requested.',
        errorMessage: "Can't request vacancy cancellation.",
        execute: () => {
          if (setGeneralContextData) {
            setGeneralContextData();
          }
        },
      }}
      permissionToCheck={null}
      buttonText="Request cancellation"
      confirmBtnProps={{ disabled: !cancellationReason }}
      color="error"
      variant="outlined"
    />
  ) : null;
};

export default RequestVacancyCancellationButton;
