import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import { useCandidateUrl } from 'erp/candidate/CandidateRouter';
import { useLeadUrl } from 'crm/lead/LeadRouter';
import { useOpportunityUrl } from 'crm/opportunity/OpportunityRouter';
import { useAccountUrl } from 'crm/account/index';
import { useContactUrl } from 'crm/contact/ContactRouter';
import { useEmployeeOfferUrl } from 'erp/employee/offers/EmployeeOfferRouter';
import { useCandidateOfferUrl } from 'erp/candidate/offercompensation/CandidateOfferRouter';
import { ResourceId } from 'shared/crud/baseCrudService';
import { useProjectUrl } from 'erp/project/ProjectRouter';
import { useServiceUrl } from 'erp/service/ServiceRouter';
import { useDateTimeService } from 'shared/uibuilder/dateService';
import useEmployeeNamesService from 'erp/employee/shared/employeeNamesService';
import { useFinanceApi } from 'api';

const useCustomHtmlTagHelper = () => {
  const { getSingleEntityUrl: getEmployeeUrl } = useEmployeeUrl();
  const { getSingleEntityUrl: getCandidateUrl } = useCandidateUrl();
  const { getSingleEntityUrl: getLeadUrl } = useLeadUrl();
  const { getTimelineUrl: getOpportunityUrl } = useOpportunityUrl();
  const { getSingleEntityUrl: getAccountUrl } = useAccountUrl();
  const { getSingleEntityUrl: getContactUrl } = useContactUrl();
  const { getSingleEntityUrl: getEmployeeOfferUrl } = useEmployeeOfferUrl();
  const { getSingleEntityUrl: getCandidateOfferUrl } = useCandidateOfferUrl();
  const { getSingleEntityUrl: getProjectUrl } = useProjectUrl();
  const { getSingleEntityUrl: getCompanyServiceUrl } = useServiceUrl();
  const { sendPostRequest } = useFinanceApi();

  const { formatDateWithTimezone, getUserTimezone } = useDateTimeService();
  const { getEmployeeNameDataById } = useEmployeeNamesService();

  const replaceEmployeeTag = async (description: string) => {
    const employeeTagRegexp = /<employee\s+id=['"]([\S\d]+?)['"]\s*(\/>|>(.*?)<\/employee>)/gm;

    const matches = [...description.matchAll(employeeTagRegexp)];

    const replacements = await Promise.all(
      matches.map(async match => {
        const [fullTag, id] = match;
        const employeeUrl = getEmployeeUrl(id);
        try {
          const employeeData = await getEmployeeNameDataById(id);
          const employeeName = `${employeeData.nameEn.firstName} ${employeeData.nameEn.lastName}`;

          return {
            fullTag,
            newTag: `<a href="${employeeUrl}" target="_blank">${employeeData ? employeeName : id}</a>`,
          };
        } catch (error) {
          return { fullTag, newTag: `<a href="${employeeUrl}" target="_blank">${id}</a>` };
        }
      }),
    );

    let result = description;
    replacements.forEach(({ fullTag, newTag }) => {
      result = result.replace(fullTag, newTag);
    });

    return result;
  };

  const replaceRecruitingFinanceTag = async (description: string) => {
    const financeTagRegexp = /<recruitingfinance\s+id=['"]([\S\d]+?)['"]\s*(\/>|>(.*?)<\/recruitingfinance>)/gm;

    const matches = [...description.matchAll(financeTagRegexp)];

    const replacements = await Promise.all(
      matches.map(async match => {
        const [fullTag, id] = match;
        try {
          const response = await sendPostRequest('/recruiting-finance/search', { ids: [id] });
          const data = await response.json();
          const financeData = data?.[0]?.value || 'N/A';

          return { fullTag, newTag: financeData };
        } catch (error) {
          return { fullTag, newTag: '(Sensitive info)' };
        }
      }),
    );

    let result = description;
    replacements.forEach(({ fullTag, newTag }) => {
      result = result.replace(fullTag, newTag);
    });

    return result;
  };

  const replaceUrlTag = (
    text: string,
    tag: string | ((id: ResourceId) => string) | ((id?: string) => string),
    urlBuilder: ((id: StringOrNumber) => string) | ((id?: string | undefined) => string),
  ) => {
    const regexp = new RegExp(`<${tag}\\sid=['|"]([\\S\\d]+?)['|"]((>(.*?)</${tag}>)|(/>))`, 'mg');
    let result = text;
    let matched;

    // eslint-disable-next-line no-cond-assign
    while ((matched = regexp.exec(text))) {
      const fullTag = matched[0];
      const id = matched[1];
      const value = matched[4];

      const newTag = `<a href="${urlBuilder(id)}" target="_blank">${value || id}</a>`;

      result = result.replace(fullTag, newTag);
    }

    return result;
  };

  const replaceDateTag = (text: string, tag: any, replacer: any, getTimezone: any) => {
    const regexp = new RegExp(`<${tag}\\s+format=['"]([^'"]+)['"](>(.*?)</${tag}>|\\s*/>)`, 'mg');
    let result = text;
    const matches = [...text.matchAll(regexp)];

    matches.forEach(matched => {
      const fullTag = matched[0];
      const format = matched[1];
      const value = matched[3];
      const timezone = getTimezone ? getTimezone() : '';

      const newValue = `<b>${replacer(value, format)} ${timezone}</b>`;

      result = result.replace(fullTag, newValue);
    });

    return result;
  };

  const replaceAsyncTagsToLoader = (text: string, asyncTag: string) => {
    const regexp = new RegExp(`<${asyncTag}\\sid=['|"]([\\S\\d]+?)['|"]((>(.*?)</${asyncTag}>)|(/>))`, 'mg');

    const matches = [...text.matchAll(regexp)];

    const replacements = matches.map(match => {
      const [fullTag, id] = match;
      return { fullTag, newTag: `<${asyncTag} id='${id}'><i class="fa fa-spinner fa-spin"></i></${asyncTag}>` };
    });

    let result = text;
    replacements.forEach(({ fullTag, newTag }) => {
      result = result.replace(fullTag, newTag);
    });

    return result;
  };

  const mapTags = (description: any) => {
    const urlTagsToMap = [
      ['candidate', getCandidateUrl],
      ['lead', getLeadUrl],
      ['opportunity', getOpportunityUrl],
      ['contact', getContactUrl],
      ['account', getAccountUrl],
      ['employeeoffer', getEmployeeOfferUrl],
      ['candidateoffer', getCandidateOfferUrl],
      ['project', getProjectUrl],
      ['service', getCompanyServiceUrl],
    ];

    const dateTagsToMap = [['date', formatDateWithTimezone, getUserTimezone]];

    const asyncTagsToLoader = ['employee', 'recruitingfinance'];

    let result = description;

    urlTagsToMap.forEach(([tag, urlFunction]) => {
      result = replaceUrlTag(
        result,
        tag,
        urlFunction as ((id: StringOrNumber) => string) | ((id?: string | undefined) => string),
      );
    });

    dateTagsToMap.forEach(([tag, replacer, timezone]) => {
      result = replaceDateTag(result, tag, replacer, timezone);
    });

    asyncTagsToLoader.forEach((asyncTag: string) => {
      result = replaceAsyncTagsToLoader(result, asyncTag);
    });

    return result;
  };

  const mapAsyncTags = async (description: any) => {
    let result = description;
    result = await replaceEmployeeTag(result);
    result = await replaceRecruitingFinanceTag(result);

    return result;
  };

  return {
    mapTags,
    mapAsyncTags,
  };
};

export default useCustomHtmlTagHelper;
