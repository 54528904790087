import React from 'react';
import RelatedList from 'shared/uibuilder/list/RelatedList';
import useVacancyPublicationService from 'erp/recruitment/newVacancy/show/publications/vacancyPublicationService';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { BadgeField, DateField } from 'shared/uibuilder/field';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import PublicationPlatformField from 'erp/recruitment/newVacancy/show/publications/field/PublicationPlatformField';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import ViewVacancyPublicationButton from '../shared/button/ViewVacancyPublicationButton';
import PublicationLinkField from '../field/PublicationLinkField';
import CreateVacancyPublicationButton from '../shared/button/CreateVacancyPublicationButton';
import StatusBadge from 'erp/recruitment/newVacancy/show/publications/shared/badge/StatusBadge';

const RelatedPublicationsList = () => {
  const { search } = useVacancyPublicationService();

  return (
    <RelatedList getDataMethod={search}>
      <ShowSection>
        <RelationListLayout actions={[<CreateVacancyPublicationButton />]}>
          <DataGridLayout buttons={[<ViewVacancyPublicationButton source="id" />]} isSmallTable showTotal={false}>
            <PublicationPlatformField source="type" label="Platform" />
            <BadgeField
              width={100}
              isSortable
              badges={[<StatusBadge idSource="id" statusSource="status" />]}
              label="Status"
              source="status"
            />
            <PublicationLinkField label="Publication" />
            <DateField source="createdAt" label="Create Date" />
            <DateField source="updatedAt" label="Modification Date" />
          </DataGridLayout>
        </RelationListLayout>
      </ShowSection>
    </RelatedList>
  );
};

export default RelatedPublicationsList;
