/* istanbul ignore file */
import React from 'react';
import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';
import { useVacancyGroupId, useVacancyGroupUrl } from '../../../../routing';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

const EditVacancyPublicationButton = (props: EditButtonProps) => {
  const groupId = useVacancyGroupId();
  const { getValue: getPublicationId } = useFieldHelper({
    source: 'id',
  });
  const { getUpdatePublicationUrl } = useVacancyGroupUrl();

  return <EditButton {...props} path={id => getUpdatePublicationUrl(groupId, getPublicationId())} />;
};

export default EditVacancyPublicationButton;
