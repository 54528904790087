/* istanbul ignore file */

import { EnumField, FieldProps } from 'shared/uibuilder/field';
import React from 'react';
import { PUBLICATION_PLATFORM } from 'erp/recruitment/newVacancy/show/publications/vacancyPublicationService';

const PublicationPlatformField = (props: FieldProps) => {
  return <EnumField {...props} options={PUBLICATION_PLATFORM} />;
};

export default PublicationPlatformField;
