import React from 'react';
import useRecruitingBoardService from '../useRecruitingBoardService';
import List from 'shared/uibuilder/list/ListWithInfiniteScroll';
import RecruitingBoardContent from './RecruitingBoardContent';
import RecruitingContext from 'erp/recruitment/RecruitingContext';
import { MINUTE } from '../constants';

const RecruitingBoard = () => {
  const { search } = useRecruitingBoardService();

  // TODO: Infinite Scroll (ListWithInfiniteScroll)
  // Use DataGridInfiniteScrollPagingHandler for handle scroll (See - ProjectsDashboardsDataGrid.tsx)
  return (
    <RecruitingContext>
      <List
        getDataMethod={search}
        defaultSortOrder="DESC"
        defaultSortField="createdAt"
        defaultFilter={{ 'specification:vacancyGroupByStatus': { in: ['REQUIRES_REVIEW', 'OPEN'] } }}
        autoUpdateTime={MINUTE * 5}
      >
        <RecruitingBoardContent />
      </List>
    </RecruitingContext>
  );
};

export default RecruitingBoard;
