export default {
  interviewDateTime: {
    type: 'string',
    required: true,
  },
  interviewerAlias: {
    type: 'string',
    required: true,
  },
  interviewMethod: {
    type: 'enum',
    required: true,
  },
  resumeId: {
    type: 'array',
    required: true,
    maxSize: 1,
  },
};
