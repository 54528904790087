import React, { useState, useRef, useImperativeHandle } from 'react';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { ArrowRight } from '@mui/icons-material';
import { Theme } from '@mui/material/styles';

export interface NestedMenuItemProps extends Omit<MenuItemProps, 'button'> {
  parentMenuOpen: boolean;
  component?: React.ElementType;
  label?: React.ReactNode;
  rightIcon?: React.ReactNode;
  containerProps?: React.HTMLAttributes<HTMLElement> & React.RefAttributes<HTMLElement | null>;
  button?: true | undefined;
}

const NestedMenuItem = React.forwardRef<HTMLLIElement | null, NestedMenuItemProps>((props, ref) => {
  const {
    parentMenuOpen,
    label,
    rightIcon = <ArrowRight />,
    children,
    containerProps: containerPropsProp = {},
    ...menuItemProps
  } = props;

  const { ref: containerRefProp, ...containerProps } = containerPropsProp;

  const menuItemRef = useRef<HTMLLIElement>(null);
  useImperativeHandle(ref, () => menuItemRef.current as HTMLLIElement);

  const containerRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(containerRefProp as React.Ref<HTMLElement>, () => containerRef.current as HTMLDivElement);

  const menuContainerRef = useRef<HTMLDivElement>(null);

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setIsSubMenuOpen(true);

    if (containerProps?.onMouseEnter) {
      containerProps.onMouseEnter(event);
    }
  };

  const handleMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
    setIsSubMenuOpen(false);

    if (containerProps?.onMouseLeave) {
      containerProps.onMouseLeave(event);
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLElement>) => {
    if (event.target === containerRef.current) {
      setIsSubMenuOpen(true);
    }

    if (containerProps?.onFocus) {
      containerProps.onFocus(event);
    }
  };

  const open = isSubMenuOpen && parentMenuOpen;

  return (
    <div
      {...containerProps}
      ref={containerRef}
      onFocus={handleFocus}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <MenuItem
        {...menuItemProps}
        ref={menuItemRef}
        sx={{
          '&.Mui-focusVisible': {
            backgroundColor: (theme: Theme) => (open ? theme.palette.action.hover : 'transparent !important'),
          },
          fontSize: '12px',
          padding: '4px',
        }}
      >
        {label}
        {rightIcon}
      </MenuItem>
      <Menu
        style={{ pointerEvents: 'none', maxHeight: '400px' }}
        anchorEl={menuItemRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={open}
        autoFocus={false}
        disableAutoFocus
        disableEnforceFocus
        onClose={() => {
          setIsSubMenuOpen(false);
        }}
      >
        <div ref={menuContainerRef} style={{ pointerEvents: 'auto' }}>
          {children}
        </div>
      </Menu>
    </div>
  );
});

export default NestedMenuItem;
