/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { LinkField, TextField } from 'shared/uibuilder/field';
import { useArtifactStorageUrl } from 'artifactStorage/ArtifactStorageRouter';
import { EMPTY_VALUE_INDICATOR } from 'shared/uibuilder/helper';

interface ArtifactStorageOneFileLinkProps {
  id?: string;
  source?: string;
  linkText?: string;
  isInternal?: boolean;

  [key: string]: any;
}

const ArtifactStorageOneFileLink = ({
  id,
  linkText,
  source,
  label,
  isInternal = true,
  ...props
}: ArtifactStorageOneFileLinkProps) => {
  const { getValue: getId } = useFieldHelper({ source });
  const { getSingleEntityUrl } = useArtifactStorageUrl();
  const fileId = id || getId();
  const url = getSingleEntityUrl(fileId);

  return fileId ? (
    <LinkField {...props} linkText={linkText || fileId} value={url} isInternal={isInternal} />
  ) : (
    <TextField value={EMPTY_VALUE_INDICATOR} />
  );
};

export default ArtifactStorageOneFileLink;
