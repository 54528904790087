/* istanbul ignore file */
import React, { useState } from 'react';
import useAuthorization from 'shared/authorization/authorizationService';
import Button from 'uibuilder/button/Button';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import ApproveVacancyForm from 'erp/recruitment/newVacancy/shared/ApproveVacancyForm/ApproveVacancyForm';
import { RECRUITING_VACANCY_APPROVE_VACANCY, VACANCY_STATUS } from 'erp/recruitment/newVacancy/constants';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { RECRUITING_CONTEXT } from '../../../RecruitingContext';

const ApproveVacancyButton = ({
  statusSource,
  setGeneralContextData,
}: {
  statusSource: string;
  setGeneralContextData: any;
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { isGranted } = useAuthorization();

  const { getValue } = useFieldHelper({ source: statusSource });
  const status = getValue();

  if (status !== VACANCY_STATUS.REQUIRES_REVIEW) {
    return null;
  }

  const closeModal = () => {
    setModalOpen(false);
  };

  return isGranted(RECRUITING_VACANCY_APPROVE_VACANCY, RECRUITING_CONTEXT) ? (
    <>
      <Button
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          setModalOpen(true);
          (e.target as HTMLElement).blur();
        }}
        sx={{
          'svg path': {
            fill: 'var(--mui-palette-primary-contrastText)',
            opacity: 0.9,
          },
        }}
      >
        Approve
      </Button>
      <ModalWindow
        backdrop="static"
        isOpen={modalOpen}
        title="Vacancy Approval"
        onToggle={closeModal}
        hasCancelButton={false}
      >
        <ApproveVacancyForm
          closeModal={closeModal}
          modalOpen={modalOpen}
          setGeneralContextData={setGeneralContextData}
        />
      </ModalWindow>
    </>
  ) : null;
};

export default ApproveVacancyButton;
