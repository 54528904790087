import React from 'react';
import Typography from 'uibuilder/Typography';
import Paper from 'uibuilder/Paper';
import Box from 'uibuilder/Box';
import Message from 'shared/message/Message';
import classnames from 'classnames';

export interface DefaultFormPageLayoutProps {
  title?: string | React.ReactNode;
  entityHeader?: React.ReactElement;
  breadcrumbs?: React.ReactElement;
  buttons: React.ReactElement;
  children: React.ReactElement | React.ReactElement[];
  hasSeparateSections?: boolean;
}

const DefaultFormPageLayout = ({
  children,
  title,
  breadcrumbs,
  entityHeader,
  buttons,
  hasSeparateSections,
}: DefaultFormPageLayoutProps) => {
  return (
    <Paper className="page-content view-single-entry__wrapper">
      {breadcrumbs ? <Box sx={{ marginBottom: entityHeader ? '30px' : '20px' }}>{breadcrumbs}</Box> : null}
      {entityHeader ? <Box sx={{ marginBottom: '36px' }}>{entityHeader}</Box> : null}
      <div className="show-page__content-wrapper">
        <div
          className={classnames('show-page__content-inner', {
            'show-page__content-inner--without-wrapper': hasSeparateSections,
          })}
        >
          <div className="alerts__wrapper">
            <Message />
          </div>
          {title ? (
            <Typography
              variant={entityHeader ? 'h2' : 'h1'}
              component="h2"
              sx={{ marginBottom: '24px' }}
              className="c-title"
            >
              {title}
            </Typography>
          ) : null}
          {children}
          {buttons}
        </div>
      </div>
    </Paper>
  );
};

export default DefaultFormPageLayout;
