import React from 'react';

import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTheme } from '@mui/material/styles';
import { VACANCY_GROUP_STATUS } from 'erp/recruitment/newVacancy/constants';

interface StatusNotificationProps {
  source: string;
  size: 'XS' | 'S' | 'M';
}

const sizeMap = {
  XS: { text: 9, icon: 11 },
  S: { text: 12, icon: 14 },
  M: { text: 14, icon: 16 },
};

const StatusNotification: React.FC<StatusNotificationProps> = ({ source, size }: StatusNotificationProps) => {
  const { getValue } = useFieldHelper({ source });
  const status = getValue();
  const fontSize = sizeMap[size];
  const theme = useTheme();

  if (status === VACANCY_GROUP_STATUS.REQUIRES_REVIEW) {
    return (
      <span style={{ display: 'flex', alignItems: 'center', fontSize: fontSize.text, color: theme.palette.error.main }}>
        <ErrorOutlineIcon sx={{ fontSize: fontSize.icon, marginRight: '4px', color: theme.palette.error.main }} />{' '}
        Review is required
      </span>
    );
  }
  return null;
};

export default StatusNotification;
