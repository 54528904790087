import React from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import FormRow from 'shared/layout/form/FormRow';
import ReactCountryFlag from 'react-country-flag';
import { NumberInput } from 'shared/uibuilder/form/input';
import Box from 'uibuilder/Box';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { COMPENSATION_CURRENCY_TYPES } from 'erp/recruitment/newVacancy/constants';

interface ApproveVacancyCompensationsInputsProps {
  isLast: boolean;
  registration: string;
  source: string;
  value: any;
}

const ApproveVacancyCompensationsInputs = ({
  isLast,
  registration,
  ...props
}: ApproveVacancyCompensationsInputsProps) => {
  const { getSource } = useInputHelper(props);
  const source = getSource();

  return (
    <FormRow
      sx={{
        borderBottom: !isLast ? '1px solid #dcdbdb' : '',
      }}
      colXl={3}
    >
      <Box sx={{ padding: '10px' }}>
        <ReactCountryFlag
          key={`registration-${registration}`}
          countryCode={registration}
          style={{ width: '50px', height: '50px', boxShadow: '0px 0px 6px -4px rgba(0, 0, 0, 1)' }}
          svg
        />
      </Box>
      <NumberInput source={`${source}.compensation.salaryFrom`} label="Min salary" />
      <NumberInput source={`${source}.compensation.salaryTo`} label="Max salary" />
      <EnumDropdown
        source={`${source}.compensation.currency`}
        label="Currency"
        options={COMPENSATION_CURRENCY_TYPES}
        placeholder="Select"
        isRequired
      />
    </FormRow>
  );
};

export default ApproveVacancyCompensationsInputs;
