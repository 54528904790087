/* istanbul ignore file */
import React, { useState } from 'react';
import useAuthorization from 'shared/authorization/authorizationService';
import Button from 'uibuilder/button/Button';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import { RECRUITING_VACANCY_APPROVE_DECLINE_CANCELLATION, VACANCY_STATUS } from 'erp/recruitment/newVacancy/constants';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import TextField from 'shared/uibuilder/field/TextField';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import ApproveVacancyCancellationRequestButton from './ApproveVacancyCancellationRequestButton';
import DeclineVacancyCancellationRequestButton from './DeclineVacancyCancellationRequestButton';
import { RECRUITING_CONTEXT } from '../../../RecruitingContext';

const ResolveVacancyCancellationRequestButton = ({
  statusSource,
  setGeneralContextData,
}: {
  statusSource: string;
  setGeneralContextData: () => {};
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { isGranted } = useAuthorization();

  const { data } = useShowContext();
  const { cancelReason } = data.getData();

  const { getValue } = useFieldHelper({ source: statusSource });
  const status = getValue();

  const closeModal = () => {
    setModalOpen(false);
  };

  if (status !== VACANCY_STATUS.CANCELLATION_REQUESTED) {
    return null;
  }

  return isGranted(RECRUITING_VACANCY_APPROVE_DECLINE_CANCELLATION, RECRUITING_CONTEXT) ? (
    <>
      <Button
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          setModalOpen(true);
          (e.target as HTMLElement).blur();
        }}
        sx={{
          'svg path': {
            fill: 'var(--mui-palette-primary-contrastText)',
            opacity: 0.9,
          },
        }}
      >
        Resolve Cancellation Request
      </Button>
      <ModalWindow
        backdrop="static"
        isOpen={modalOpen}
        title="Make a decision about vacancy cancellation"
        onToggle={closeModal}
        hasCancelButton={false}
        buttons={[
          <ApproveVacancyCancellationRequestButton
            setGeneralContextData={setGeneralContextData}
            setModalOpen={setModalOpen}
          />,
          <DeclineVacancyCancellationRequestButton
            setGeneralContextData={setGeneralContextData}
            setModalOpen={setModalOpen}
          />,
        ]}
      >
        <TextField source="cancellationReason" label="Cancellation Reason" value={cancelReason} />
      </ModalWindow>
    </>
  ) : null;
};

export default ResolveVacancyCancellationRequestButton;
