import React from 'react';
import AppIcon from 'uibuilder/AppIcon/AppIcon';
import { AppIconType } from 'uibuilder/AppIcon/const';
import Tooltip from 'uibuilder/Tooltip';

interface AppIconLabelProps {
  icon: AppIconType;
  tooltipTitle: string;
}

const AppIconLabel: React.FC<AppIconLabelProps> = ({ icon, tooltipTitle }) => {
  return (
    <Tooltip title={tooltipTitle}>
      <span style={{ display: 'flex', alignItems: 'center', marginRight: '15px' }}>
        <AppIcon icon={icon} style={{ fontSize: '22px', color: '#4D4C59' }} />
      </span>
    </Tooltip>
  );
};

export default AppIconLabel;
