import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const BaselinePeopleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg overflow="visible" width="60" height="50" viewBox="3 3 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M33.334 22.9167C36.7923 22.9167 39.5631 20.125 39.5631 16.6667C39.5631 13.2084 36.7923 10.4167 33.334 10.4167C29.8757 10.4167 27.084 13.2084 27.084 16.6667C27.084 20.125 29.8757 22.9167 33.334 22.9167ZM16.6673 22.9167C20.1257 22.9167 22.8965 20.125 22.8965 16.6667C22.8965 13.2084 20.1257 10.4167 16.6673 10.4167C13.209 10.4167 10.4173 13.2084 10.4173 16.6667C10.4173 20.125 13.209 22.9167 16.6673 22.9167ZM16.6673 27.0834C11.8132 27.0834 2.08398 29.5209 2.08398 34.375V39.5834H31.2507V34.375C31.2507 29.5209 21.5215 27.0834 16.6673 27.0834ZM33.334 27.0834C32.7298 27.0834 32.0423 27.125 31.3131 27.1875C33.7298 28.9375 35.4173 31.2917 35.4173 34.375V39.5834H47.9173V34.375C47.9173 29.5209 38.1881 27.0834 33.334 27.0834Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default BaselinePeopleIcon;
