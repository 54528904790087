import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const RUserIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg overflow="visible" width="50" height="73" viewBox="3 3 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M25.0007 25C22.709 25 20.7472 24.184 19.1152 22.5521C17.4833 20.9201 16.6673 18.9583 16.6673 16.6666C16.6673 14.375 17.4833 12.4132 19.1152 10.7812C20.7472 9.14929 22.709 8.33331 25.0007 8.33331C27.2923 8.33331 29.2541 9.14929 30.8861 10.7812C32.518 12.4132 33.334 14.375 33.334 16.6666C33.334 18.9583 32.518 20.9201 30.8861 22.5521C29.2541 24.184 27.2923 25 25.0007 25ZM8.33398 41.6666V35.8333C8.33398 34.6528 8.63815 33.568 9.24648 32.5791C9.85482 31.5903 10.6618 30.8347 11.6673 30.3125C13.8201 29.2361 16.0076 28.4291 18.2298 27.8916C20.452 27.3541 22.709 27.0847 25.0007 27.0833C27.2923 27.0819 29.5493 27.3514 31.7715 27.8916C33.9937 28.4319 36.1812 29.2389 38.334 30.3125C39.3409 30.8333 40.1486 31.5889 40.7569 32.5791C41.3652 33.5694 41.6687 34.6541 41.6673 35.8333V41.6666H8.33398Z"
          fill="currentColor"
        />
        <g filter="url(#filter0_d_0_1)">
          <path
            d="M19.2642 65V50.4545H25.0028C26.1013 50.4545 27.0388 50.651 27.8153 51.044C28.5966 51.4323 29.1908 51.9839 29.598 52.6989C30.0099 53.4091 30.2159 54.2448 30.2159 55.206C30.2159 56.1719 30.0076 57.0028 29.5909 57.6989C29.1742 58.3902 28.5705 58.9205 27.7798 59.2898C26.9938 59.6591 26.0421 59.8438 24.9247 59.8438H21.0824V57.3722H24.4276C25.0147 57.3722 25.5024 57.2917 25.8906 57.1307C26.2789 56.9697 26.5677 56.7282 26.7571 56.4062C26.9512 56.0843 27.0483 55.6842 27.0483 55.206C27.0483 54.723 26.9512 54.3158 26.7571 53.9844C26.5677 53.6529 26.2765 53.402 25.8835 53.2315C25.4953 53.0563 25.0052 52.9688 24.4134 52.9688H22.3395V65H19.2642ZM27.1193 58.3807L30.7344 65H27.3395L23.8026 58.3807H27.1193Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_0_1"
            x="15.2637"
            y="50.4545"
            width="19.4707"
            height="22.5455"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape" />
          </filter>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default RUserIcon;
