import React, { useState } from 'react';
import { Menu, MenuItem, SxProps } from '@mui/material';
import Badge from 'shared/uibuilder/badge/Badge';
import SmallLoader from 'shared/uibuilder/SmallLoader';
import Tooltip from '../../../uibuilder/Tooltip';

export interface EditableBadgeValueProps {
  value: string;
  bgcolor?: string;
  disabled?: boolean;
  disabledReason?: JSX.Element;
  textColor?: string;
}

interface EditableBadgeProps {
  children: any;
  color: BasicColors;
  values: EditableBadgeValueProps[];
  canUpdate: boolean;
  onChange: (newValue: string) => Promise<void>;
  menuItemSx?: SxProps;
  badgeSx?: SxProps;
}

const EditableBadge = ({
  children,
  color,
  values,
  onChange,
  canUpdate = true,
  menuItemSx = {},
  badgeSx,
}: EditableBadgeProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = async (newValue: string) => {
    try {
      setLoading(true);
      await onChange(newValue);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const menuItems = values.map(({ value, bgcolor, textColor, disabled = false, disabledReason }) => {
    if (disabled && disabledReason) {
      return (
        <Tooltip title={disabledReason}>
          <span>
            <MenuItem
              key={value}
              onClick={() => handleMenuItemClick(value)}
              sx={{
                ...menuItemSx,
                ...(bgcolor && {
                  backgroundColor: bgcolor,
                  '&:hover': {
                    backgroundColor: bgcolor,
                    opacity: '0.8',
                  },
                }),
                ...(textColor && {
                  color: textColor,
                }),
              }}
              disabled={disabled}
            >
              {value}
            </MenuItem>
          </span>
        </Tooltip>
      );
    } else {
      return (
        <MenuItem
          key={value}
          onClick={() => handleMenuItemClick(value)}
          sx={{
            ...menuItemSx,
            ...(bgcolor && {
              backgroundColor: bgcolor,
              '&:hover': {
                backgroundColor: bgcolor,
                opacity: '0.8',
              },
            }),
            ...(textColor && {
              color: textColor,
            }),
          }}
          disabled={disabled}
        >
          {value}
        </MenuItem>
      );
    }
  });

  return (
    <>
      <Badge color={color} onClick={canUpdate ? handleClick : undefined} sx={badgeSx}>
        {loading ? <SmallLoader /> : children}
      </Badge>
      <Menu anchorEl={anchorEl} open={canUpdate && Boolean(anchorEl)} onClose={handleClose}>
        {menuItems}
      </Menu>
    </>
  );
};

export default EditableBadge;
