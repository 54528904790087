import React, { ReactElement, useCallback, useState } from 'react';
import { ContextMenuProvider } from './ContextMenuContext';
import { Menu, MenuItem, PopoverPosition, Theme } from '@mui/material';
import NestedMenuItem from './NestedMenuItem';
import { MappedApplication, VacancyItem } from '../../types';

export interface ContextMenuItem extends VacancyItem {
  label: string;
  onClick?: (item: MappedApplication | null, submenuItem: VacancyItem | null) => void;
  submenu?: ContextMenuItem[];
}

interface ContextMenuProps {
  children: ReactElement;
  menuItems: ContextMenuItem[];
}

const ContextMenu: React.FC<ContextMenuProps> = ({ children, menuItems }) => {
  const [menuPosition, setMenuPosition] = useState<PopoverPosition | null>(null);
  const [currentItem, setCurrentItem] = useState<MappedApplication | null>(null);

  const handleContextMenu = (event: React.MouseEvent, application: MappedApplication) => {
    if (menuPosition) {
      return;
    }

    event.preventDefault();
    setCurrentItem(application);
    setMenuPosition({
      top: event.pageY,
      left: event.pageX,
    });
  };

  const handleClose = () => {
    setMenuPosition(null);
  };

  const handleItemClick = useCallback(
    (
      submenuItem: VacancyItem | null,
      onClick?: (application: MappedApplication | null, submenuItem: VacancyItem | null) => void,
    ) => {
      if (onClick) {
        handleClose();
        onClick(currentItem, submenuItem);
      }
    },
    [currentItem],
  );

  const contextData = {
    menuPosition,
    handleContextMenu,
    handleClose,
    menuItems,
  };

  const isMenuOpen = Boolean(menuPosition);

  return (
    <ContextMenuProvider value={contextData}>
      {children}
      <Menu
        open={isMenuOpen}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition || undefined}
        MenuListProps={{ sx: { padding: '4px 0' } }}
      >
        {menuItems.map(item => {
          if (item.submenu?.length) {
            return (
              <NestedMenuItem label={item.label} parentMenuOpen={isMenuOpen}>
                {item.submenu
                  .filter(submenuItem => currentItem && submenuItem.id !== currentItem?.entityId)
                  .map(submenuItem => (
                    <MenuItem
                      sx={{ fontSize: '12px', padding: '8px 4px' }}
                      onClick={() => handleItemClick(submenuItem, item.onClick)}
                    >
                      {submenuItem.label} {submenuItem.specificNote}
                    </MenuItem>
                  ))}
              </NestedMenuItem>
            );
          }

          return (
            <MenuItem
              sx={{
                '&.Mui-focusVisible': {
                  backgroundColor: 'transparent !important',
                },
                ':hover': {
                  backgroundColor: (theme: Theme) => `${theme.palette.action.hover} !important`,
                },
              }}
              onClick={() => handleItemClick(item, item.onClick)}
            >
              {item.label} {item.specificNote}
            </MenuItem>
          );
        })}
      </Menu>
    </ContextMenuProvider>
  );
};

export default ContextMenu;
