import TextField from 'shared/uibuilder/field/TextField';
import React, { useCallback, useState } from 'react';
import AppIconLabel from 'uibuilder/layout/AppIconLabel';
import VacancyGroupSectionTitle from '../shared/VacancyGroupSectionTitle';
import { CommaListField } from 'shared/uibuilder/field';
import EmployeeLinkFieldWithNameLoading from 'erp/employee/shared/field/EmployeeLinkFieldWithNameLoading';
import DateField from 'shared/uibuilder/field/DateField';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import UpdateForm from 'shared/uibuilder/form/UpdateForm';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import EmployeesInput from 'financialAnalytic/corporateEvents/shared/input/EmployeesInput';
import useEmployeeNamesService from 'erp/employee/shared/employeeNamesService';
import useVacancyGroupService from '../../useVacancyGroupService';
import useAuthorization from 'shared/authorization/authorizationService';
import { RECRUITING_VACANCY_GROUP_UPDATE } from 'erp/recruitment/newVacancy/constants';
import { RECRUITING_CONTEXT } from 'erp/recruitment/RecruitingContext';
import './VacancyGroupInfo.scss';
import IconSectionRow from 'uibuilder/layout/IconSectionRow';
import { SaveGroupButton, CloseEditGroupButton, EditGroupButton } from './shared/buttons/VacancyGroupButton';
import ReactCountryFlag from 'react-country-flag';
import Box from 'uibuilder/Box';

const VacancyGroupInfo = () => {
  const [isEdit, setEdit] = useState(false);
  const { updateVacancyGroup } = useVacancyGroupService();
  const { isGranted } = useAuthorization();
  const { getEmployeeNamesByAliases } = useEmployeeNamesService();
  const { data: show, setData = () => {} } = useShowContext();
  const showData = show.getData();

  const toggleEdit = useCallback(() => setEdit(prev => !prev), []);

  const getDataForUpdate = useCallback(async () => {
    return {
      recruiters: await getEmployeeNamesByAliases(showData.recruiters),
      sourcers: await getEmployeeNamesByAliases(showData.sourcers),
      priority: showData.priority,
      specificNote: showData.specificNote,
      details: {
        coordinators: await getEmployeeNamesByAliases(showData.details.coordinators),
      },
    };
  }, [getEmployeeNamesByAliases, showData]);

  const renderActions = useCallback(() => {
    if (!isGranted(RECRUITING_VACANCY_GROUP_UPDATE, RECRUITING_CONTEXT)) {
      return null;
    }

    return isEdit ? (
      <>
        <SaveGroupButton />
        <CloseEditGroupButton toggleEdit={toggleEdit} getDataForUpdate={getDataForUpdate} />
      </>
    ) : (
      <EditGroupButton onClickButton={toggleEdit} />
    );
  }, [isGranted, isEdit, toggleEdit, getDataForUpdate]);

  return (
    <UpdateForm
      submitFormFunc={(id, values) => updateVacancyGroup(id, { ...values, priority: showData.priority })}
      getDataFunc={getDataForUpdate}
      afterSubmit={{
        message: 'You are awesome! The Vacancy Group has been successfully updated.',
        execute: (_id, response: any) => {
          setData({
            ...showData,
            recruiters: response.recruiters,
            sourcers: response.sourcers,
            priority: response.priority,
            specificNote: response.specificNote,
            details: {
              ...showData.details,
              coordinators: response.details.coordinators,
            },
          });
          toggleEdit();
        },
      }}
    >
      <ShowSection
        justifyContent="space-between"
        titleId="vacancy-group-title"
        title={data => <VacancyGroupSectionTitle data={data?.getData()} actions={renderActions()} isEdit={isEdit} />}
      >
        <IconSectionRow>
          <TextField
            source="details.englishLevel"
            label={<AppIconLabel icon="english" tooltipTitle="English Level" />}
          />
          <Box display="flex" alignItems="center" sx={{ width: '100%' }}>
            <AppIconLabel icon="salary" tooltipTitle="Compensations" />
            {showData.details.compensations.length ? (
              showData.details.compensations.map((item: any) => (
                <Box display="flex" alignItems="center" gap={1} sx={{ width: '100%' }}>
                  <ReactCountryFlag
                    key={`registration-${item.registration}`}
                    countryCode={item.registration}
                    style={{ width: '20px', height: '20px', boxShadow: '0px 0px 6px -4px rgba(0, 0, 0, 1)' }}
                    svg
                  />
                  <Box>
                    {item.compensationCurrencies.map((salary: any) => (
                      <TextField value={salary.value} width="25%" />
                    ))}
                  </Box>
                </Box>
              ))
            ) : (
              <TextField />
            )}
          </Box>
          <DateField
            source="details.nearestTargetDate"
            label={<AppIconLabel icon="calendar" tooltipTitle="Nearest Target Date" />}
            dateFormat={DATE_FORMAT.FULL_DATE}
          />
          <TextField
            source="details.openPositionsCount"
            label={<AppIconLabel icon="baseline-people" tooltipTitle="Open Positions" />}
          />
          <CommaListField label={<AppIconLabel icon="work" tooltipTitle="Projects" />} source="details.projectNames" />
          {isEdit ? (
            <EmployeesInput
              className="inputs-in-view"
              source="sourcers"
              label={<AppIconLabel icon="s-user" tooltipTitle="Sourcers" />}
              customMapper={(item: any) => ({
                value: item.alias,
                label: `${item.nameEn?.firstName ?? ''} ${item.nameEn?.lastName ?? ''}`,
              })}
            />
          ) : (
            <CommaListField
              label={<AppIconLabel icon="s-user" tooltipTitle="Sourcers" />}
              source="sourcers"
              isNeedJoin={false}
              mapValue={value => <EmployeeLinkFieldWithNameLoading label="" externalId={value} />}
            />
          )}
          {isEdit ? (
            <EmployeesInput
              className="inputs-in-view"
              source="details.coordinators"
              label={<AppIconLabel icon="cs-user" tooltipTitle="Coordinators" />}
              customMapper={(item: any) => ({
                value: item.alias,
                label: `${item.nameEn?.firstName ?? ''} ${item.nameEn?.lastName ?? ''}`,
              })}
            />
          ) : (
            <CommaListField
              label={<AppIconLabel icon="cs-user" tooltipTitle="Coordinators" />}
              source="details.coordinators"
              isNeedJoin={false}
              mapValue={value => <EmployeeLinkFieldWithNameLoading label="" externalId={value} />}
            />
          )}
          {isEdit ? (
            <EmployeesInput
              className="inputs-in-view"
              source="recruiters"
              label={<AppIconLabel icon="r-user" tooltipTitle="Recruiters" />}
              customMapper={(item: any) => ({
                value: item.alias,
                label: `${item.nameEn?.firstName ?? ''} ${item.nameEn?.lastName ?? ''}`,
              })}
              defaultFilter={{ 'specification:isRecruiter': { eq: true } }}
            />
          ) : (
            <CommaListField
              label={<AppIconLabel icon="r-user" tooltipTitle="Recruiters" />}
              source="recruiters"
              isNeedJoin={false}
              mapValue={value => <EmployeeLinkFieldWithNameLoading label="" externalId={value} />}
            />
          )}
        </IconSectionRow>
      </ShowSection>
    </UpdateForm>
  );
};

export default VacancyGroupInfo;
