import { SvgIconProps } from '@mui/material';
import BaselinePeopleIcon from 'uibuilder/AppIcon/Icons/BaseLinePeopleIcon';
import CSUserIcon from 'uibuilder/AppIcon/Icons/CSUserIcon';
import EnglishIcon from 'uibuilder/AppIcon/Icons/EnglishIcon';
import NoteIcon from 'uibuilder/AppIcon/Icons/NoteIcon';
import RUserIcon from 'uibuilder/AppIcon/Icons/RUserIcon';
import SalaryIcon from 'uibuilder/AppIcon/Icons/SalaryIcon';
import SUserIcon from 'uibuilder/AppIcon/Icons/SUserIcon';
import WorkIcon from 'uibuilder/AppIcon/Icons/WorkIcon';
import CalendarIcon from 'uibuilder/AppIcon/Icons/СalendarIcon';

export type AppIconType =
  | 'english'
  | 'salary'
  | 'r-user'
  | 's-user'
  | 'cs-user'
  | 'calendar'
  | 'baseline-people'
  | 'work'
  | 'note';
export const AppIconTypeToSvgIcon: Record<AppIconType, React.FC<SvgIconProps>> = {
  english: EnglishIcon,
  salary: SalaryIcon,
  'r-user': RUserIcon,
  's-user': SUserIcon,
  'cs-user': CSUserIcon,
  calendar: CalendarIcon,
  'baseline-people': BaselinePeopleIcon,
  work: WorkIcon,
  note: NoteIcon,
};
