/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { VACANCY_GROUP_STATUSES } from '../../useRecruitingBoardService';

const VacancyGroupStatusDropdown = (props: any) => {
  const options = { ...VACANCY_GROUP_STATUSES };

  return (
    <EnumDropdown options={options} label="Vacancy Group Status" placeholder="Choose Vacancy Group Status" {...props} />
  );
};

export default VacancyGroupStatusDropdown;
