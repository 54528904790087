import React from 'react';
import Badge from '../../../../../shared/uibuilder/badge/Badge';
import { VACANCY_STATUS, VACANCY_STATUS_OPTIONS } from '../../constants';
import { SxProps } from '@mui/material';

type LabelStatusProps = {
  status: keyof typeof VACANCY_STATUS;
  sx?: SxProps;
};

const LabelStatus = ({ status, sx }: LabelStatusProps) => {
  const colorFunc = () => {
    if (status === VACANCY_STATUS.OPEN) {
      return 'success';
    }

    if (status === VACANCY_STATUS.REQUIRES_REVIEW || VACANCY_STATUS.CANCELLATION_REQUESTED) {
      return 'warning';
    }

    if (status === VACANCY_STATUS.EXPIRED) {
      return 'error';
    }

    return 'neutral';
  };

  return (
    <Badge
      sx={{
        marginLeft: '0',
        right: 0,
        minWidth: 80,
        paddingX: '10px',
        height: 25,
        color: 'white',
        fontSize: '12px',
        ...sx,
      }}
      color={colorFunc()}
    >
      {VACANCY_STATUS_OPTIONS[status]}
    </Badge>
  );
};

export default LabelStatus;
