import React, { useContext } from 'react';

export interface VacancyContextData {
  areVacanciesUpdated: boolean;
  arePublicationsUpdated: boolean;
  setAreVacanciesUpdated: (areVacanciesUpdated: boolean) => void;
  setArePublicationsUpdated: (arePublicationsUpdated: boolean) => void;
}

const VacancyContext = React.createContext<Partial<VacancyContextData>>({});

export const VacancyContextProvider = VacancyContext.Provider;

export const useVacancyContext = () => useContext(VacancyContext);

export default VacancyContext;
