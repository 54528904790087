import React, { useState } from 'react';
import MultiSelectInput from 'shared/uibuilder/form/input/MultiSelectInput';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { ListResponse } from 'shared/uibuilder/list/builder/useListCrud';
import { Option } from 'shared/uibuilder/form/input/dropdownHelper';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';

const DEFAULT_NUMBER_RESULTS_PER_PAGE = 30;

const lowerCaseFirstLetters = (string: string) =>
  string
    .split(' ')
    .map(word => word.charAt(0).toLowerCase() + word.slice(1))
    .join(' ');

interface MultiSelectFieldProps {
  additionalMapData?: (data: ListResponse<any>) => ListResponse<any>;
  searchString?: string;
  alias?: string;
  useService: () => { search: (params: any) => Promise<ListResponse<any>> };
  noOptionLabel: string;
  buildLabelCallback?: (item: any) => string | boolean;
  [key: string]: any;
}

const MultiSelectField = ({
  additionalMapData,
  searchString = 'searchString',
  alias = 'alias',
  useService,
  noOptionLabel,
  buildLabelCallback,
  ...props
}: MultiSelectFieldProps & DefaultInputPropTypes<any>) => {
  const { search } = useService();
  const { getSource, getRawOnChangeCallback } = useInputHelper(props);
  const [valueState, setValueState] = useState([]);

  const parentOnChange = getRawOnChangeCallback() || (() => {});

  const loadSuggestionsMethod = async (searchValue: any) => {
    let data = await search({
      filter: {
        [searchString]: { ct: searchValue },
      },
    });
    if (additionalMapData) {
      data = additionalMapData(data);
    }

    return data;
  };

  const formatDataForMultiSelectInput = (data?: any) => {
    if (!data) {
      return [];
    }

    const refinedMessage = {
      label: `Only ${DEFAULT_NUMBER_RESULTS_PER_PAGE} results are shown. Please refine your search query`,
      value: 'refine-query',
      isDisabled: true,
      isMessage: true,
    };

    const returnData = data.result.map((item: any) => ({
      value: item[`${alias}`] ? item[`${alias}`] : null,
      label: buildLabelCallback ? buildLabelCallback(item) : item.name,
      isDisabled: !item[`${alias}`],
    }));

    return [...(data.totalPages > 1 ? [refinedMessage] : []), ...returnData];
  };

  const onChangeCallback = (obj: Dictionary<any>) => {
    const mappedArray = obj[getSource()] || [];
    setValueState(mappedArray.map(({ value, label }: Option) => ({ value, label })));

    parentOnChange({
      [getSource()]: mappedArray.map((item: any) => item.value),
    });
  };

  const noOptionsMessage = (value?: Dictionary<string>) =>
    value?.inputValue.length
      ? `${noOptionLabel} is not found.`
      : `Start typing the name of the ${lowerCaseFirstLetters(noOptionLabel)}.`;

  const mappedValue = valueState.map(({ value, label }: Option) => ({ value, label }));

  return (
    <MultiSelectInput
      {...props}
      onChangeCallback={onChangeCallback}
      mapResults={formatDataForMultiSelectInput}
      value={mappedValue}
      noOptionsMessage={noOptionsMessage}
      loadSuggestionsMethod={loadSuggestionsMethod}
    />
  );
};

export default MultiSelectField;
