/* istanbul ignore file */
import React from 'react';
import MultiSelectInputFilter from 'shared/uibuilder/list/filter/MultiSelectInputFilter';
import { ConditionProps } from 'shared/uibuilder/list/filter/condition/Condition';
import MultiSelectField from 'erp/shared/input/MultiSelectField';
import useVacancyGroupService from 'erp/recruitment/newVacancy/useVacancyGroupService';

const VacancyGroupFilter = ({ label, ...props }: Omit<ConditionProps, 'children'> & { label: string }) => {
  return (
    <MultiSelectInputFilter {...props}>
      <MultiSelectField
        {...props}
        label={label}
        useService={useVacancyGroupService}
        searchString="searchString"
        noOptionLabel="Vacancy group"
        buildLabelCallback={(group) => group.searchString}
      />
    </MultiSelectInputFilter>
  );
};

export default VacancyGroupFilter;
