import { useFinanceApi, useRecruitmentApi } from 'api';
import useCrudService from 'shared/crud';
import createVacancySchema from './createupdate/create/createVacancySchema';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { cloneDeep } from 'lodash';
import { CompensationCurrency, DetailsCompensation, Vacancy, VacancyFormType, VacancyGroupType } from './types';
import useHiringService, { HIRING_MODE } from '../hiring/useHiringService';
import { LOCATION_ACCOMMODATIONS, RELOCATION } from './constants';
import usePositionService from '../../position/positionService';
import updateVacancySchema from './createupdate/update/updateVacancySchema';
import closePositionValidationSchema from './show/vacancy/shared/closePosition/closePositionValidationSchema';
import useEmployeeNamesService from '../../employee/shared/employeeNamesService';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { CURRENCIES } from 'shared/uibuilder/field/CurrencyField';

const VACANCIES_GROUPS = '/vacancies/groups';
const DAYS_TO_CLOSE = 30;
const NO_ACCESS_MESSAGE = 'No Access';

const useVacancyGroupService = () => {
  const { sendPostRequest, sendGetRequest, sendPutRequest, sendPatchRequest } = useRecruitmentApi();
  const { sendPostRequest: sendFinancePostRequest } = useFinanceApi();
  const { searchForMigration } = useHiringService();
  const { findAll: findAllPositions } = usePositionService();
  const { getEmployeeNameDataById } = useEmployeeNamesService();
  const { getCurrentDateTimeInUtc } = useDateService();
  const currentDate = getCurrentDateTimeInUtc();
  const calculatedTargetHiringDate = currentDate.add(DAYS_TO_CLOSE, 'days').format(DATE_FORMAT.INPUT_VALUE);

  const { searchAll: baseSearchAll, ...baseCrud } = useCrudService({
    singleResourceUrl: `${VACANCIES_GROUPS}/:id`,
    listResourceUrl: VACANCIES_GROUPS,
    apiService: useRecruitmentApi,
  });

  const { search: baseSearchVacancy } = useCrudService({
    singleResourceUrl: '/vacancies/:id',
    listResourceUrl: '/vacancies',
    apiService: useRecruitmentApi,
  });

  const mapOfficeToLocation = (office: number) => {
    switch (office) {
      case 1:
        return { country: 'US', city: 'Austin' };
      case 2:
        return { country: 'BY', city: 'Minsk' };
      case 3:
        return { country: 'PL', city: 'Krakow' };
      case 4:
        return { country: 'GE', city: 'Tbilisi' };
      case 5:
      default:
        return null;
    }
  };

  const getSpecializationOptions = async () => {
    const positions = await findAllPositions();

    return positions.map((position: Dictionary<string>) => {
      return { alias: position.vacancyAlias, name: position.name };
    }, []);
  };

  const searchVacancy = (request: any) => {
    const searchParams = cloneDeep(request);
    const searchString = searchParams.filter?.searchString?.ct || '';

    if (searchString && searchParams.filter) {
      delete searchParams.filter?.searchString;
      searchParams.filter.specialization = {
        ct: searchString,
      };
    }

    return baseSearchVacancy(searchParams);
  };

  const mapLegacyVacancy = async (vacancy: any) => {
    const hiringRequests = await searchForMigration(vacancy.hiringRequestIds, vacancy.alias);

    const group = {
      alias: vacancy.alias,
      specialization: vacancy.name,
      recruiter: null,
      sourcer: vacancy.sourcerAlias,
      createdAt: vacancy.createdAt,
      createdBy: vacancy.createdById,
      updatedAt: vacancy.updatedAt,
      updatedBy: vacancy.updatedById,
    };

    const vacancies = hiringRequests.map((request: any) => {
      const optionalRequirementsDescription = vacancy.description.optionalRequirements
        ? `<ul>${vacancy.description.optionalRequirements
            .map((responsibility: any) => `<li>${responsibility.name}: ${responsibility.description}</li>`)
            .join('')}</ul>`
        : '';

      return {
        status: {
          OPEN: 'OPEN',
          CLOSED: 'CLOSED',
          FAILED: 'EXPIRED',
          CANCELED: 'CANCELLED',
          MIGRATED: 'CLOSED',
        }[request.status as string],
        specialization: request.specialisation,
        competencyLevelMin: vacancy.competencyLevel === 'N/A' ? null : vacancy.competencyLevel,
        competencyLevelMax: vacancy.competencyLevel === 'N/A' ? null : vacancy.competencyLevel,
        isAsap: request.isAsap,
        targetHiringDate: request.isAsap === true ? calculatedTargetHiringDate : request.targetDate,
        expirationDate: request.expirationDate,
        hiringMode: request.hiringMode,
        workingConditions: vacancy.vacancyDetails.map((details: any) => ({
          registration: {
            1: 'US',
            2: 'BY',
            3: 'PL',
            5: 'PL',
          }[details.office as number],
          accommodation: {
            1: 'ANYWHERE',
            2: 'BY',
            3: 'EU',
            5: 'ANYWHERE',
          }[details.office as number],
          employmentTypes: details.assignmentType,
          contractTypes: details.contractType.map(
            (type: any) =>
              ({
                EC: 'EMPLOYMENT_CONTRACT',
                CFS: 'CONTRACT_FOR_SERVICES',
                B2B: 'B2B_CONTRACT',
                INTERNSHIP: 'INTERNSHIP',
              })[type as string],
          ),
          isRelocationAvailable: {
            REQUIRED: true,
            OPTIONAL: true,
            NOT_APPLICABLE: false,
          }[details.relocationOption as string],
        })),
        projects: [],
        responsibilities: `<ul>${vacancy.description.responsibilities
          .map((responsibility: any) => `<li>${responsibility.name}: ${responsibility.description}</li>`)
          .join('')}</ul>`,
        mandatoryRequirements: {
          description: `<ul>${vacancy.description.requirements
            .map((responsibility: any) => `<li>${responsibility.name}: ${responsibility.description}</li>`)
            .join('')}</ul>`,
          skills: vacancy.description.mandatorySkills,
          requiredExperienceInYears: vacancy.minExperience,
          englishLevel: 'B1',
        },
        optionalRequirements: {
          description: optionalRequirementsDescription,
          skills: vacancy.description.optionalSkills,
        },
        notes: {
          description: vacancy.description.projectText,
          attachmentIds: [],
        },
        positions: request.requestPositions.map((position: any) => ({
          type: {
            REQUIRED: 'REQUIRED',
            EXTRA: 'ADDITIONAL',
          }[position.necessityStatus as string],
          status: {
            OPEN: 'OPEN',
            CLOSED: 'CLOSED',
            CANCELED: 'CANCELLED',
          }[position.positionStatus as string],
          forecastDate: position.forecastDates?.[0]?.forecastDate ?? null,
          closeDate: position.closeDate ?? null,
          startWorkingDate: null,
        })),
        createdAt: request.createdAt,
        createdBy: request.createdBy,
      };
    });

    const vacancyDetails = vacancy.vacancyDetails.map((details: any) => ({
      location: mapOfficeToLocation(details.office),
      assignmentType: details.assignmentType,
      workingSchedule: details.workingSchedule,
      workplaceLocation: details.workplaceLocation,
      contractType: details.contractType.map(
        (type: any) =>
          ({
            EC: 'EMPLOYMENT_CONTRACT',
            CFS: 'CONTRACT_FOR_SERVICES',
            B2B: 'B2B_CONTRACT',
            INTERNSHIP: 'INTERNSHIP',
          })[type as string],
      ),
      relocationOption: details.relocationOption,
    }));

    const publications = vacancy.publications.map((publication: any) => ({
      description: publication.description,
      name: publication.name,
      type: publication.type,
      status: publication.status,
      englishLevels: publication.englishLevels,
      countryOfResidences: publication.countryOfResidences,
      formFields: publication.formFields,
      createdAt: publication.createdAt,
      createdBy: publication.createdBy,
      updatedAt: publication.updatedAt,
      updatedBy: publication.updatedBy,
      publicationDetails: vacancyDetails,
    }));

    return { group, vacancies, publications };
  };

  const migrateVacancy = async (
    group: any,
    vacancies: any[],
    publications: any[],
    migrateHiringRequests: boolean,
    vacancyGroupToMerge: string | null,
  ) => {
    if (!migrateHiringRequests && !vacancyGroupToMerge) {
      throw new Error('Unexpected error occurred. Please, reload the page.');
    }

    const vacanciesToMigrate = migrateHiringRequests ? vacancies : [];

    const result = await sendPostRequest('/vacancies/migrate', {
      group,
      vacancies: vacanciesToMigrate,
      publications,
    });
    const resultObject = await result.json();

    if (!migrateHiringRequests) {
      const vacanciesToMove = await searchVacancy({
        filter: {
          'vacancyGroup.id': {
            eq: vacancyGroupToMerge,
          },
        },
      });

      await Promise.all(vacanciesToMove.result.map((vacancy: any) => moveToGroup(vacancy.id, resultObject.id)));
    }
  };

  const moveToGroup = async (vacancyId: string, vacancyGroupId: string | null) => {
    const result = await sendPatchRequest(`/vacancies/${vacancyId}/group`, { vacancyGroupId });
    return result.json();
  };

  const changePriority = async (vacancyGroupId: string, priority: string) => {
    const result = await sendPatchRequest(`/vacancies/groups/${vacancyGroupId}`, { priority });
    return result.json();
  };

  const mapDataForCreate = (values: VacancyFormType) => {
    return {
      ...values,
      isCompetencyLevelRange: undefined,
      competencyLevel: undefined,
      competencyLevelMin: values.competencyLevelMin || values.competencyLevel,
      competencyLevelMax: values.competencyLevelMax || values.competencyLevel,
      workingConditions: values.workingConditions.map(condition => {
        return {
          ...condition,
          isRelocationAvailable: condition.isRelocationAvailable === RELOCATION.AVAILABLE,
        };
      }),
    };
  };

  const mapVacancyGroup = async (
    values: VacancyGroupType,
    vacancies: Vacancy[],
    specializationsResponse: { alias: string; name: string }[],
  ) => {
    const {
      competencyLevelMax,
      hiringModes,
      competencyLevelMin,
      englishLevelMin,
      englishLevelMax,
      compensations,
      accommodations,
      ...details
    } = values.details;
    const cachedCompensationData = new Map<string, string>();

    const findExtremaValue = (
      map: Map<string, number>,
      comparator: (a: number, b: number) => number,
      initialValue: number,
    ) => {
      const numericValues = Array.from(map.values());

      if (numericValues.length === 0) return initialValue;
      return numericValues.reduce((acc, value) => comparator(acc, value), initialValue);
    };

    const findMinValue = (map: Map<string, number>) => findExtremaValue(map, Math.min, Infinity);
    const findMaxValue = (map: Map<string, number>) => findExtremaValue(map, Math.max, -Infinity);

    const formatCompensation = (min: number, max: number, currency: string) =>
      min !== Infinity && max !== -Infinity ? `${min}-${max} ${CURRENCIES[currency]}` : null;

    const retrieveCompensationData = async (ids: string[]) => {
      const uncachedIds = ids.filter(id => !cachedCompensationData.has(id));
      if (uncachedIds.length > 0) {
        try {
          const newData = await retrieveSensitiveRecruitingInfo(uncachedIds);
          newData.forEach((value, key) => {
            if (value) cachedCompensationData.set(key, value);
          });
        } catch (error) {
          return new Map();
        }
      }
      return new Map(ids.map(id => [id, cachedCompensationData.get(id) || '']));
    };

    const retrieveCompensationRange = async (minIds?: string[], maxIds?: string[], currency?: string) => {
      try {
        const [minMap, maxMap] = await Promise.all([
          minIds && minIds.length ? retrieveCompensationData(minIds) : new Map(),
          maxIds && maxIds.length ? retrieveCompensationData(maxIds) : new Map(),
        ]);

        const minCompensation = findMinValue(minMap);
        const maxCompensation = findMaxValue(maxMap);

        return formatCompensation(minCompensation, maxCompensation, currency || 'USD');
      } catch (error) {
        return NO_ACCESS_MESSAGE;
      }
    };

    const mapCompensationCurrencies = async (compensationCurrencies: CompensationCurrency[]) => {
      return Promise.all(
        compensationCurrencies.map(async (compensationCurrency: CompensationCurrency) => ({
          value: await retrieveCompensationRange(
            compensationCurrency.compensationMinValueIds,
            compensationCurrency.compensationMaxValueIds,
            compensationCurrency.currency,
          ),
        })),
      );
    };

    const groupSalaryCompensations = await Promise.all(
      compensations.map(async (compensation: DetailsCompensation) => {
        return {
          registration: compensation.registration,
          compensationCurrencies: await mapCompensationCurrencies(compensation.compensationCurrencies),
        };
      }),
    );

    const mapVacancy = async (vacancy: Vacancy) => {
      const { competency, workingConditions, positions, ...data } = vacancy;

      const employeeDri = data.responsiblePerson ? await getEmployeeNameDataById(data.responsiblePerson) : null;

      return {
        coordinator: data.createdBy,

        ...data,
        competency,
        responsiblePersonName: employeeDri ? `${employeeDri.nameEn.firstName} ${employeeDri.nameEn.lastName}` : '',
        workingConditions: await Promise.all(
          workingConditions.map(async item => ({
            ...item,
            isRelocationAvailable: item.isRelocationAvailable ? RELOCATION.AVAILABLE : RELOCATION.NOT_AVAILABLE,
            compensationValue: item.compensation
              ? await retrieveCompensationRange(
                  item.compensation.salaryFromId ? [item.compensation.salaryFromId] : undefined,
                  item.compensation.salaryToId ? [item.compensation.salaryToId] : undefined,
                  item.compensation.currency,
                )
              : '-',
            compensation: item.compensation
              ? {
                  staticSalaryFromValue: cachedCompensationData.get(item.compensation.salaryFromId),
                  staticSalaryToValue: cachedCompensationData.get(item.compensation.salaryToId),
                  salaryFromValue: cachedCompensationData.get(item.compensation.salaryFromId),
                  salaryToValue: cachedCompensationData.get(item.compensation.salaryToId),
                  ...item.compensation,
                }
              : {},
          })),
        ),
        positions: positions.map(position => ({
          vacancyId: vacancy.id,
          ...position,
        })),
        competencyRange:
          competency.maxLevel === competency.minLevel
            ? competency.maxLevel
            : `${competency.minLevel} - ${competency.maxLevel}`,
        specializationName:
          specializationsResponse.find(item => item.alias === vacancy.specialization)?.name || vacancy.specialization,
      };
    };

    const mappedVacancies = await Promise.all(vacancies.map(mapVacancy));

    return {
      ...values,
      specialization:
        specializationsResponse.find(item => item.alias === values.specialization)?.name || values.specialization,
      vacancies: mappedVacancies,
      details: {
        competencyLevel:
          competencyLevelMax === competencyLevelMin
            ? competencyLevelMax
            : `${competencyLevelMin} - ${competencyLevelMax}`,
        englishLevel: englishLevelMax === englishLevelMin ? englishLevelMax : `${englishLevelMin} - ${englishLevelMax}`,
        compensations: groupSalaryCompensations,
        hiringModes: hiringModes.map(mode => HIRING_MODE[mode]),
        accommodations: accommodations.includes(
          LOCATION_ACCOMMODATIONS.ANYWHERE as keyof typeof LOCATION_ACCOMMODATIONS,
        )
          ? [LOCATION_ACCOMMODATIONS.ANYWHERE]
          : accommodations,
        ...details,
      },
    };
  };

  const createVacancy = async (values: FormFieldsData) => {
    const tempValues = cloneDeep(values) as VacancyFormType;
    const res = await sendPostRequest('/vacancies/request', mapDataForCreate(tempValues));

    return res.json();
  };

  const updateVacancy = async (id: string, values: FormFieldsData) => {
    const tempValues = cloneDeep(values);

    const res = await sendPutRequest(`/vacancies/${id}`, {
      title: tempValues.title,
      isAsap: tempValues.isAsap,
      targetHiringDate: tempValues.targetHiringDate,
      responsiblePerson: tempValues.responsiblePerson,
      workingConditions: await Promise.all(
        tempValues.workingConditions.map(async (item: any) => {
          const salaryFromKpiValue =
            item.compensation.salaryFromValue !== item.compensation.staticSalaryFromValue
              ? await saveSensitiveRecruitingInfo(item.compensation.salaryFromValue)
              : { id: item.compensation.salaryFromId, value: item.compensation.salaryFromValue };
          const salaryToKpiValue =
            item.compensation.salaryToValue !== item.compensation.staticSalaryToValue
              ? await saveSensitiveRecruitingInfo(item.compensation.salaryToValue)
              : { id: item.compensation.salaryToId, value: item.compensation.salaryToValue };

          const salaryFromId = salaryFromKpiValue.id;
          const salaryToId = salaryToKpiValue.id;

          return {
            ...item,
            isRelocationAvailable: item.isRelocationAvailable === RELOCATION.AVAILABLE,
            compensation: {
              id: item.compensation.id,
              currency: item.compensation.currency,
              salaryFromId,
              salaryToId,
            },
          };
        }),
      ),
      projects: tempValues.projects,
      responsibilities: tempValues.responsibilities,
      mandatoryRequirements: tempValues.mandatoryRequirements,
      optionalRequirements: tempValues.optionalRequirements,
      notes: tempValues.notes,
      positions: tempValues.positions.map((item: any) => {
        return {
          id: item.id,
          type: item.type,
          status: item.status,
          forecastDate: item.forecastDate,
          closeDate: item.closeDate,
          startWorkingDate: item.startWorkingDate,
        };
      }),
    });

    return res.json();
  };

  const approveVacancy = async (values: FormFieldsData) => {
    const workingConditionsCompensations = await Promise.all(
      values.workingConditions.map(async (workingCondition: any) => {
        const salaryFromKpiValue = await saveSensitiveRecruitingInfo(workingCondition.compensation.salaryFrom);
        const salaryToKpiValue = await saveSensitiveRecruitingInfo(workingCondition.compensation.salaryTo);

        const salaryFromId = salaryFromKpiValue.id;
        const salaryToId = salaryToKpiValue.id;

        return {
          workingConditionId: workingCondition.id,
          compensation: {
            salaryFromId,
            salaryToId,
            currency: workingCondition.compensation.currency === 'BYN' ? 'BYR' : workingCondition.compensation.currency,
          },
        };
      }),
    );

    const positionsForecasts = values.positions.map((position: any) => {
      return {
        positionId: position.id,
        forecastDate: position.forecastDate,
      };
    });

    const res = await sendPostRequest(`/vacancies/${values.id}/approve`, {
      responsiblePerson: values.responsiblePerson,
      positionsForecasts,
      workingConditionsCompensations,
    });

    return res.json();
  };

  const saveSensitiveRecruitingInfo = async (sensitiveInfo: any) => {
    const res = await sendFinancePostRequest('/recruiting-finance', { value: sensitiveInfo });

    return res.json();
  };

  const retrieveSensitiveRecruitingInfo = async (ids: string[]) => {
    const res = await sendFinancePostRequest('/recruiting-finance/search', { ids });

    const kpiValues = await res.json();
    const kpiValuesMap = new Map();

    kpiValues.forEach((kpiValue: any) => {
      kpiValuesMap.set(kpiValue.id, kpiValue.value);
    });

    return kpiValuesMap;
  };

  const searchVacanciesByGroup = async (groupId: StringOrNumber) => {
    const res = await sendPostRequest('/vacancies/search', {
      filter: {
        'vacancyGroup.id': {
          eq: groupId,
        },
      },
      pageSize: 30,
    });

    return res.json();
  };

  const getVacancyGroupById = async (groupId: StringOrNumber) => {
    const res = await sendGetRequest(`${VACANCIES_GROUPS}/${groupId}`);
    const groupDate = await res.json();
    const vacancies = await searchVacanciesByGroup(groupId);
    const setSpecializationsResponse = await getSpecializationOptions();

    return mapVacancyGroup(groupDate, vacancies.result || [], setSpecializationsResponse);
  };

  const updateVacancyGroup = async (id: StringOrNumber, values: FormFieldsData) => {
    try {
      const res = await sendPutRequest(`${VACANCIES_GROUPS}/${id}`, {
        sourcers: values.sourcers.map((item: any) => item.value),
        recruiters: values.recruiters.map((item: any) => item.value),
        coordinators: values.details.coordinators.map((item: any) => item.value),
        priority: values.priority,
        specificNote: values.specificNote,
      });

      return res.json();
    } catch (error) {
      return error;
    }
  };

  const closeVacancyPosition = async (
    vacancyId: StringOrNumber,
    positionId: StringOrNumber,
    values: FormFieldsData,
  ) => {
    const res = await sendPostRequest(`/vacancies/${vacancyId}/positions/${positionId}/close`, values);

    return res.json();
  };

  const requestVacancyCancellation = async (vacancyId: StringOrNumber, cancellationReason: any) => {
    const res = await sendPostRequest(`/vacancies/${vacancyId}/request-cancellation`, {
      cancelReason: cancellationReason,
    });

    return res.json();
  };

  const approveVacancyCancellation = async (vacancyId: StringOrNumber) => {
    const res = await sendPostRequest(`/vacancies/${vacancyId}/cancellation-request/approve`);

    return res.json();
  };

  const declineVacancyCancellation = async (vacancyId: StringOrNumber) => {
    const res = await sendPostRequest(`/vacancies/${vacancyId}/cancellation-request/decline`);

    return res.json();
  };

  const closeVacancy = async (vacancyId: StringOrNumber) => {
    const res = await sendPostRequest(`/vacancies/${vacancyId}/close`);

    return res.json();
  };

  const closeVacancyGroup = async (vacancyGroupId: StringOrNumber) => {
    const res = await sendPostRequest(`/vacancies/groups/${vacancyGroupId}/close`);

    return res.json();
  };

  const getCreateValidationSchema = () => Promise.resolve(createVacancySchema);
  const getUpdateValidationSchema = () => Promise.resolve(updateVacancySchema);
  const getClosePositionValidationSchema = () => Promise.resolve(closePositionValidationSchema);

  return {
    closeVacancyPosition,
    updateVacancyGroup,
    searchVacancy,
    moveToGroup,
    updateVacancy,
    getVacancyGroupById,
    createVacancy,
    approveVacancy,
    mapLegacyVacancy,
    migrateVacancy,
    getCreateValidationSchema,
    getUpdateValidationSchema,
    getClosePositionValidationSchema,
    searchVacanciesByGroup,
    baseSearchAll,
    requestVacancyCancellation,
    approveVacancyCancellation,
    declineVacancyCancellation,
    closeVacancy,
    closeVacancyGroup,
    changePriority,
    ...baseCrud,
  };
};

export default useVacancyGroupService;
