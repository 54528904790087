/* eslint-disable react/no-danger */
import React from 'react';
import { isArray, isObject } from 'lodash';
import { joinElements } from 'shared/uibuilder/helper';
import SanitizedHtml from 'shared/security/SanitizedHtml';
import useCustomHtmlTagHelper from 'uibuilder/customHtmlTagsHelper';
import useAsyncValue from 'shared/asyncHelper';

export type ErrorMessage = string | string[];

export interface BaseInputErrorsProps {
  errorMessages?: Nullable<ErrorMessage[]>;
  errorMessageMapper?: (error: ErrorMessage) => string | string[];
}

const formatError = (error: string) => {
  return error.replace(/(\d{4})-(\d{2})-(\d{2})/g, '$1&#8209;$2&#8209;$3');
};

const BaseInputErrors = ({ errorMessages = [], errorMessageMapper }: BaseInputErrorsProps) => {
  const { mapTags, mapAsyncTags = () => {} } = useCustomHtmlTagHelper();

  if (!errorMessages || !errorMessages.length) {
    return null;
  }

  let displayedErrors: (React.ReactElement | ErrorMessage)[] = errorMessages;

  if (errorMessageMapper) {
    displayedErrors = errorMessages.map(errorMessageMapper);
  }

  displayedErrors = displayedErrors.map(error =>
    typeof error === 'string' ? (
      (() => {
        const syncData = mapTags(error);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const mappedTags = useAsyncValue(async () => mapAsyncTags(syncData));

        return (
          <SanitizedHtml
            wrapperTag="span"
            className="form-error"
            html={formatError(mappedTags || syncData)}
            key={error}
          />
        );
      })()
    ) : (
      <span className="form-error" key={isArray(error) || !isObject(error) ? error.toString() : error.key}>
        {error}
      </span>
    ),
  );

  return <>{joinElements(displayedErrors, '')}</>;
};

export default BaseInputErrors;
