import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const BLetterIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 2C3.79086 2 2 3.79086 2 6V14C2 16.2091 3.79086 18 6 18H14C16.2091 18 18 16.2091 18 14V6C18 3.79086 16.2091 2 14 2H6ZM6.78 13.92C6.83333 13.9733 6.9019 14 6.98571 14H10.4829C11.0848 14 11.5952 13.901 12.0143 13.7029C12.4333 13.5048 12.7495 13.2267 12.9629 12.8686C13.1762 12.5105 13.2829 12.0914 13.2829 11.6114C13.2829 11.1771 13.1686 10.8038 12.94 10.4914C12.719 10.1714 12.46 9.94286 12.1629 9.80571C12.3 9.73714 12.441 9.63429 12.5857 9.49714C12.7381 9.36 12.8638 9.18476 12.9629 8.97143C13.0695 8.75809 13.1229 8.51048 13.1229 8.22857C13.1229 7.77905 13.0238 7.38667 12.8257 7.05143C12.6352 6.71619 12.3381 6.45714 11.9343 6.27429C11.5305 6.09143 11.0124 6 10.38 6H6.98571C6.9019 6 6.83333 6.02667 6.78 6.08C6.72667 6.13333 6.7 6.2019 6.7 6.28571V13.7143C6.7 13.7981 6.72667 13.8667 6.78 13.92ZM10.2657 12.6629H8.51714V10.5486H10.2657C10.6314 10.5486 10.9095 10.6514 11.1 10.8571C11.2981 11.0552 11.3971 11.3029 11.3971 11.6C11.3971 11.8895 11.3019 12.141 11.1114 12.3543C10.921 12.56 10.639 12.6629 10.2657 12.6629ZM10.1629 9.23429H8.51714V7.33714H10.1629C10.521 7.33714 10.7876 7.42476 10.9629 7.6C11.1457 7.76762 11.2371 7.99238 11.2371 8.27429C11.2371 8.55619 11.1457 8.78857 10.9629 8.97143C10.7876 9.14667 10.521 9.23429 10.1629 9.23429Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default BLetterIcon;
