export default {
  workingConditions: {
    type: 'array',
    required: true,
    forEach: {
      type: 'object',
      properties: {
        compensation: {
          type: 'object',
          required: true,
          properties: {
            salaryFrom: {
              type: 'number',
              required: true,
              regex: {
                value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
                message: 'Invalid number.',
              },
              maxValue: {
                value: '$salaryTo',
                message: 'Min value cannot exceed max.',
              },
            },
            salaryTo: {
              type: 'number',
              required: true,
              regex: {
                value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
                message: 'Invalid number.',
              },
              minValue: {
                value: '$salaryFrom',
                message: 'Max value cannot be below min.',
              },
            },
            currency: {
              type: 'string',
              required: true,
            },
          },
        },
      },
    },
  },

  responsiblePerson: {
    type: 'string',
    required: true,
  },

  positions: {
    type: 'array',
    required: true,
    forEach: {
      type: 'object',
      properties: {
        forecastDate: {
          type: 'date',
          required: true,
          maxDate: {
            value: '$expirationDate',
            message: 'Please choose date before or at "Expiration" date',
          },
        },
      },
    },
  },
};
