/* istanbul ignore file */

import React from 'react';

import SectionRow from 'shared/uibuilder/layout/SectionRow';
import { EnumField, ListField, TextField } from 'shared/uibuilder/field';
import { RELOCATION_OPTIONS } from '../../../../vacancy/shared/vacancyService';

type VacancyDetailsFieldProps = {
  value?: any;
};

export const PUBLICATION_EMPLOYEE_CONTRACT_TYPES = {
  EMPLOYMENT_CONTRACT: 'Employment contract',
  B2B_CONTRACT: 'B2B contract',
  CONTRACT_FOR_SERVICES: 'Contract for services',
  INTERNSHIP: 'Internship',
};

const PublicationDetailsField = ({ value = {} }: VacancyDetailsFieldProps) => {
  return (
    <SectionRow>
      <TextField
        label="Location"
        value={value?.location?.city ? `${value.location.city} - ${value.location.country}` : '-'}
      />
      <EnumField options={RELOCATION_OPTIONS} value={value.relocationOption} label="Relocation Options" />
      <ListField value={value.contractType} label="Contract Type">
        <EnumField options={PUBLICATION_EMPLOYEE_CONTRACT_TYPES} />
      </ListField>
    </SectionRow>
  );
};

export default PublicationDetailsField;
