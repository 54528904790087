import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { DATE_FORMAT, DAY } from 'shared/uibuilder/dateService/constants';
import { DEFAULT_TIMEZONE } from 'shared/uibuilder/dateService';
// temporary solution
import { store } from 'uibuilder/reducer/store';

const getMomentDateObj = (...props: moment.MomentInput[]) => moment(...props);

/**
 * Method for getting date as utc moment object
 * @param date - utc date in the string format
 * @returns {moment.Moment} = moment object
 */
const getMomentDateObjAsUtc = (date: moment.MomentInput) => {
  return moment.utc(date);
};

/**
 * @param date - text date
 * @param format - date format like YYYY-MM-DD
 * @returns {string|null} - text date converted to passed format
 */
const formatDate = (date: any, format: string | undefined) => {
  if (!date) return null;

  return ` ${getMomentDateObj(date).format(format)}`;
};

/**
 * @param dateFrom - text date in format 'YYYY-MM-DD HH:mm:ss'
 * @param dateTo - text date in format 'YYYY-MM-DD HH:mm:ss'
 * @returns {string|null} - string duration between two month in format `${year} year, ${month} month, ${day} days`
 */
const getStringDurationBetweenTwoDates = (dateFrom: moment.MomentInput, dateTo: moment.MomentInput) => {
  if (!dateFrom || !dateTo) {
    return null;
  }

  const start = getMomentDateObj(dateFrom, DATE_FORMAT.API).startOf('day');
  const end = getMomentDateObj(dateTo, DATE_FORMAT.API).endOf('day').add(1, 'minute');

  const years = end.diff(start, 'year');
  start.add(years, 'years');
  const months = end.diff(start, 'months');
  start.add(months, 'months');
  const days = end.diff(start, 'days');
  start.add(days, 'days');

  const durationValues = {
    years,
    months,
    days,
  };

  let result = '';
  const sign = end.isAfter(start) ? '' : '- ';
  Object.keys(durationValues).forEach(key => {
    if (durationValues[key as keyof typeof durationValues]) {
      const durationName = durationValues[key as keyof typeof durationValues] === 1 ? key.replace('s', '') : key;
      result += result.length
        ? `, ${durationValues[key as keyof typeof durationValues]} ${durationName}`
        : `${durationValues[key as keyof typeof durationValues]} ${durationName}`;
    }
  });

  return `${sign}${result}`;
};

/**
 * @param format - date format like YYYY-MM-DD, 'YYYY-MM-DD' by default
 * @returns moment.Moment - moment object with today date converted to passed format
 */
const getCurrentDate = (format = DATE_FORMAT.INPUT_VALUE) => moment.utc(moment.utc().format(format));

/**
 * @param dateTo - text date
 * @param dateFrom - text date, today date by default
 * @param format - date format like YYYY-MM-DD, 'YYYY-MM-DD' by default
 * @returns {number|null} - month diff between dates
 */
const getMonthsBetween = (
  dateTo: moment.MomentInput,
  dateFrom = getCurrentDate(),
  format = DATE_FORMAT.INPUT_VALUE,
) => {
  if (!dateTo) {
    return null;
  }

  const comparedDate = moment.utc(moment.utc(dateTo).format(format));

  return comparedDate.diff(moment.utc(moment.utc(dateFrom).format(format)), 'months', true);
};

const getDaysBetween = (dateTo: moment.MomentInput, dateFrom: moment.MomentInput, format = DATE_FORMAT.INPUT_VALUE) => {
  const comparedDate = moment.utc(moment.utc(dateTo).format(format));

  return comparedDate.diff(moment.utc(moment.utc(dateFrom).format(format)), 'days');
};

const getHoursBetween = (dateTo: moment.MomentInput, dateFrom: moment.MomentInput, format = DATE_FORMAT.API) => {
  const comparedDate = moment.utc(moment.utc(dateTo).format(format));

  return comparedDate.diff(moment.utc(moment.utc(dateFrom).format(format)), 'hours');
};

const getMinutesBetween = (dateTo: moment.MomentInput, dateFrom: moment.MomentInput, format = DATE_FORMAT.API) => {
  const comparedDate = moment.utc(moment.utc(dateTo).format(format));

  return comparedDate.diff(moment.utc(moment.utc(dateFrom).format(format)), 'minutes');
};

const getCurrentDateTime = () => moment();

/**
 * Method for getting current date in the UTC (GMT +0)
 * @returns {moment.Moment}
 */
const getCurrentDateTimeInUtc = () => moment.utc();

const getTimeDifference = (startDate: moment.MomentInput, endDate = getCurrentDateTime()) => endDate.diff(startDate);

const isCurrentTimeInPeriod = (startDate: moment.MomentInput, endDate: moment.MomentInput) =>
  moment().isBetween(startDate, endDate);

const getUserTimezone = () => (store.getState() as any)?.auth?.user?.timezone || DEFAULT_TIMEZONE;

const getToday = () => momentTimezone.tz(moment(), getUserTimezone()) || moment();

const formatDateForAPI = (dateText: moment.MomentInput) => {
  return moment(dateText).format(DATE_FORMAT.API_ONLY_DATE);
};

const getMaxDate = (dates: moment.Moment[]) => moment.max(dates);

const getMinDate = (dates: moment.Moment[]) => moment.min(dates);

const subtractDaysFromStartDate = (
  startDate: string,
  daysToSubtract: moment.DurationInputArg1,
  dateFormat = DATE_FORMAT.SIMPLE,
) => {
  const momentStartDate = getMomentDateObj(startDate);
  const newDate = momentStartDate.subtract(daysToSubtract, 'days');

  return newDate.format(dateFormat);
};

const excludeWeekends = (startDate: moment.MomentInput, endDate: moment.MomentInput): number => {
  const start = moment(startDate).startOf('day');
  const end = moment(endDate).startOf('day');
  const totalDays = end.diff(start, 'days');

  const weekends = Array.from({ length: totalDays }, (_, i) => start.clone().add(i, 'days')).filter(
    date => date.day() === 0 || date.day() === 6,
  ).length;

  return weekends * DAY;
};

export default () => ({
  formatDateForAPI,
  getMonthsBetween,
  formatDate,
  getToday,
  getCurrentDate,
  getCurrentDateTime,
  getCurrentDateTimeInUtc,
  getTimeDifference,
  getStringDurationBetweenTwoDates,
  getMaxDate,
  getMinDate,
  getMomentDateObj,
  getMomentDateObjAsUtc,
  getDaysBetween,
  getHoursBetween,
  getMinutesBetween,
  getUserTimezone,
  subtractDaysFromStartDate,
  isCurrentTimeInPeriod,
  excludeWeekends,
});
