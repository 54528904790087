import React, { useState } from 'react';
import Box from '@mui/material/Box';
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import Droppable from 'shared/dnd/Droppable';
import Typography from 'uibuilder/Typography';
import useRecruitingBoardService from '../useRecruitingBoardService';
import { CreateForm } from 'shared/uibuilder/form';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import { Stages } from '../constants';
import RejectForm from './forms/RejectForm/RejectForm';

interface RejectBoxProps {
  id: string;
  activeNode: any;
}

const RejectBox: React.FC<RejectBoxProps> = ({ id, activeNode }) => {
  const { moveStage } = useRecruitingBoardService();
  const [sourceStage, setSourceStage] = useState<string | null>(null);
  const [isModalActive, setModalActive] = useState<boolean>(false);
  const [pendingItem, setPendingItem] = useState<any | null>(null);

  const closeDialog = () => {
    setModalActive(false);
    setPendingItem(null);
    setSourceStage(null);
  };

  return (
    <>
      {activeNode && activeNode.stage !== Stages.PendingRejection ? (
        <Droppable
          params={{
            canDrop: _args => {
              // Optional custom logic for determining if drop is allowed
              return true;
            },
            onDrop: ({ self, source }) => {
              const initialColumnName = source.data.stage;
              const applicationId = source.data.id as number;
              const version = source.data.version as number;
              const vacancyId = source.data.entityId as string;

              setPendingItem({ vacancyId, applicationId, version, targetStage: Stages.PendingRejection });
              setSourceStage(initialColumnName as string);
              setModalActive(true);
            },
          }}
          data={{ id }} // Optional data to associate with the droppable area
        >
          {({ isDraggedOver, droppableRef }) => (
            <Box
              ref={droppableRef}
              sx={{
                width: '200px',
                height: '100px',
                position: 'fixed',
                bottom: 10,
                left: '92%',
                opacity: 0.7,
                transform: 'translateX(-50%)',
                zIndex: 998,
              }}
            >
              <Box
                sx={{
                  height: '100px',
                  padding: '20px 5px',
                  backgroundColor: isDraggedOver ? '#ffcdd2' : '#ffebee',
                  border: '2px dashed #f44336',
                  borderRadius: '3px',
                  textAlign: 'center',
                  transition: 'background-color 0.3s, box-shadow 0.3s',
                }}
              >
                <DoDisturbAltOutlinedIcon
                  color="error"
                  sx={{
                    fontSize: isDraggedOver ? '2.5rem' : '2rem',
                    transition: 'transform 0.3s ease, font-size 0.3s ease',
                    transform: isDraggedOver ? 'scale(1.3)' : 'scale(1)',
                  }}
                />
                <Typography color="error" sx={{ fontSize: '16px', fontWeight: 700 }}>
                  Reject
                </Typography>
              </Box>
            </Box>
          )}
        </Droppable>
      ) : null}
      {isModalActive ? (
        <ModalWindow
          isOpen
          key="modal-not-strict"
          modalSize="sm"
          backdrop="static"
          title="Additional info required"
          onToggle={closeDialog}
        >
          <CreateForm
            submitFormFunc={formData => {
              if (pendingItem) {
                return moveStage(
                  pendingItem.vacancyId,
                  pendingItem.version,
                  pendingItem.applicationId,
                  pendingItem.targetStage,
                  formData,
                );
              } else {
                return Promise.reject();
              }
            }}
            afterSubmit={{
              execute: () => {
                setPendingItem(null);
                setModalActive(false);
                setSourceStage(null);
              },
            }}
          >
            <RejectForm handleCancelClick={closeDialog} sourceStage={sourceStage as string} />
          </CreateForm>
        </ModalWindow>
      ) : null}
    </>
  );
};

export default RejectBox;
