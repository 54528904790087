/* istanbul ignore file */

import React from 'react';

import Show from 'shared/uibuilder/show/Show';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import { EnumField, HtmlField, TextField } from 'shared/uibuilder/field';
import ViewVacancyPublicationPageMenu from 'erp/recruitment/vacancy/publication/Show/ViewVacancyPublicationPageMenu';
import SingleVacancyPublicationBreadcrumbs from 'erp/recruitment/vacancy/publication/shared/SingleVacancyPublicationBreadcrumbs';
import VacancyPublicationEntityHeader from 'erp/recruitment/vacancy/publication/shared/VacancyPublicationEntityHeader';
import FieldListLayout from 'uibuilder/layout/field/FieldListLayout';
import VacancyPublicationDetailsField from 'erp/recruitment/vacancy/shared/field/VacancyPublicationDetailsField';
import VacancyPublicationField from 'erp/recruitment/vacancy/shared/field/VacancyPublicationField';
import useFeatureToggle, { Features } from 'featuretoggle';
import VacancyGroupLinkField from '../../../shared/VacancyGroupLinkField';
import useVacancyPublicationService from '../vacancyPublicationService';
import {
  VACANCY_PUBLICATION_PLATFORMS,
  VACANCY_PUBLICATION_STATUSES,
} from '../../../../vacancy/publication/shared/vacancyPublicationService';
import PublicationDetailsField from '../field/PublicationDetailsField';
import { useParams } from 'react-router-dom';
import EditVacancyPublicationButton from '../shared/button/EditVacancyPublicationButton';
import { RECRUITING_VACANCY_PUBLICATION_UPDATE } from '../../../constants';

const ViewVacancyPublication = () => {
  const { publicationId } = useParams<Dictionary<string>>();
  const { getById } = useVacancyPublicationService();
  const { isFeatureEnabled } = useFeatureToggle();

  return (
    <Show getDataMethod={() => getById(publicationId!)}>
      <ShowPageLayout
        buttons={[
          <EditVacancyPublicationButton source="id" permissionToCheck={RECRUITING_VACANCY_PUBLICATION_UPDATE} />,
        ]}
        menu={<ViewVacancyPublicationPageMenu />}
        breadcrumbs={<SingleVacancyPublicationBreadcrumbs />}
        entityHeader={<VacancyPublicationEntityHeader />}
      >
        <ShowSection title="Vacancy Publication Information">
          <SectionRow>
            <VacancyGroupLinkField source="vacancyGroupAlias" label="Vacancy Group" />
            <TextField source="name" />
            <FieldListLayout source="englishLevels" label="Relevant English Levels">
              <VacancyPublicationField />
            </FieldListLayout>
            <FieldListLayout source="countryOfResidences" label="Relevant Country Of Residences">
              <VacancyPublicationField />
            </FieldListLayout>
            <EnumField source="type" label="Publication Platform" options={VACANCY_PUBLICATION_PLATFORMS} />
            <EnumField source="status" label="Status" options={VACANCY_PUBLICATION_STATUSES} />
          </SectionRow>
        </ShowSection>
        <ShowSection
          title="Additional Vacancy Publication Fields"
          isVisible={isFeatureEnabled(Features.VACANCY_PUBLICATION)}
        >
          <FieldListLayout source="formFields" hasSeparator>
            <VacancyPublicationDetailsField />
          </FieldListLayout>
        </ShowSection>
        <ShowSection title="Public Description">
          <HtmlField source="description" label="" />
        </ShowSection>

        <ShowSection title="Vacancy Publication Details">
          <FieldListLayout source="publicationDetails" hasSeparator>
            <PublicationDetailsField />
          </FieldListLayout>
        </ShowSection>
      </ShowPageLayout>
    </Show>
  );
};

export default ViewVacancyPublication;
