import React, { useEffect, useState } from 'react';
import EqCondition, { EQ_CONDITION_TYPE } from 'shared/uibuilder/list/filter/condition/EqCondition';
import { EmployeeInput } from 'erp/employee/index';
import { Filter, FilterCondition, useFilterContext } from 'shared/uibuilder/list/filter/FilterContext';
import useEmployeeService from 'erp/employee/employeeService';
import { buildEmployeeAutoSuggestLabel } from 'erp/employee/shared/input/EmployeeInput';
import InputLoader from 'shared/uibuilder/InputLoader';
import { ConditionProps } from 'shared/uibuilder/list/filter/condition/Condition';

const setEmployeeLabel = (searchSuggestions: any, alias: any, setLabel: any, setAliasLoaded: any) => {
  (async () => {
    const response = await searchSuggestions({
      filter: {
        alias: {
          eq: alias,
        },
      },
    });
    const employee = response && response.result && !!response.result.length && response.result[0];
    const employeeLabel = buildEmployeeAutoSuggestLabel(employee);
    setLabel(employeeLabel);
    setAliasLoaded(true);
  })();
};

type EmployeeFilterProps = {
  source: string;
  label: string;
  withContractors?: boolean;
} & Omit<ConditionProps, 'children'>;

const EmployeeFilter = ({ label: filterLabel, source, withContractors = true, ...props }: EmployeeFilterProps) => {
  const { filterBy, getValue } = useFilterContext();
  const { searchSuggestions, searchWithContractors } = useEmployeeService();

  const [label, setLabel] = useState('');
  const [aliasLoaded, setAliasLoaded] = useState(false);

  const searchMethod = withContractors ? searchWithContractors : searchSuggestions;

  useEffect(() => {
    const alias = getValue(source, EQ_CONDITION_TYPE as FilterCondition);
    if (!alias) {
      setAliasLoaded(true);
    } else {
      setEmployeeLabel(searchMethod, alias, setLabel, setAliasLoaded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeCallback = (filter: Filter) => {
    filterBy(filter);
  };

  return aliasLoaded ? (
    <EqCondition {...props} source={source} onChangeCallback={onChangeCallback}>
      <EmployeeInput initialLabel={label} source={source} label={filterLabel} withContractors={withContractors} />
    </EqCondition>
  ) : (
    <InputLoader />
  );
};

export default EmployeeFilter;
