/* istanbul ignore file */
import { PopoverPosition } from '@mui/material';
import React, { useContext } from 'react';

interface ContextMenuData {
  menuPosition?: PopoverPosition | null;
  handleContextMenu?: (event: React.MouseEvent, item: any) => void;
  handleClose?: () => void;
  menuItems?: any[];
}

const defaultContext = {};

const ContextMenuContext = React.createContext<ContextMenuData>(defaultContext);

export const ContextMenuProvider = ContextMenuContext.Provider;

export const useContextMenuContext = () => useContext(ContextMenuContext);

export default ContextMenuContext;
