import React from 'react';

import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTheme } from '@mui/material/styles';

interface ExpiredNotificationProps {
  source: string;
  size: 'XS' | 'S' | 'M';
}

const sizeMap = {
  XS: { text: 9, icon: 11 },
  S: { text: 12, icon: 14 },
  M: { text: 14, icon: 16 },
};

const ExpiredNotification: React.FC<ExpiredNotificationProps> = ({ source, size }: ExpiredNotificationProps) => {
  const { getValue } = useFieldHelper({ source });
  const isVacancyInsideGroupExpired = getValue();

  const fontSize = sizeMap[size];
  const theme = useTheme();

  if (isVacancyInsideGroupExpired) {
    return (
      <span style={{ display: 'flex', alignItems: 'center', fontSize: fontSize.text, color: theme.palette.error.main }}>
        <ErrorOutlineIcon sx={{ fontSize: fontSize.icon, marginRight: '4px', color: theme.palette.error.main }} /> Some
        vacancies expired
      </span>
    );
  }

  return null;
};

export default ExpiredNotification;
