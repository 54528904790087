import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import useMessageService, { SuccessMessage } from 'shared/message/messageService';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import ModalFormButton from 'shared/uibuilder/form/ModalFormButton';
import useRecruitingReportService, { REPORT_TYPES } from '../../useReportService';
import { DateInput } from 'shared/uibuilder/form/input';

const RequestReportGenerationButton = () => {
  const { addMessage } = useMessageService();
  const { create } = useRecruitingReportService();
  return (
    <ModalFormButton
      outline
      formTitle="Request report generation"
      label="Request report generation"
      submitFormFunc={formState => create(formState)}
      afterSubmit={{
        execute: () => {
          addMessage(new SuccessMessage('The KPI parameter has been published successfully.'));
        },
      }}

      // getValidationSchemaFunc={getPublishValidationSchema}
    >
      <FormSection>
        <EnumDropdown options={REPORT_TYPES} source="reportType" />
        <DateInput source="periodFrom" />
        <DateInput source="periodTo" />
      </FormSection>
    </ModalFormButton>
  );
};

export default RequestReportGenerationButton;
