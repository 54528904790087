import { BUDGETING_READ } from '../budgetingService';
import ServiceBudgetStructureRecordLayout from './ServiceBudgetStructureRecordLayout';
import { TimelineItem } from 'shared/uibuilder/timeline/timelineHelper';
import CreateServiceBudgetStructure from './Create';
import UpdateServiceBudgetStructure from 'erp/serviceCatalog/budgets/structure/Update';

const useServiceBudgetStructureRegistry = () => ({
  AddForm: CreateServiceBudgetStructure,
  UpdateForm: UpdateServiceBudgetStructure,
  RecordLayout: ServiceBudgetStructureRecordLayout,
  isApplicable: (item: TimelineItem) => item,
  source: '',
  urlPostfix: 'structures',
  name: 'New Budget Structure',
  hasFullData: true,
  permissionToCheck: BUDGETING_READ,
});

export default useServiceBudgetStructureRegistry;
