import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';

export const RECRUITING_REPORT_PATH = '/recruiting/report';

export const useRecruitingReportPath = () => {
  const routers = useRoute({ listRoute: RECRUITING_REPORT_PATH });

  return {
    ...routers,
  };
};

export const useVacancyGroupUrl = () => {
  const urls = useEntityUrl({
    baseLink: RECRUITING_REPORT_PATH,
  });

  return {
    ...urls,
  };
};
