import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { TextField } from 'shared/uibuilder/field';
import useRecruitingReportService, { REPORT_STATUSES_ALIAS, REPORT_TYPES } from '../../useReportService';
import SmallLoader from 'shared/uibuilder/SmallLoader';

import DownloadIcon from '@mui/icons-material/Download';

const ReportLinkField = (props: any) => {
  const { getValue: getId } = useFieldHelper({ source: 'reportId' });
  const { getValue: getReportType } = useFieldHelper({ source: 'reportType' });
  const { getValue: getStatus } = useFieldHelper({ source: 'status' });
  const { downloadReport } = useRecruitingReportService();

  const [loading, setLoading] = React.useState(false);

  let icon = null;
  if (getStatus() === REPORT_STATUSES_ALIAS.GENERATED && !loading) {
    icon = (
      <DownloadIcon
        onClick={() => {
          setLoading(true);
          downloadReport(getId()).then(() => setLoading(false));
        }}
        sx={{
          'font-size': '15px',
          cursor: 'pointer',
        }}
      />
    );
  } else if (loading) {
    icon = <SmallLoader />;
  }

  const value = (
    <>
      {REPORT_TYPES[getReportType()]}
      {icon}
    </>
  );

  return <TextField value={value} />;
};

export default ReportLinkField;
