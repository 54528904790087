/* istanbul ignore file */
// All logic is located in helpers
import React, { useMemo } from 'react';
import { ListProvider } from './ListContext';
import {
  useListCrud,
  useListParams,
  useListDataLoading,
  useListUrlStoring,
  useListLocalStorageStoring,
  useListDataUpdating,
  useListParamsConcatenation,
} from 'shared/uibuilder/list/builder';
import useTimelinePaging from '../timeline/paging/timelinePagingHelper';
import getLoadingParams from './builder/listBuilderHelper';
import { ListProps } from './List';

export const LIST_PAGE_SIZE = 30;

const ListWithInfiniteScroll = ({
  getDataMethod,
  children,
  localStorageKey,
  uncontrolledFilters,
  pageSize = LIST_PAGE_SIZE,
  defaultSortOrder = '',
  defaultSortField = '',
  defaultFilter = {},
  onRemove = () => {},
  shouldSaveUncontrolledFilters,
  autoUpdateTime,
}: ListProps) => {
  const { params: urlParams, updateParamsInUrl } = useListUrlStoring();
  const { params: storageParams, updateParamsInLocalStorage } = useListLocalStorageStoring({
    localStorageKey,
    uncontrolledFilters,
    defaultFilter,
    shouldSaveUncontrolledFilters,
  });
  const { params: initialParams } = useListParamsConcatenation({ urlParams, localStorageParams: storageParams });
  const { params, sortBy, searchBy, filterBy, goToPage, resetParamsToDefault } = useListParams({
    initialParams,
    defaultSortOrder,
    defaultSortField,
    defaultFilter,
    onParamsChangeCallbacks: [updateParamsInUrl, updateParamsInLocalStorage],
  });

  const { loading, loadData, setLoading } = useListDataLoading({ getDataMethod, resetParamsToDefault });

  const { data, reloadData } = useListDataUpdating({ loadData, params, pageSize, autoUpdateTime });
  const listCrud = useListCrud({ onRemove, inputData: data });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadingParams = useMemo(() => getLoadingParams(params, pageSize), [params.filter, params.sort, params.search]);

  const pagingMethods = useTimelinePaging({
    currentPage: params.page || 0,
    loadData: (pageNumber: number) => loadData({ ...loadingParams, pageNumber }, false),
    setData: listCrud.setData,
    data: listCrud.data,
  });

  const contextData = {
    ...params,
    filterBy,
    sortBy,
    goToPage,
    searchBy,
    loading,
    setLoading,
    reloadData,
    uncontrolledFilters,
    ...listCrud,
    ...pagingMethods,
  };

  return <ListProvider value={contextData}>{children}</ListProvider>;
};

export default ListWithInfiniteScroll;
