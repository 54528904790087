/* istanbul ignore file */
import React from 'react';
import useVacancyGroupService from 'erp/recruitment/newVacancy/useVacancyGroupService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { InputList } from 'shared/uibuilder/form/input';
import TextField from 'shared/uibuilder/field/TextField';
import { EmployeeInput } from 'erp/employee';
import ModalForm from 'shared/uibuilder/form/ModalForm';
import FormSection from 'shared/layout/form/FormSection';
import BigFormRow from 'shared/layout/form/BigFormRow';
import ApproveVacancyCompensationsInputsListWrapper from '../inputs/ApproveVacancyCompensationsInputsListWrapper';
import ApproveVacancyPositionsInputsListWrapper from '../inputs/ApproveVacancyPositionsInputsListWrapper';
import approveVacancyFormValidationSchema from './approveVacancyFormValidationSchema';

interface ApproveVacancyFormProps {
  closeModal: () => void;
  modalOpen: boolean;
  setGeneralContextData: () => void;
}

const ApproveVacancyForm = ({ closeModal, modalOpen, setGeneralContextData }: ApproveVacancyFormProps) => {
  const { approveVacancy } = useVacancyGroupService();

  const { data } = useShowContext();
  const { workingConditions, positions, id } = data.getData();

  return (
    <ModalForm
      getDataFunc={async () => ({ id, positions, workingConditions })}
      closeModal={closeModal}
      modalOpen={modalOpen}
      getValidationSchemaFunc={() => Promise.resolve(approveVacancyFormValidationSchema)}
      submitFormFunc={formFieldsData => approveVacancy(formFieldsData)}
      afterSubmit={{
        execute: () => {
          if (setGeneralContextData) {
            setGeneralContextData();
          }
        },
      }}
    >
      <FormSection>
        <TextField source="" label="" value="Compensations" />
        <BigFormRow>
          <InputList
            maxFields={100}
            isAddable={false}
            isDeletable={false}
            minFields={data.workingConditions.length}
            source="workingConditions"
            inputTemplate={ApproveVacancyCompensationsInputsListWrapper}
          />
        </BigFormRow>
        <EmployeeInput source="responsiblePerson" label="DRI" nameSource="employeeName" isRequired />
        <BigFormRow>
          <InputList
            maxFields={100}
            isAddable={false}
            isDeletable={false}
            minFields={data.positions.length}
            source="positions"
            inputTemplate={ApproveVacancyPositionsInputsListWrapper}
          />
        </BigFormRow>
      </FormSection>
    </ModalForm>
  );
};

export default ApproveVacancyForm;
