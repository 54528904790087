import React, { useState, useCallback } from 'react';
import Box from 'uibuilder/Box';
import { MenuItem, Tab, Tabs, Tooltip } from '@mui/material';
import { SxProps } from '@mui/system';
import DropdownMenu from '../menu/DropdownMenu';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type TabMenuItemType = {
  label: string;
  isVisible?: boolean;
  disabled?: boolean;
  disabledReason?: string;
  menuAction: () => void;
};

type TabItem = {
  index: number;
  label: string;
  content: React.ReactNode;
  menuIcon?: React.ReactNode;
  menuItems?: TabMenuItemType[];
  sxTab?: SxProps;
};

type TabsWrapperProps = {
  tabs: TabItem[];
  sxContentProps?: SxProps;
  sxTab?: SxProps;
  buttons?: React.ReactNode[];
  changeTabCallback?: (index: number) => void;
};

const CustomTab: React.FC<TabItem> = ({ label, menuItems, sxTab, ...props }) => {
  const visibleMenuItems = menuItems?.filter(item => item.isVisible !== false);

  return (
    <Box>
      <Tab component="div" sx={{ textTransform: 'none', ...sxTab }} label={label} key={label} {...(props as any)} />
      {visibleMenuItems?.length ? (
        <DropdownMenu
          popupId={label}
          iconButton={
            <IconButton size="small">
              <KeyboardArrowDownIcon />
            </IconButton>
          }
        >
          {visibleMenuItems.map(item => {
            const menuItem = (<MenuItem
              key={`${item.label}-${label}`}
              disabled={item.disabled}
              onClick={item.menuAction}>
              {item.label}
            </MenuItem>);

            if (item.disabled && item.disabledReason) {
              return <Tooltip title={item.disabledReason}><span>{menuItem}</span></Tooltip>;
            } else {
              return menuItem;
            }
          })}
        </DropdownMenu>
      ) : null}
    </Box>
  );
};

const TabsWrapper: React.FC<TabsWrapperProps> = ({ tabs, sxTab, sxContentProps, buttons = [], changeTabCallback }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = useCallback(
    (_: React.SyntheticEvent, newValue: number) => {
      setActiveTab(newValue);
      changeTabCallback?.(newValue);
    },
    [changeTabCallback],
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        sx={{
          flexWrap: 'wrap',
          overflow: 'visible',
          '> .MuiTabs-fixed': {
            overflow: 'visible !important',
          },
        }}
        value={activeTab}
        onChange={handleTabChange}
        aria-label="TabsWrapper"
      >
        {tabs.map(tab => (
          <CustomTab key={tab.index} sxTab={sxTab} {...tab} />
        ))}
        {buttons}
      </Tabs>

      <Box sx={sxContentProps || { padding: 2 }}>
        {tabs.map(tab =>
          tab.index === activeTab ? (
            <Box key={tab.index} sx={{ paddingTop: 2 }}>
              {tab.content}
            </Box>
          ) : null,
        )}
      </Box>
    </Box>
  );
};

export default TabsWrapper;
