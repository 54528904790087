import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const DockIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.87484 1.08333C8.87484 1.02808 8.85289 0.97509 8.81382 0.936019C8.77475 0.896949 8.72176 0.875 8.6665 0.875H2.83317C2.22538 0.875 1.64249 1.11644 1.21272 1.54621C0.782947 1.97598 0.541504 2.55888 0.541504 3.16667V14.8333C0.541504 15.4411 0.782947 16.024 1.21272 16.4538C1.64249 16.8836 2.22538 17.125 2.83317 17.125H11.1665C11.7743 17.125 12.3572 16.8836 12.787 16.4538C13.2167 16.024 13.4582 15.4411 13.4582 14.8333V6.6225C13.4582 6.56725 13.4362 6.51426 13.3972 6.47519C13.3581 6.43612 13.3051 6.41417 13.2498 6.41417H9.49984C9.33408 6.41417 9.17511 6.34832 9.0579 6.23111C8.94069 6.1139 8.87484 5.95493 8.87484 5.78917V1.08333ZM9.49984 9.20833C9.6656 9.20833 9.82457 9.27418 9.94178 9.39139C10.059 9.5086 10.1248 9.66757 10.1248 9.83333C10.1248 9.99909 10.059 10.1581 9.94178 10.2753C9.82457 10.3925 9.6656 10.4583 9.49984 10.4583H4.49984C4.33408 10.4583 4.17511 10.3925 4.0579 10.2753C3.94069 10.1581 3.87484 9.99909 3.87484 9.83333C3.87484 9.66757 3.94069 9.5086 4.0579 9.39139C4.17511 9.27418 4.33408 9.20833 4.49984 9.20833H9.49984ZM9.49984 12.5417C9.6656 12.5417 9.82457 12.6075 9.94178 12.7247C10.059 12.8419 10.1248 13.0009 10.1248 13.1667C10.1248 13.3324 10.059 13.4914 9.94178 13.6086C9.82457 13.7258 9.6656 13.7917 9.49984 13.7917H4.49984C4.33408 13.7917 4.17511 13.7258 4.0579 13.6086C3.94069 13.4914 3.87484 13.3324 3.87484 13.1667C3.87484 13.0009 3.94069 12.8419 4.0579 12.7247C4.17511 12.6075 4.33408 12.5417 4.49984 12.5417H9.49984Z"
          fill="currentColor"
        />
        <path
          d="M10.125 1.35322C10.125 1.19988 10.2858 1.10238 10.405 1.19822C10.5061 1.27988 10.5958 1.37488 10.6742 1.48322L13.185 4.98072C13.2417 5.06072 13.18 5.16405 13.0817 5.16405H10.3333C10.2781 5.16405 10.2251 5.1421 10.186 5.10303C10.1469 5.06396 10.125 5.01097 10.125 4.95572V1.35322Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default DockIcon;
