import React from 'react';
import { SearchInput, ValidationProps } from 'shared/uibuilder/form/input';
import useEmployeeService from 'erp/employee/employeeService';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';

export const buildEmployeeAutoSuggestLabel = (employee: any) =>
  !!employee && `${employee.alias} ${employee.nameEn.firstName} ${employee.nameEn.lastName}`;

export interface EmployeeInputProps extends ValidationProps {
  source: string;
  label?: string;
  nameSource?: string;
  mapResults?: (employee: any) => void;
  defaultFilter?: Dictionary<object>;
  isVisible?: boolean | ((data: FormFieldsData) => boolean);
  disabled?: boolean | ((data: FormFieldsData) => boolean);
  props?: any;
  withContractors?: boolean;
  initialLabel?: string;
  onChangeCallback?: (values: FormFieldsData) => void;
  onInputChangeCallback?: (query: any) => void;
  valueDecorator?: (searchValue: string) => string;
  searchRequest?: (filter: any) => any;
}

const EmployeeInput = ({ mapResults, withContractors = true, ...props }: EmployeeInputProps) => {
  const { searchSuggestions, searchWithContractors } = useEmployeeService();

  const mapResponse = (employee: any) => ({
    id: employee.alias,
    text: buildEmployeeAutoSuggestLabel(employee),
  });

  const searchMethod = withContractors ? searchWithContractors : searchSuggestions;

  return (
    <SearchInput
      searchRequest={searchMethod}
      noResultMessage="No Employees are found."
      mapResults={mapResults || mapResponse}
      {...props}
    />
  );
};

export default EmployeeInput;
