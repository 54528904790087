/* istanbul ignore file */
import React from 'react';
import useCandidateService, { CandidateDuplicate } from 'erp/candidate/shared/candidateService';
import { Button } from 'uibuilder/button';

interface ReportWrongDuplicateResultButtonProps {
  duplicate: CandidateDuplicate;
  removeByCandidateIdFunc: (candidateId: any) => void;
}

const ReportWrongDuplicateResultButton = ({
  duplicate,
  removeByCandidateIdFunc,
}: ReportWrongDuplicateResultButtonProps) => {
  const { reportWrongDuplicateResult } = useCandidateService();

  const onClick = () => {
    reportWrongDuplicateResult(duplicate).then(data => removeByCandidateIdFunc(data.candidateId));
  };

  return (
    <Button
      outline
      onClick={onClick}
      sx={{
        margin: '4px 0',
        padding: '4px 8px',
        fontSize: '12px',
        textTransform: 'none',
        width: 'auto',
        alignSelf: 'flex-end',
      }}
    >
      Report wrong result
    </Button>
  );
};

export default ReportWrongDuplicateResultButton;
