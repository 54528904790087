import React from 'react';
import useAuthorization from 'shared/authorization/authorizationService';
import { RECRUITING_VACANCY_CLOSE_VACANCY, VACANCY_STATUS } from 'erp/recruitment/newVacancy/constants';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import useVacancyGroupService from 'erp/recruitment/newVacancy/useVacancyGroupService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { RECRUITING_CONTEXT } from '../../../RecruitingContext';
import { useVacancyContext } from 'erp/recruitment/newVacancy/VacancyContext';

const CloseVacancyButton = ({ setGeneralContextData }: { setGeneralContextData: () => {} }) => {
  const { isGranted } = useAuthorization();
  const { closeVacancy } = useVacancyGroupService();
  const { areVacanciesUpdated, setAreVacanciesUpdated } = useVacancyContext();

  const { data } = useShowContext();
  const { id, status, areAllHired } = data.getData();

  if (!(VACANCY_STATUS.EXPIRED === status || (VACANCY_STATUS.OPEN === status && areAllHired))) {
    return null;
  }

  return isGranted(RECRUITING_VACANCY_CLOSE_VACANCY, RECRUITING_CONTEXT) ? (
    <ButtonWithConfirmation
      displayMessage="Do you really want to close this vacancy, maybe some candidates not finished probation?"
      submitMethod={() => closeVacancy(id!)}
      afterSubmit={{
        successMessage: 'The Vacancy has been successfully closed.',
        errorMessage: "Can't close vacancy.",
        execute: () => {
          if (setGeneralContextData) {
            setGeneralContextData();
          }

          if (setAreVacanciesUpdated) {
            setAreVacanciesUpdated(!areVacanciesUpdated);
          }
        },
      }}
      permissionToCheck={null}
    >
      Close
    </ButtonWithConfirmation>
  ) : null;
};

export default CloseVacancyButton;
