/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import FormSection from 'shared/layout/form/FormSection';
import VacancyWysiwygInput from 'erp/recruitment/vacancy/shared/input/VacancyWysiwygInput';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import FormRow from 'shared/layout/form/FormRow';
import Loading from 'shared/uibuilder/Loading';
import { TextInput } from 'shared/uibuilder/form/input';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import AdditionalVacancyQuestionList from './AdditionalVacancyQuestionList';
import BigFormRow from 'shared/layout/form/BigFormRow';
import AdditionalVacancyQuestion from './AdditionalVacancyQuestion';
import { COUNTRIES } from 'shared/uibuilder/form/input/CountryDropdown';
import CountyOfResidencesEnumDropdown from 'shared/uibuilder/form/input/CountyOfResidencesEnumDropdown';
import useVacancyPublicationService, {
  PUBLICATION_PLATFORM,
  PUBLICATION_STATUS,
} from 'erp/recruitment/newVacancy/show/publications/vacancyPublicationService';
import MinimalAndBetterEnglishLevelEnumDropdown from 'erp/recruitment/newVacancy/show/publications/shared/input/MinimalAndBetterEnglishLevelEnumDropdown';
import { useVacancyGroupId } from 'erp/recruitment/newVacancy/routing';
import { EMPLOYEE_CONTRACT_TYPES } from 'erp/recruitment/newVacancy/constants';
import OfficeDropdown from 'erp/employee/shared/input/OfficeDropdown';
import { RELOCATION_OPTIONS } from 'erp/recruitment/vacancy/shared/vacancyService';

interface VacancyPublicationFormProps {
  isCreateForm?: boolean;
}

export const COUNTRY_OF_RESIDENCE = COUNTRIES.filter(country => country.name !== 'Unknown').reduce(
  (prev, curr) => ({ ...prev, [curr.name.toUpperCase()]: curr.name }),
  {},
);

export const ALL_DROP_DOWN_COUNTRIES = {
  ALL: 'All',
  EU: 'EU',
  ...COUNTRY_OF_RESIDENCE,
};

export const ENGLISH_LEVEL_OPTIONS = {
  BEGINNER: 'Beginner',
  ELEMENTARY: 'Elementary',
  INTERMEDIATE: 'Intermediate',
  'UPPER INTERMEDIATE': 'Upper Intermediate',
  ADVANCED: 'Advanced',
  PROFICIENCY: 'Proficiency',
};

const VacancyPublicationForm = ({ isCreateForm = false }: VacancyPublicationFormProps) => {
  const { onChangeCallback } = useFormContext();
  const vacancyId = useVacancyGroupId();
  const { constructVacancyPublicationDescription } = useVacancyPublicationService();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (onChangeCallback && vacancyId && isCreateForm) {
        const vacancyDescription = await constructVacancyPublicationDescription(vacancyId);
        onChangeCallback({ ...vacancyDescription }, true);
        setLoading(false);
      } else {
        setLoading(false);
      }
    })();
  }, [vacancyId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <FormSection title="Vacancy Publication">
        <FormRow>
          <EnumDropdown
            source="type"
            label="Publication Platform"
            options={PUBLICATION_PLATFORM}
            placeholder="Choose platform"
          />
          <EnumDropdown source="status" label="Status" options={PUBLICATION_STATUS} placeholder="Choose Status" />
        </FormRow>
        <FormRow>
          <MinimalAndBetterEnglishLevelEnumDropdown
            source="englishLevels"
            label="Relevant English Levels"
            options={ENGLISH_LEVEL_OPTIONS}
            multiple
            placeholder="Choose minimal relevant english level"
            helpText="Choose minimal relevant english level and higher levels will be selected automatically.
            To remove the level - start from the lowest"
          />
          <CountyOfResidencesEnumDropdown
            source="countryOfResidences"
            label="Relevant Country Of Residence"
            options={ALL_DROP_DOWN_COUNTRIES}
            multiple
            placeholder="Choose relevant country"
          />
        </FormRow>
        <FormRow>
          <OfficeDropdown isAnywhereAvailable source="details.location" label="Location of registration" />
          <EnumDropdown
            source="details.contractType"
            label="Contract type"
            options={EMPLOYEE_CONTRACT_TYPES}
            multiple
            placeholder="Choose relevant contract type"
          />
          <EnumDropdown
            source="details.relocationOption"
            label="Relocation options"
            options={RELOCATION_OPTIONS}
            placeholder="Choose relevant relocation options"
          />
        </FormRow>
        <BigFormRow>
          <AdditionalVacancyQuestionList
            source="formFields"
            inputTemplate={AdditionalVacancyQuestion}
            addText="Add question"
          />
        </BigFormRow>
        {isCreateForm && loading ? (
          <Loading />
        ) : (
          <>
            <MediumFormRow>
              <TextInput source="name" />
            </MediumFormRow>
            <VacancyWysiwygInput source="description" label="Public Description" />
          </>
        )}
      </FormSection>
    </>
  );
};

export default VacancyPublicationForm;
