import React from 'react';
import Box from 'uibuilder/Box';
import Typography from 'uibuilder/Typography';
import { columnNames } from '../constants';
import { countItemsWithStatus } from './board/utils';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { DATE_FORMAT, useDateService } from 'shared/uibuilder/dateService';
import Tooltip from 'uibuilder/Tooltip';
import InfoIcon from '@mui/icons-material/InfoOutlined';

interface HeaderProps {
  data: any[];
}

const Header: React.FC<HeaderProps> = ({ data }) => {
  const { formatDate } = useDateService();
  const { data: { lastUpdate } = {} } = useListContext();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px 0',
          backgroundColor: '#f5f5f5',

          position: 'sticky',
          left: 0,
        }}
      >
        <Tooltip id="last-update-board" title="Last synchronized" arrow placement="bottom">
          <InfoIcon sx={{ color: (theme: any) => theme.vars.palette.neutral[50], fontSize: '15px', mr: '5px' }} />
        </Tooltip>

        <Typography sx={{ color: (theme: any) => theme.vars.palette.neutral[50] }}>
          {formatDate(lastUpdate, DATE_FORMAT.DAY_TIME)}
        </Typography>
      </Box>
      {columnNames.map(({ name, stage }) => {
        const itemsCount = countItemsWithStatus(data, stage);

        return (
          <Box
            key={`header-${name}`}
            sx={{
              borderBottom: '1px solid black',
              display: 'flex',
              justifyContent: 'center',
              padding: '15px 0',
              backgroundColor: '#f5f5f5',
            }}
          >
            <Typography sx={{ color: (theme: any) => theme.vars.palette.neutral[50] }}>
              {`${name} (${itemsCount})`}
            </Typography>
          </Box>
        );
      })}
    </>
  );
};

export default Header;
