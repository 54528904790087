import { useCallback } from 'react';
import { useFinanceApi, useRecruitmentApi } from 'api';
import useCrudService from 'shared/crud';
import { ResourceData } from 'shared/crud/baseCrudService';
import { cloneDeep } from 'lodash';
import { MoveToStages, Stages } from './constants';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import useDateTimeService from 'shared/uibuilder/dateService/useDateTimeService';
import { Application, MappedApplication, MappedVacancyBoard, VacancyItem } from './types';
import { useDateService } from 'shared/uibuilder/dateService';
import useMessageService, { ErrorMessage } from '../../../shared/message/messageService';
import useVacancyGroupService from 'erp/recruitment/newVacancy/useVacancyGroupService';

const RECRUITING_POOLS_PATH = '/recruiting-dashboard';
export const REJECT_BOX_ID = 'reject-box';
const FINANCE_FIELDS_MAPPING = {
  compensationFromValue: 'expectedCompensationFromId',
  compensationToValue: 'expectedCompensationToId',
  offerSum: 'offerSumId',
};

export const VACANCY_GROUP_STATUSES = {
  REQUIRES_REVIEW: 'Requires review',
  OPEN: 'Open',
  CLOSED: 'Closed',
};

const useRecruitingBoardService = () => {
  const { sendPostRequest, sendPatchRequest, sendGetRequest } = useRecruitmentApi();
  const { sendPostRequest: uploadFinanceValue } = useFinanceApi();
  const { changePriority } = useVacancyGroupService();
  const { addMessage } = useMessageService();
  const { data: { items = [] } = {}, setData = () => {} } = useListContext();
  const { getDateInCurrentTimezone } = useDateTimeService();
  const { getCurrentDateTime } = useDateService();

  const {
    search: baseSearch,
    update: baseUpdate,
    create: baseCreate,
    ...baseCrud
  } = useCrudService({
    singleResourceUrl: `${RECRUITING_POOLS_PATH}/:id`,
    listResourceUrl: RECRUITING_POOLS_PATH,
    apiService: useRecruitmentApi,
  });

  const applicationMap = useCallback(
    (entityId: string, application: Application): MappedApplication => ({
      id: application.id,
      version: application.version,
      entityId,
      candidateId: application.candidateId,
      name: application.candidateName,
      vacancyId: application.vacancyId,
      eventTimestamp: getDateInCurrentTimezone(application.applicationPipelineState.stageTransitionTimestamp),
      stage: application.applicationPipelineState.stage,
      tooltipData: application.applicationPipelineState.info,
      undoStates: application.applicationPipelineState.undoStates,
      activeStates: application.applicationPipelineState.jobsStates,
      canDrag: true,
    }),
    [getDateInCurrentTimezone],
  );

  const mapApplicationsToBoard = useCallback(
    (data: VacancyItem[]): MappedVacancyBoard[] =>
      data.map((item: VacancyItem) => ({
        id: item.id,
        alias: item.alias,
        name: item.level && item.level !== 'N_A' ? `${item.level} ${item.specialization}` : item.specialization,
        hired: item.hired,
        isLegacy: item.isLegacy,
        priority: item.priority,
        needHire: item.needHire,
        forecastDate: item.forecastDate,
        isVacancyInsideGroupExpired: item.isVacancyInsideGroupExpired,
        items: item.applications.map(application => applicationMap(item.id, application)),
        specificNote: item.specificNote ? item.specificNote : 'Without specific',
        status: item.status,
      })),
    [applicationMap],
  );

  const updateLocalState = useCallback(
    (updatedApplication: MappedApplication, moveVacancyGroupAction?: boolean) => {
      setData((prev: any) => ({
        ...prev,
        items: prev.items.map((entity: MappedVacancyBoard) => {
          if (entity.id === updatedApplication.entityId) {
            return {
              ...entity,
              items: moveVacancyGroupAction
                ? [...entity.items, updatedApplication]
                : entity.items.map(item => (item.id === updatedApplication.id ? updatedApplication : item)),
            };
          }

          return entity;
        }),
      }));
    },
    [setData],
  );

  const removeApplicationFromState = useCallback(
    (removedApplication: MappedVacancyBoard, application: MappedApplication) => {
      setData((prev: any) => ({
        ...prev,
        items: prev.items.map((entity: MappedVacancyBoard) => {
          if (entity.id === removedApplication.id) {
            const findRemovedApplication = entity.items.find(item => item.candidateId === application.candidateId);

            return {
              ...entity,
              items: entity.items.filter(item => item.id !== findRemovedApplication?.id),
            };
          }

          return entity;
        }),
      }));
    },
    [setData],
  );

  const moveStageAction = useCallback(
    (vacancyId: string, version: number, applicationId: number, stage: string) => {
      const targetEntity = items.find(entity => entity.id === vacancyId);
      if (!targetEntity) return null;

      const prevState = targetEntity.items.find((item: MappedApplication) => item.id === applicationId);

      return {
        newState: {
          ...prevState,
          eventTimestamp: getCurrentDateTime().toISOString(),
          stage,
          canDrag: false,
        },
        prevState,
      };
    },
    [items, getCurrentDateTime],
  );

  const moveStage = useCallback(
    async (vacancyId: string, version: number, applicationId: number, stage: string, payload: any = null) => {
      const updatedEntity = moveStageAction(vacancyId, version, applicationId, stage);
      if (!updatedEntity) return null;

      const { newState, prevState } = updatedEntity;

      updateLocalState(newState);

      try {
        const res = await sendPostRequest(`/candidates/applications/${newState.id}/pipeline/move`, {
          targetStage: MoveToStages[newState.stage as Stages],
          version,
          payload,
        });
        const resJson = await res.json();
        updateLocalState(applicationMap(newState.entityId, resJson));
        return resJson;
      } catch (error) {
        updateLocalState(prevState);
        return error;
      }
    },
    [sendPostRequest, applicationMap, moveStageAction, updateLocalState],
  );

  const moveVacancyGroupAction = useCallback(
    (application: MappedApplication, applicationId: string) => {
      const targetEntity = items.find(entity => entity.id === applicationId);
      if (!targetEntity) return null;

      const newApplicationState = {
        ...application,
        entityId: targetEntity.id,
        eventTimestamp: getCurrentDateTime().toISOString(),
        canDrag: false,
      };

      return {
        newState: newApplicationState,
        prevState: application,
      };
    },
    [items, getCurrentDateTime],
  );

  const moveVacancyGroup = useCallback(
    async (application: MappedApplication, vacancyGroupId: string, vacancyGroupAlias: string, payload: any = null) => {
      const updatedEntity = moveVacancyGroupAction(application, vacancyGroupId);
      const previousEntity = items.find(entity => entity.items.find((item: any) => item.id === application.id));

      if (!updatedEntity || !previousEntity) return null;

      const { newState, prevState } = updatedEntity;

      updateLocalState(newState, true);
      removeApplicationFromState(previousEntity, application);

      try {
        const res = await sendPatchRequest(`/candidates/applications/${application.id}/vacancy-group/change`, {
          version: application.version,
          vacancyGroupAlias,
          payload,
        });
        const resJson = await res.json();
        updateLocalState(applicationMap(newState.entityId, resJson));
        return resJson;
      } catch (error) {
        updateLocalState(prevState);
        updateLocalState(previousEntity);
        return error;
      }
    },
    [moveVacancyGroupAction, items, updateLocalState, removeApplicationFromState, sendPatchRequest, applicationMap],
  );

  const completeJobStep = useCallback(
    async (applicationId: string, version: number, job: string, event: string, payload: any) => {
      try {
        if (payload?.resumeId?.length) {
          // workaround to correctly pass single artifact
          // eslint-disable-next-line no-param-reassign, prefer-destructuring
          payload.resumeId = payload.resumeId[0];
        }
        if (payload?.screeningMaterialsId) {
          // same as above (workaround to correctly pass single artifact)
          // eslint-disable-next-line no-param-reassign, prefer-destructuring
          payload.screeningMaterialsId = payload.screeningMaterialsId[0];
        }
        const resultPayload = await handleFinanceInfo(payload);
        const res = await sendPostRequest(`/candidates/applications/${applicationId}/pipeline/jobs/complete`, {
          event,
          version,
          jobName: job,
          payload: resultPayload,
        });
        const resJson = await res.json();
        return resJson;
      } catch (error) {
        addMessage(new ErrorMessage('Error during job completion'));
        return error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sendPostRequest, addMessage],
  );

  const handleFinanceInfo = async (payload: any) => {
    if (!payload) return null;

    const hasFinanceFields = Object.keys(FINANCE_FIELDS_MAPPING).some(field => field in payload);

    if (!hasFinanceFields) return payload;

    return Object.entries(FINANCE_FIELDS_MAPPING).reduce(async (accPromise, [fieldName, idField]) => {
      const acc = await accPromise;

      if (!(fieldName in acc)) return acc;

      const response = await uploadFinanceValue('/recruiting-finance', {
        value: acc[fieldName],
      });
      const { id } = await response.json();

      const { [fieldName]: _, ...rest } = acc;
      return {
        ...rest,
        [idField]: id,
      };
    }, Promise.resolve(payload));
  };

  const updatePriority = useCallback(
    async (vacancyId: string, priority: string) => {
      const updatedVacancy = await changePriority(vacancyId, priority);
      setData((prev: any) => ({
        ...prev,
        items: prev.items.map((entity: MappedVacancyBoard) =>
          entity.id === vacancyId
            ? {
                ...entity,
                priority,
              }
            : entity,
        ),
      }));

      return updatedVacancy;
    },
    [setData, changePriority],
  );

  const search = useCallback(
    async (request: ResourceData) => {
      try {
        const params = cloneDeep(request);
        const searchString = params.filter?.['specification:search']?.eq || '';

        if (searchString && params.filter) {
          delete params.filter?.['specification:search'];
          params.filter['specification:vacancyGroupBySpecialization'] = {
            ct: searchString,
          };
        }

        const response = await baseSearch({
          ...params,
          pageSize: 200,
        });
        return {
          ...response,
          result: mapApplicationsToBoard(response.result as VacancyItem[]),
        };
      } catch (error) {
        return error;
      }
    },
    [baseSearch, mapApplicationsToBoard],
  );

  const getCandidateDetailsById = async (candidateId: StringOrNumber) => {
    const response = await sendGetRequest(`${RECRUITING_POOLS_PATH}/candidates/${candidateId}?notesSize=2`);
    return response.json();
  };

  return {
    search,
    completeJobStep,
    moveStage,
    updatePriority,
    moveVacancyGroup,
    getCandidateDetailsById,
    ...baseCrud,
  };
};

export default useRecruitingBoardService;
