import React from 'react';
import Badge from 'shared/uibuilder/badge/Badge';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { POSITION_STATUS } from 'erp/recruitment/newVacancy/constants';

const LabelCandidateRejected = () => {
  const { data } = useShowContext();
  const { status, isCandidateRejected } = data.getData();

  if (status !== POSITION_STATUS.CLOSED) {
    return null;
  }

  if (!isCandidateRejected) {
    return null;
  }

  return (
    <Badge
      sx={{
        marginLeft: '0',
        right: 0,
        paddingX: '5px',
        height: 25,
        color: 'white',
        fontSize: '12px',
      }}
      color="error"
    >
      Candidate Rejected
    </Badge>
  );
};

export default LabelCandidateRejected;
