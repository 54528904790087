/* istanbul ignore file */
import React from 'react';
import Button from 'uibuilder/button/Button';
import { SubmitButton } from 'uibuilder/button';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import DomainInput from 'erp/employee/domains/domain/field/DomainInput';
import NumberInput from 'shared/uibuilder/form/input/NumberInput';
import InputTooltip from 'uibuilder/layout/form/input/InputTooltip';

type EmployeeDomainFormProps = {
  handleCancelClick: () => void;
};

const EmployeeDomainForm = ({ handleCancelClick }: EmployeeDomainFormProps) => {
  const { submitForm, isSubmitEnabled, isLoadingArtifact } = useFormContext();

  return (
    <>
      <DomainInput
        isRequired
        errorSource="domain"
        source="domainId"
        placeholder="Choose Domain area"
        label="Domain area"
      />
      <NumberInput
        isRequired
        errorSource="assignmentMonths"
        source="assignmentMonths"
        label={<>
          Assignment months
          <InputTooltip source="assignmentMonths"
                        message="Enter maximum number of consecutive months working with more than 50% allocation within last 3 years" />
        </>}
      />
      <div className="btn-area">
        <SubmitButton
          key="submit-modal-btn"
          onClick={submitForm}
          isLoading={!isSubmitEnabled}
          disabled={isLoadingArtifact}
        />
        <Button
          outline
          key="cancel-modal-btn"
          onClick={handleCancelClick}
          disabled={!isSubmitEnabled || isLoadingArtifact}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default EmployeeDomainForm;
