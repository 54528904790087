import React from 'react';

const getErrorDescription = (info: React.ErrorInfo) => {
  const currentLocation = window.location.pathname + window.location.search || 'unknown';
  const online = window.navigator.onLine || 'unknown';
  const timestamp = new Date().toISOString() || 'unknown';
  const userAgent = window.navigator.userAgent || 'unknown';
  const componentStackTrace = info.componentStack?.trim()?.replace('\n', '<br>') || 'unknown';

  return `</br></br></br></br></br></br></br></br></br>Location: ${currentLocation}</br>Online: ${online}</br>Time: ${timestamp}</br>User-agent: ${userAgent}</br>Component stack trace:</br>${componentStackTrace}`.slice(
    0,
    32000,
  );
};

export default getErrorDescription;
