/* istanbul ignore file */
import React, { useEffect, useMemo, useState } from 'react';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useParams } from 'react-router-dom';
import { useVacancyPublicationUrl } from 'erp/recruitment/vacancy/publication/VacancyPublicationRouter';
import VacancyPublicationBreadcrumbs from 'erp/recruitment/vacancy/publication/shared/VacancyPublicationBreadcrumbs';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useFormContext } from 'shared/uibuilder/form/FormContext';

const SingleVacancyPublicationBreadcrumbs = ({ children }: any) => {
  const { getSingleEntityUrl } = useVacancyPublicationUrl();
  // @ts-ignore
  const { id } = useParams<Dictionary<string>>();
  const { data } = useShowContext();
  const { data: formData } = useFormContext();
  const [initialEntityName, setInitialEntityName] = useState<string | null>(null);

  useEffect(() => {
    if (!initialEntityName && formData?.name) {
      setInitialEntityName(formData.name);
    }
  }, [formData?.name, initialEntityName]);

  const entity = useMemo(() => {
    return initialEntityName || data.getValueBySource('name');
  }, [data, initialEntityName]);

  return (
    <VacancyPublicationBreadcrumbs>
      <SingleBreadcrumbItem entity={entity} link={getSingleEntityUrl(id)} />
      {children}
    </VacancyPublicationBreadcrumbs>
  );
};

export default SingleVacancyPublicationBreadcrumbs;
