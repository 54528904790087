import Box from '@mui/material/Box';
import React from 'react';
import classnames from 'classnames';
import Typography from 'uibuilder/Typography';
import { EMPTY_VALUE_INDICATOR, isNotNullValue } from 'shared/uibuilder/helper';
import { FieldLayoutProps } from 'shared/uibuilder/field';

const IconFieldLayout = ({ label, value, className }: FieldLayoutProps) => {
  return (
    <Box className={classnames('field__wrapper', className)} display="flex" alignItems="center">
      {label as any}
      <Typography variant="body1" component="div">
        {isNotNullValue(value) ? value : EMPTY_VALUE_INDICATOR}
      </Typography>
    </Box>
  );
};

export default IconFieldLayout;
