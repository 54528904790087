/* istanbul ignore file */
import React, { useState } from 'react';
import useServiceCatalogService, {
  ASSIGN_SERVICE_CATALOG_OWNER,
  SERVICE_CATALOG_STATUSES,
} from 'erp/serviceCatalog/useServiceCatalogService';
import { useParams } from 'react-router-dom';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { buildEmployeeAutoSuggestLabel } from 'erp/employee/shared/input/EmployeeInput';
import { snakeCase } from 'lodash';
import { EmployeeInput } from 'erp/employee';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';

const AssignServiceCatalogOwnerButton = ({ statusSource }: { statusSource: string }) => {
  const { id } = useParams<Dictionary<string>>();
  const { assignServiceOwner, invalidateCache } = useServiceCatalogService();
  const { getValue } = useFieldHelper({ source: statusSource });
  const { setData = () => {} } = useShowContext();
  const status = getValue();

  const [owner, setOwner] = useState<string>('');

  const onOwnerCallback = (values: FormFieldsData) => {
    setOwner(values.owner);
  };

  if (status === SERVICE_CATALOG_STATUSES.ARCHIVED) {
    return null;
  }

  return (
    <ButtonWithConfirmation
      displayMessage="Do you really want to assign/reasign service owner?"
      modalChildren={
        <div className="mt-5">
          <EmployeeInput
            source="owner"
            label="New Owner"
            nameSource="employeeName"
            mapResults={(employee: any) => ({
              id: employee.alias,
              text: buildEmployeeAutoSuggestLabel(employee),
              office: employee.office ? snakeCase(employee.office).toUpperCase() : null,
            })}
            onChangeCallback={onOwnerCallback}
          />
        </div>
      }
      submitMethod={() => assignServiceOwner(id!, owner)}
      afterSubmit={{
        successMessage: 'New service owner has been successfully assigned.',
        errorMessage: "Can't assign new owner.",
        execute: (data: any) => {
          invalidateCache();
          setData(data);
        },
      }}
      permissionToCheck={ASSIGN_SERVICE_CATALOG_OWNER}
      variant="outlined"
    >
      Assign service owner
    </ButtonWithConfirmation>
  );
};

export default AssignServiceCatalogOwnerButton;
