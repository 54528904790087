import React from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ALetterIcon from './shared/icons/ALetter';
import HLetterIcon from './shared/icons/HLetter';
import BLetterIcon from './shared/icons/BLetter';
import PLetterIcon from './shared/icons/PLetter';
import CompensationForm from './shared/forms/CompensationForm/CompensationForm';
import ScreeningForm from './shared/forms/ScreeningForm/ScreeningForm';
import AdditionalActivitiesForm from './shared/forms/AdditionalActivitiesForm/AdditionalActivitiesForm';
import OfferForm from './shared/forms/OfferForm/OfferForm';
import DockIcon from 'uibuilder/AppIcon/Icons/DockIcon';
import ChecklistIcon from 'uibuilder/AppIcon/Icons/ChecklistIcon';
import TestTaskIcon from 'uibuilder/AppIcon/Icons/TestTaskIcon';
import RoleRelatedIcon from 'uibuilder/AppIcon/Icons/RoleRelatedIcon';
import GeneralInterviewIcon from 'uibuilder/AppIcon/Icons/GeneralInterviewIcon';
import RoleRelatedInterviewScheduledForm from 'erp/recruitment/recruitingBoard/shared/forms/InterviewForm/RoleRelatedInterviewScheduledForm';
import GeneralInterviewScheduledForm from 'erp/recruitment/recruitingBoard/shared/forms/InterviewForm/GeneralInterviewScheduledForm';

export enum VacancyPriority {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}
export const VacancyPriorityLabels = {
  [VacancyPriority.HIGH]: 'High',
  [VacancyPriority.MEDIUM]: 'Medium',
  [VacancyPriority.LOW]: 'Low',
};

export const VacancyPriorityShortLabels = {
  [VacancyPriority.HIGH]: 'H',
  [VacancyPriority.MEDIUM]: 'M',
  [VacancyPriority.LOW]: 'L',
};

export const ActivityType = {
  additionalInterview: 'additionalInterview',
  referenceCheck: 'referenceCheck',
  additionalMaterials: 'additionalMaterials',
  testTask: 'testTask',
};

export enum Stages {
  Applied = 'Applied',
  Screening = 'Screening',
  Interview = 'Interview',
  Decision = 'Decision',
  Offer = 'Offer',
  Hired = 'Hired',
  PendingRejection = 'PendingRejection',
  Closed = 'Closed',
}

export const MoveToStages: Record<Stages, string> = {
  [Stages.Applied]: 'MoveToApplied',
  [Stages.Screening]: 'MoveToScreening',
  [Stages.Interview]: 'MoveToInterview',
  [Stages.Decision]: 'MoveToDecision',
  [Stages.Offer]: 'MoveToOffer',
  [Stages.Hired]: 'MoveToHired',
  [Stages.PendingRejection]: 'MoveToReject',
  [Stages.Closed]: 'MoveToClosed',
};

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;

export const columnNames = [
  {
    name: 'Applied',
    stage: Stages.Applied,
    deadlinesForStage: {
      normal: HOUR * 3,
      warning: HOUR * 5,
    },
  },
  {
    name: 'Screening',
    stage: Stages.Screening,
    deadlinesForStage: {
      normal: DAY * 4,
      warning: DAY * 5,
    },
  },
  {
    name: 'Interview',
    stage: Stages.Interview,
    deadlinesForStage: {
      normal: DAY * 4,
      warning: DAY * 5,
    },
  },
  {
    name: 'Decision',
    stage: Stages.Decision,
    deadlinesForStage: {
      normal: DAY,
      warning: DAY * 2,
    },
  },
  {
    name: 'Offer',
    stage: Stages.Offer,
    deadlinesForStage: {
      normal: DAY * 3,
      warning: DAY * 4,
    },
  },
  {
    name: 'Hired',
    stage: Stages.Hired,
  },
  {
    name: 'Pending Reject',
    stage: Stages.PendingRejection,
    deadlinesForStage: {
      normal: HOUR * 4,
      warning: DAY,
    },
  },
];

export const TaskDeadlineStyle = {
  Normal: {
    borderColor: '#02542D',
    background: '#EBFFEE',
    dragIconBackground: '#02542D36',
  },
  Warning: {
    borderColor: '#BF6A02',
    background: '#FFFBEB',
    dragIconBackground: '#BF6A0236',
  },
  Expired: {
    borderColor: '#900B09',
    background: '#FEE9E7',
    dragIconBackground: '#900B0936',
  },
} as const;

export const stepConfigurations: Record<
  string,
  {
    icon: React.ElementType;
    job: string;
    states: {
      key: string;
      event: string;
      modal?: any;
      text: string;
      color: string;
      tooltipType?: string;
      isManagedByProcess?: boolean;
    }[];
  }[]
> = {
  Offer: [
    {
      icon: DockIcon,
      job: 'OfferSending',
      states: [
        {
          key: 'OfferNotSent',
          text: 'Offer is not sent',
          event: 'OfferReset',
          color: 'gray',
        },
        {
          key: 'OfferSent',
          text: 'Offer is sent',
          event: 'OfferSent',
          color: 'green',
          modal: OfferForm,
        },
      ],
    },
  ],
  Hired: [
    {
      icon: HLetterIcon,
      job: 'FirstWorkingDay',
      states: [
        {
          key: 'FirstWorkingDayNotDefined',
          text: "Candidate's start date is expected",
          event: 'FirstWorkingDayReset',
          color: 'gray',
          tooltipType: 'Hired',
        },
        {
          key: 'FirstWorkingDayStarted',
          event: 'FirstWorkingDayStarted',
          text: 'Candidate has started',
          color: 'green',
          tooltipType: 'Hired',
          isManagedByProcess: true,
        },
      ],
    },
    {
      icon: BLetterIcon,
      job: 'BootcampPeriod',
      states: [
        {
          key: 'BootcampPeriodPending',
          text: 'Bootcamp',
          event: 'BootcampPeriodReset',
          color: 'gray',
        },
        {
          key: 'BootcampPeriodFinished',
          event: 'BootcampPeriodFinished',
          text: 'Bootcamp finished positively',
          color: 'green',
          isManagedByProcess: true,
        },
      ],
    },
    {
      icon: PLetterIcon,
      job: 'ProbationPeriod',
      states: [
        {
          key: 'ProbationPeriodPending',
          text: 'Probation period',
          event: 'ProbationPeriodReset',
          color: 'gray',
        },
        {
          key: 'ProbationPeriodFinished',
          text: 'Probation finished positively',
          event: 'ProbationPeriodFinished',
          color: 'green',
          isManagedByProcess: true,
        },
      ],
    },
  ],
  Interview: [
    {
      icon: RoleRelatedIcon,
      job: 'RoleRelatedInterview',
      states: [
        {
          key: 'RoleRelatedInterviewPending',
          text: 'Role-related interview is not scheduled',
          event: 'RoleRelatedInterviewReset',
          color: 'gray',
        },
        {
          key: 'RoleRelatedInterviewScheduled',
          text: 'Role-related interview is scheduled',
          event: 'RoleRelatedInterviewScheduled',
          color: 'orange',
          modal: RoleRelatedInterviewScheduledForm,
          tooltipType: 'RoleRelatedInterview',
        },
        {
          key: 'RoleRelatedInterviewCompleted',
          event: 'RoleRelatedInterviewCompleted',
          text: 'Role-related interview is conducted',
          color: 'green',
          isManagedByProcess: true,
        },
      ],
    },
    {
      icon: GeneralInterviewIcon,
      job: 'GeneralInterview',
      states: [
        {
          key: 'GeneralInterviewPending',
          event: 'GeneralInterviewReset',
          text: 'General interview is not scheduled',
          color: 'gray',
        },
        {
          key: 'GeneralInterviewScheduled',
          event: 'GeneralInterviewScheduled',
          text: 'General interview is scheduled',
          color: 'orange',
          modal: GeneralInterviewScheduledForm,
          tooltipType: 'GeneralInterview',
        },
        {
          key: 'GeneralInterviewCompleted',
          event: 'GeneralInterviewCompleted',
          text: 'General interview is conducted',
          color: 'green',
          isManagedByProcess: true,
        },
      ],
    },
    {
      icon: ALetterIcon,
      job: 'AdditionalActivities',
      states: [
        {
          key: 'AdditionalActivitiesNotRequired',
          event: 'AdditionalActivitiesReset',
          text: 'Additional activities not required',
          color: 'gray',
        },
        {
          key: 'AdditionalActivitiesRequested',
          text: 'Additional activities required',
          event: 'AdditionalActivitiesRequested',
          color: 'orange',
          modal: AdditionalActivitiesForm,
          tooltipType: 'AdditionalActivities',
        },
        {
          key: 'AdditionalActivitiesReceived',
          text: 'Additional activities received',
          event: 'AdditionalActivitiesReceived',
          color: 'green',
          isManagedByProcess: true,
        },
      ],
    },
  ],
  Screening: [
    {
      icon: ChecklistIcon,
      job: 'ScreeningChecklist',
      states: [
        {
          key: 'ScreeningChecklistPending',
          event: 'ScreeningChecklistReset',
          text: 'Checklist not sent to candidate',
          color: 'gray',
        },
        {
          key: 'ScreeningChecklistSent',
          event: 'ScreeningChecklistSent',
          text: 'Checklist sent to candidate',
          color: 'orange',
        },
        {
          key: 'ScreeningChecklistCompleted',
          event: 'ScreeningChecklistCompleted',
          text: 'Checklist received from candidate',
          color: 'green',
          modal: CompensationForm,
        },
      ],
    },
    {
      icon: TestTaskIcon,
      job: 'ScreeningTestTask',
      states: [
        {
          key: 'ScreeningTestTaskPending',
          event: 'ScreeningTestTaskReset',
          text: 'Test task not sent to candidate',
          color: 'gray',
        },
        {
          key: 'ScreeningTestTaskSent',
          event: 'ScreeningTestTaskSent',
          text: 'Test task sent to candidate',
          color: 'orange',
        },
        {
          key: 'ScreeningTestTaskCompleted',
          event: 'ScreeningTestTaskCompleted',
          text: 'Test task received from candidate',
          color: 'green',
        },
      ],
    },
    {
      icon: CalendarMonthIcon,
      job: 'ScreeningCommunication',
      states: [
        {
          key: 'ScreeningCommunicationPending',
          text: 'Screening call not scheduled with candidate',
          event: 'ScreeningCommunicationReset',
          color: 'gray',
        },
        {
          key: 'ScreeningCommunicationScheduled',
          text: 'Screening call scheduled with candidate',
          event: 'ScreeningCommunicationScheduled',
          color: 'orange',
          modal: ScreeningForm,
          tooltipType: 'ScreeningCommunication',
        },
        {
          key: 'ScreeningCommunicationCompleted',
          event: 'ScreeningCommunicationCompleted',
          text: 'Screening call conducted with candidate',
          color: 'green',
        },
      ],
    },
  ],
};

export const REJECTION_REASON = {
  // Applied stage
  APPLIED_NO_RELEVANT_POSITION: 'APPLIED_NO_RELEVANT_POSITION',
  APPLIED_NO_RELEVANT_EXPERIENCE: 'APPLIED_NO_RELEVANT_EXPERIENCE',
  APPLIED_INSUFFICIENT_ENGLISH_LEVEL: 'APPLIED_INSUFFICIENT_ENGLISH_LEVEL',
  APPLIED_RELOCATION_REGISTRATION_ISSUES: 'APPLIED_RELOCATION_REGISTRATION_ISSUES',

  // Screening stage
  CHECKLIST_SCREENING_NO_RESPONSE: 'CHECKLIST_SCREENING_NO_RESPONSE',
  CHECKLIST_SCREENING_EXPERIENCE_MISMATCH: 'CHECKLIST_SCREENING_EXPERIENCE_MISMATCH',
  CHECKLIST_SCREENING_ENGLISH_LEVEL_MISMATCH: 'CHECKLIST_SCREENING_ENGLISH_LEVEL_MISMATCH',
  CHECKLIST_SCREENING_RELOCATION_REGISTRATION_ISSUES: 'CHECKLIST_SCREENING_RELOCATION_REGISTRATION_ISSUES',
  CHECKLIST_SCREENING_WORK_SCHEDULE_NOT_SUITABLE: 'CHECKLIST_SCREENING_WORK_SCHEDULE_NOT_SUITABLE',
  CHECKLIST_SCREENING_SALARY_EXPECTATIONS_MISMATCH: 'CHECKLIST_SCREENING_SALARY_EXPECTATIONS_MISMATCH',
  TEST_TASK_SCREENING_POOR_PERFORMANCE: 'TEST_TASK_SCREENING_POOR_PERFORMANCE',
  TEST_TASK_SCREENING_NOT_SUBMITTED: 'TEST_TASK_SCREENING_NOT_SUBMITTED',
  TEST_TASK_SCREENING_DECLINED: 'TEST_TASK_SCREENING_DECLINED',
  CALL_SCREENING_INSUFFICIENT_TECHNICAL_SKILLS: 'CALL_SCREENING_INSUFFICIENT_TECHNICAL_SKILLS',
  CALL_SCREENING_INSUFFICIENT_SOFT_SKILLS_MOTIVATION: 'CALL_SCREENING_INSUFFICIENT_SOFT_SKILLS_MOTIVATION',
  CALL_SCREENING_COMPANY_CULTURE_MISMATCH: 'CALL_SCREENING_COMPANY_CULTURE_MISMATCH',
  CALL_SCREENING_ENGLISH_LEVEL_MISMATCH: 'CALL_SCREENING_ENGLISH_LEVEL_MISMATCH',

  // Interview stage
  GENERAL_INTERVIEW_SOFT_SKILLS_MOTIVATION_MISMATCH: 'GENERAL_INTERVIEW_SOFT_SKILLS_MOTIVATION_MISMATCH',
  GENERAL_INTERVIEW_COMPANY_CULTURE_MISMATCH: 'GENERAL_INTERVIEW_COMPANY_CULTURE_MISMATCH',
  GENERAL_INTERVIEW_INSUFFICIENT_ENGLISH_LEVEL: 'GENERAL_INTERVIEW_INSUFFICIENT_ENGLISH_LEVEL',
  GENERAL_INTERVIEW_TECHNICAL_SKILLS_MISMATCH: 'GENERAL_INTERVIEW_TECHNICAL_SKILLS_MISMATCH',
  ROLE_INTERVIEW_TECHNICAL_SKILLS_MISMATCH: 'ROLE_INTERVIEW_TECHNICAL_SKILLS_MISMATCH',
  ROLE_INTERVIEW_SOFT_SKILLS_MOTIVATION_MISMATCH: 'ROLE_INTERVIEW_SOFT_SKILLS_MOTIVATION_MISMATCH',
  ROLE_INTERVIEW_COMPANY_CULTURE_MISMATCH: 'ROLE_INTERVIEW_COMPANY_CULTURE_MISMATCH',
  ROLE_INTERVIEW_INSUFFICIENT_ENGLISH_LEVEL: 'ROLE_INTERVIEW_INSUFFICIENT_ENGLISH_LEVEL',
  ROLE_INTERVIEW_TECHNICAL_SOFT_SKILLS_MISMATCH: 'ROLE_INTERVIEW_TECHNICAL_SOFT_SKILLS_MISMATCH',

  // Decision stage
  DECISION_BUDGET_LIMITATIONS: 'DECISION_BUDGET_LIMITATIONS',
  DECISION_POSITION_NO_LONGER_NEEDED: 'DECISION_POSITION_NO_LONGER_NEEDED',
  DECISION_INSUFFICIENT_SENIORITY_LEVEL: 'DECISION_INSUFFICIENT_SENIORITY_LEVEL',
  // Offer stage
  OFFER_DEVELOPMENT_PROSPECTS_CONCERNS: 'OFFER_DEVELOPMENT_PROSPECTS_CONCERNS',
  OFFER_SPECIFIC_PREFERENCES: 'OFFER_SPECIFIC_PREFERENCES',
  OFFER_SALARY_EXPECTATIONS_NOT_ALIGNED: 'OFFER_SALARY_EXPECTATIONS_NOT_ALIGNED',
  OFFER_SPECIFIC_PREFERENCES_AND_SALARY: 'OFFER_SPECIFIC_PREFERENCES_AND_SALARY',
  OFFER_DEVELOPMENT_PROSPECTS_AND_SALARY: 'OFFER_DEVELOPMENT_PROSPECTS_AND_SALARY',
  OFFER_RELOCATION_REGISTRATION_ISSUES: 'OFFER_RELOCATION_REGISTRATION_ISSUES',
  OFFER_NO_INFO: 'OFFER_NO_INFO',
  // Hiring stage
  HIRING_RELOCATION_REGISTRATION_ISSUES: 'HIRING_RELOCATION_REGISTRATION_ISSUES',
  HIRING_COMPANY_CULTURE_MISMATCH: 'HIRING_COMPANY_CULTURE_MISMATCH',
  HIRING_PROJECT_TASKS_MISMATCH: 'HIRING_PROJECT_TASKS_MISMATCH',
  HIRING_CAREER_GROWTH_LIMITATIONS: 'HIRING_CAREER_GROWTH_LIMITATIONS',
  HIRING_CLIENT_RELATED_CONCERNS: 'HIRING_CLIENT_RELATED_CONCERNS',
  HIRING_SALARY_CONCERNS: 'HIRING_SALARY_CONCERNS',
  HIRING_BURNOUT: 'HIRING_BURNOUT',
  HIRING_MANAGER_RELATED_ISSUES: 'HIRING_MANAGER_RELATED_ISSUES',
  HIRING_SOFT_SKILLS_MOTIVATION_MISMATCH: 'HIRING_SOFT_SKILLS_MOTIVATION_MISMATCH',
  HIRING_TECHNICAL_SKILLS_MISMATCH: 'HIRING_TECHNICAL_SKILLS_MISMATCH',
  HIRING_TECHNICAL_AND_SOFT_SKILLS_MISMATCH: 'HIRING_TECHNICAL_AND_SOFT_SKILLS_MISMATCH',
  HIRING_WORK_SCHEDULE_INCOMPATIBILITY: 'HIRING_WORK_SCHEDULE_INCOMPATIBILITY',
  // Common
  COMMON_POSITION_IS_CLOSED: 'COMMON_POSITION_IS_CLOSED',
  COMMON_NO_RESPONSE: 'COMMON_NO_RESPONSE',
  COMMON_NOT_INTERESTED: 'COMMON_NOT_INTERESTED',
};

export const REJECTION_REASON_ALIASES = {
  // Applied stage
  [REJECTION_REASON.APPLIED_NO_RELEVANT_POSITION]: 'No relevant position available',
  [REJECTION_REASON.APPLIED_NO_RELEVANT_EXPERIENCE]:
    'No relevant experience — the candidate does not meet the requirements',
  [REJECTION_REASON.APPLIED_INSUFFICIENT_ENGLISH_LEVEL]: 'Insufficient English level',
  [REJECTION_REASON.APPLIED_RELOCATION_REGISTRATION_ISSUES]: 'Issues with relocation, registration, or location',

  // Screening stage
  [REJECTION_REASON.CHECKLIST_SCREENING_NO_RESPONSE]: 'No response after sending the checklist',
  [REJECTION_REASON.CHECKLIST_SCREENING_EXPERIENCE_MISMATCH]: 'Relevant experience mismatch based on the checklist',
  [REJECTION_REASON.CHECKLIST_SCREENING_ENGLISH_LEVEL_MISMATCH]: 'English level mismatch based on the checklist',
  [REJECTION_REASON.CHECKLIST_SCREENING_RELOCATION_REGISTRATION_ISSUES]:
    'Relocation/registration issues based on the checklist',
  [REJECTION_REASON.CHECKLIST_SCREENING_WORK_SCHEDULE_NOT_SUITABLE]:
    'Work schedule not suitable based on the checklist',
  [REJECTION_REASON.CHECKLIST_SCREENING_SALARY_EXPECTATIONS_MISMATCH]:
    'Salary expectations do not match based on the checklist',
  [REJECTION_REASON.TEST_TASK_SCREENING_POOR_PERFORMANCE]: 'Poor performance on the test task',
  [REJECTION_REASON.TEST_TASK_SCREENING_NOT_SUBMITTED]: 'Test task not submitted',
  [REJECTION_REASON.TEST_TASK_SCREENING_DECLINED]: 'Declined to take the test task',
  [REJECTION_REASON.CALL_SCREENING_INSUFFICIENT_TECHNICAL_SKILLS]:
    'Insufficient technical skills during the screening call',
  [REJECTION_REASON.CALL_SCREENING_INSUFFICIENT_SOFT_SKILLS_MOTIVATION]:
    'Insufficient soft skills or motivation during the screening call',
  [REJECTION_REASON.CALL_SCREENING_COMPANY_CULTURE_MISMATCH]: 'Company culture mismatch during the screening call',
  [REJECTION_REASON.CALL_SCREENING_ENGLISH_LEVEL_MISMATCH]: 'English level mismatch during the screening call',

  // Interview stage
  [REJECTION_REASON.GENERAL_INTERVIEW_TECHNICAL_SKILLS_MISMATCH]:
    'General interview - Technical skills do not meet the requirements',
  [REJECTION_REASON.GENERAL_INTERVIEW_SOFT_SKILLS_MOTIVATION_MISMATCH]:
    'General interview - Soft skills and motivation do not meet the requirements',
  [REJECTION_REASON.GENERAL_INTERVIEW_COMPANY_CULTURE_MISMATCH]: 'General interview - Company culture mismatch',
  [REJECTION_REASON.GENERAL_INTERVIEW_INSUFFICIENT_ENGLISH_LEVEL]: 'General interview - Insufficient English level',
  [REJECTION_REASON.ROLE_INTERVIEW_TECHNICAL_SKILLS_MISMATCH]:
    'Role-related interview - Technical skills do not meet the requirements',
  [REJECTION_REASON.ROLE_INTERVIEW_SOFT_SKILLS_MOTIVATION_MISMATCH]:
    'Role-related interview - Soft skills and motivation do not meet the requirements',
  [REJECTION_REASON.ROLE_INTERVIEW_COMPANY_CULTURE_MISMATCH]: 'Role-related interview - Company culture mismatch',
  [REJECTION_REASON.ROLE_INTERVIEW_INSUFFICIENT_ENGLISH_LEVEL]: 'Role-related interview - Insufficient English level',
  [REJECTION_REASON.ROLE_INTERVIEW_TECHNICAL_SOFT_SKILLS_MISMATCH]:
    'Role-related interview - Both technical and soft skills do not meet the requirements',

  // Decision stage
  [REJECTION_REASON.DECISION_BUDGET_LIMITATIONS]: 'Budget limitations',
  [REJECTION_REASON.DECISION_POSITION_NO_LONGER_NEEDED]: 'Position no longer needed',
  [REJECTION_REASON.DECISION_INSUFFICIENT_SENIORITY_LEVEL]: 'Insufficient seniority level',

  // Offer stage
  [REJECTION_REASON.OFFER_DEVELOPMENT_PROSPECTS_CONCERNS]: 'Concerns about development prospects',
  [REJECTION_REASON.OFFER_SPECIFIC_PREFERENCES]: 'Specific preferences of the candidate',
  [REJECTION_REASON.OFFER_SALARY_EXPECTATIONS_NOT_ALIGNED]: 'Salary expectations not aligned',
  [REJECTION_REASON.OFFER_SPECIFIC_PREFERENCES_AND_SALARY]: 'Specific preferences + Salary',
  [REJECTION_REASON.OFFER_DEVELOPMENT_PROSPECTS_AND_SALARY]: 'Development prospects + Salary',
  [REJECTION_REASON.OFFER_RELOCATION_REGISTRATION_ISSUES]: 'Relocation or registration issues',
  [REJECTION_REASON.OFFER_NO_INFO]: 'No info',

  // Hiring stage
  [REJECTION_REASON.HIRING_RELOCATION_REGISTRATION_ISSUES]: 'Issues with relocation or registration',
  [REJECTION_REASON.HIRING_COMPANY_CULTURE_MISMATCH]: 'Company culture mismatch',
  [REJECTION_REASON.HIRING_PROJECT_TASKS_MISMATCH]: 'Mismatch with project tasks',
  [REJECTION_REASON.HIRING_CAREER_GROWTH_LIMITATIONS]: 'Lack of career growth and development opportunities',
  [REJECTION_REASON.HIRING_CLIENT_RELATED_CONCERNS]: 'Client-related concerns',
  [REJECTION_REASON.HIRING_SALARY_CONCERNS]: 'Salary concerns',
  [REJECTION_REASON.HIRING_BURNOUT]: 'Burnout',
  [REJECTION_REASON.HIRING_MANAGER_RELATED_ISSUES]: 'Manager-related issues',
  [REJECTION_REASON.HIRING_SOFT_SKILLS_MOTIVATION_MISMATCH]: 'Soft skills and motivation do not meet expectations',
  [REJECTION_REASON.HIRING_TECHNICAL_SKILLS_MISMATCH]: 'Technical skills do not meet expectations',
  [REJECTION_REASON.HIRING_TECHNICAL_AND_SOFT_SKILLS_MISMATCH]:
    'Both technical and soft skills do not meet expectations',
  [REJECTION_REASON.HIRING_WORK_SCHEDULE_INCOMPATIBILITY]: 'Work schedule incompatibility',

  // Common reasons
  [REJECTION_REASON.COMMON_POSITION_IS_CLOSED]: 'The position is closed',
  [REJECTION_REASON.COMMON_NO_RESPONSE]: 'No response from the candidate',
  [REJECTION_REASON.COMMON_NOT_INTERESTED]: 'The Candidate is not interested',
};

export const REJECTION_REASONS_BY_STAGE = {
  [Stages.Applied]: {
    [REJECTION_REASON.APPLIED_NO_RELEVANT_POSITION]: 'No relevant position available',
    [REJECTION_REASON.APPLIED_NO_RELEVANT_EXPERIENCE]:
      'No relevant experience — the candidate does not meet the requirements',
    [REJECTION_REASON.APPLIED_INSUFFICIENT_ENGLISH_LEVEL]: 'Insufficient English level',
    [REJECTION_REASON.APPLIED_RELOCATION_REGISTRATION_ISSUES]: 'Issues with relocation, registration, or location',
    // Common
    [REJECTION_REASON.COMMON_POSITION_IS_CLOSED]: 'The position is closed',
    [REJECTION_REASON.COMMON_NO_RESPONSE]: 'No response from the candidate',
    [REJECTION_REASON.COMMON_NOT_INTERESTED]: 'The Candidate is not interested',
  },
  [Stages.Screening]: {
    [REJECTION_REASON.CHECKLIST_SCREENING_NO_RESPONSE]: 'No response after sending the checklist',
    [REJECTION_REASON.CHECKLIST_SCREENING_EXPERIENCE_MISMATCH]: 'Relevant experience mismatch based on the checklist',
    [REJECTION_REASON.CHECKLIST_SCREENING_ENGLISH_LEVEL_MISMATCH]: 'English level mismatch based on the checklist',
    [REJECTION_REASON.CHECKLIST_SCREENING_RELOCATION_REGISTRATION_ISSUES]:
      'Relocation/registration issues based on the checklist',
    [REJECTION_REASON.CHECKLIST_SCREENING_WORK_SCHEDULE_NOT_SUITABLE]:
      'Work schedule not suitable based on the checklist',
    [REJECTION_REASON.CHECKLIST_SCREENING_SALARY_EXPECTATIONS_MISMATCH]:
      'Salary expectations do not match based on the checklist',
    [REJECTION_REASON.TEST_TASK_SCREENING_POOR_PERFORMANCE]: 'Poor performance on the test task',
    [REJECTION_REASON.TEST_TASK_SCREENING_NOT_SUBMITTED]: 'Test task not submitted',
    [REJECTION_REASON.TEST_TASK_SCREENING_DECLINED]: 'Declined to take the test task',
    [REJECTION_REASON.CALL_SCREENING_INSUFFICIENT_TECHNICAL_SKILLS]:
      'Insufficient technical skills during the screening call',
    [REJECTION_REASON.CALL_SCREENING_INSUFFICIENT_SOFT_SKILLS_MOTIVATION]:
      'Insufficient soft skills or motivation during the screening call',
    [REJECTION_REASON.CALL_SCREENING_COMPANY_CULTURE_MISMATCH]: 'Company culture mismatch during the screening call',
    [REJECTION_REASON.CALL_SCREENING_ENGLISH_LEVEL_MISMATCH]: 'English level mismatch during the screening call',
    // Common
    [REJECTION_REASON.COMMON_POSITION_IS_CLOSED]: 'The position is closed',
    [REJECTION_REASON.COMMON_NO_RESPONSE]: 'No response from the candidate',
    [REJECTION_REASON.COMMON_NOT_INTERESTED]: 'The Candidate is not interested',
  },
  [Stages.Interview]: {
    [REJECTION_REASON.GENERAL_INTERVIEW_TECHNICAL_SKILLS_MISMATCH]:
      'General interview - Technical skills do not meet the requirements',
    [REJECTION_REASON.GENERAL_INTERVIEW_SOFT_SKILLS_MOTIVATION_MISMATCH]:
      'General interview - Soft skills and motivation do not meet the requirements',
    [REJECTION_REASON.GENERAL_INTERVIEW_COMPANY_CULTURE_MISMATCH]: 'General interview - Company culture mismatch',
    [REJECTION_REASON.GENERAL_INTERVIEW_INSUFFICIENT_ENGLISH_LEVEL]: 'General interview - Insufficient English level',
    [REJECTION_REASON.ROLE_INTERVIEW_TECHNICAL_SKILLS_MISMATCH]:
      'Role-related interview - Technical skills do not meet the requirements',
    [REJECTION_REASON.ROLE_INTERVIEW_SOFT_SKILLS_MOTIVATION_MISMATCH]:
      'Role-related interview - Soft skills and motivation do not meet the requirements',
    [REJECTION_REASON.ROLE_INTERVIEW_COMPANY_CULTURE_MISMATCH]: 'Role-related interview - Company culture mismatch',
    [REJECTION_REASON.ROLE_INTERVIEW_INSUFFICIENT_ENGLISH_LEVEL]: 'Role-related interview - Insufficient English level',
    [REJECTION_REASON.ROLE_INTERVIEW_TECHNICAL_SOFT_SKILLS_MISMATCH]:
      'Role-related interview - Both technical and soft skills do not meet the requirements',
    // Common
    [REJECTION_REASON.COMMON_POSITION_IS_CLOSED]: 'The position is closed',
    [REJECTION_REASON.COMMON_NO_RESPONSE]: 'No response from the candidate',
    [REJECTION_REASON.COMMON_NOT_INTERESTED]: 'The Candidate is not interested',
  },
  [Stages.Decision]: {
    [REJECTION_REASON.DECISION_BUDGET_LIMITATIONS]: 'Budget limitations',
    [REJECTION_REASON.DECISION_POSITION_NO_LONGER_NEEDED]: 'Position no longer needed',
    [REJECTION_REASON.DECISION_INSUFFICIENT_SENIORITY_LEVEL]: 'Insufficient seniority level',
    // Common
    [REJECTION_REASON.COMMON_POSITION_IS_CLOSED]: 'The position is closed',
    [REJECTION_REASON.COMMON_NO_RESPONSE]: 'No response from the candidate',
    [REJECTION_REASON.COMMON_NOT_INTERESTED]: 'The Candidate is not interested',
  },
  [Stages.Offer]: {
    [REJECTION_REASON.OFFER_DEVELOPMENT_PROSPECTS_CONCERNS]: 'Concerns about development prospects',
    [REJECTION_REASON.OFFER_SPECIFIC_PREFERENCES]: 'Specific preferences of the candidate',
    [REJECTION_REASON.OFFER_SALARY_EXPECTATIONS_NOT_ALIGNED]: 'Salary expectations not aligned',
    [REJECTION_REASON.OFFER_SPECIFIC_PREFERENCES_AND_SALARY]: 'Specific preferences + Salary',
    [REJECTION_REASON.OFFER_DEVELOPMENT_PROSPECTS_AND_SALARY]: 'Development prospects + Salary',
    [REJECTION_REASON.OFFER_RELOCATION_REGISTRATION_ISSUES]: 'Relocation or registration issues',
    [REJECTION_REASON.OFFER_NO_INFO]: 'No info',
    // Common
    [REJECTION_REASON.COMMON_POSITION_IS_CLOSED]: 'The position is closed',
    [REJECTION_REASON.COMMON_NO_RESPONSE]: 'No response from the candidate',
    [REJECTION_REASON.COMMON_NOT_INTERESTED]: 'The Candidate is not interested',
  },
  [Stages.Hired]: {
    [REJECTION_REASON.HIRING_RELOCATION_REGISTRATION_ISSUES]: 'Issues with relocation or registration',
    [REJECTION_REASON.HIRING_COMPANY_CULTURE_MISMATCH]: 'Company culture mismatch',
    [REJECTION_REASON.HIRING_PROJECT_TASKS_MISMATCH]: 'Mismatch with project tasks',
    [REJECTION_REASON.HIRING_CAREER_GROWTH_LIMITATIONS]: 'Lack of career growth and development opportunities',
    [REJECTION_REASON.HIRING_CLIENT_RELATED_CONCERNS]: 'Client-related concerns',
    [REJECTION_REASON.HIRING_SALARY_CONCERNS]: 'Salary concerns',
    [REJECTION_REASON.HIRING_BURNOUT]: 'Burnout',
    [REJECTION_REASON.HIRING_MANAGER_RELATED_ISSUES]: 'Manager-related issues',
    [REJECTION_REASON.HIRING_SOFT_SKILLS_MOTIVATION_MISMATCH]: 'Soft skills and motivation do not meet expectations',
    [REJECTION_REASON.HIRING_TECHNICAL_SKILLS_MISMATCH]: 'Technical skills do not meet expectations',
    [REJECTION_REASON.HIRING_TECHNICAL_AND_SOFT_SKILLS_MISMATCH]:
      'Both technical and soft skills do not meet expectations',
    [REJECTION_REASON.HIRING_WORK_SCHEDULE_INCOMPATIBILITY]: 'Work schedule incompatibility',
    // Common
    [REJECTION_REASON.COMMON_POSITION_IS_CLOSED]: 'The position is closed',
    [REJECTION_REASON.COMMON_NO_RESPONSE]: 'No response from the candidate',
    [REJECTION_REASON.COMMON_NOT_INTERESTED]: 'The Candidate is not interested',
  },
};
